<template>
  <div style="text-align: justify" class="pa-sm-8 pa-md-14">
    <v-container>
      <legal-header></legal-header>

      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-indent: -14.2pt;
          text-align: center;
          margin-top: 0pt;
          margin-bottom: 0pt;
          padding: 0pt 0pt 0pt 14.2pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή
          &Alpha;&pi;&omicron;&rho;&rho;ή&tau;&omicron;&upsilon;&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-indent: -14.2pt;
          text-align: center;
          margin-top: 0pt;
          margin-bottom: 0pt;
          padding: 0pt 0pt 0pt 14.2pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ώ&nu;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;&nbsp;</span
        >
      </p>
      <p><br /></p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          margin-right: 22.5pt;
          background-color: #ffffff;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 11pt;
            font-family: Arial;
            color: #330000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Tau;&epsilon;&lambda;&epsilon;&upsilon;&tau;&alpha;ί&alpha;
          &Epsilon;&nu;&eta;&mu;έ&rho;&omega;&sigma;&eta;: 01/06/2021</span
        >
      </p>
      <p><br /></p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-indent: -14.2pt;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
          padding: 0pt 0pt 0pt 14.2pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Gamma;&iota;&alpha; &tau;&iota;&sigmaf;
          &alpha;&nu;ά&gamma;&kappa;&epsilon;&sigmaf; &tau;&omicron;&upsilon;
          &pi;&alpha;&rho;ό&nu;&tau;&omicron;&sigmaf;
          &delta;&eta;&lambda;ώ&nu;&omicron;&nu;&tau;&alpha;&iota; &tau;&alpha;
          &kappa;ά&tau;&omega;&theta;&iota;
          &delta;&iota;&epsilon;&upsilon;&kappa;&rho;&iota;&nu;&iota;&sigma;&tau;&iota;&kappa;ά:&nbsp;</span
        >
      </p>
      <p><br /></p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-indent: 36pt;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Eta; &epsilon;&delta;ώ
          &alpha;&nu;&alpha;&phi;&epsilon;&rho;ό&mu;&epsilon;&nu;&eta;
          &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha; &laquo;Stoferno
          &nbsp;&Iota;&Kappa;&Epsilon;&raquo;
          &omicron;&rho;ί&zeta;&epsilon;&tau;&alpha;&iota; &omega;&sigmaf;
          &laquo;&omicron;
          &Upsilon;&pi;&epsilon;ύ&theta;&upsilon;&nu;&omicron;&sigmaf;
          &Epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;&sigmaf;&raquo;
          &kappa;&alpha;&iota; &epsilon;&pi;&omicron;&mu;έ&nu;&omega;&sigmaf;,
          &mu;&epsilon;&tau;&alpha;&xi;ύ ά&lambda;&lambda;&omega;&nu;,
          &sigma;&upsilon;&lambda;&lambda;έ&gamma;&epsilon;&iota;,
          &alpha;&pi;&omicron;&theta;&eta;&kappa;&epsilon;ύ&epsilon;&iota;,
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&epsilon;ί,
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;ά&zeta;&epsilon;&tau;&alpha;&iota;
          &kappa;&alpha;&iota;
          &delta;&rho;&omicron;&mu;&omicron;&lambda;&omicron;&gamma;&epsilon;ί
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &pi;&omicron;&upsilon;
          &upsilon;&pi;&epsilon;&iota;&sigma;έ&rho;&chi;&omicron;&nu;&tau;&alpha;&iota;
          &sigma;&tau;&eta; &sigma;&phi;&alpha;ί&rho;&alpha;
          &epsilon;&nu;&eta;&mu;έ&rho;&omega;&sigma;ή &tau;&eta;&sigmaf;
          &kappa;&alpha;&iota;
          &epsilon;&kappa;&tau;έ&lambda;&epsilon;&sigma;&eta;&sigmaf;
          &epsilon;&nu;&tau;&epsilon;&lambda;ώ&nu; ό&tau;&alpha;&nu;
          &tau;&omicron;
          &Upsilon;&pi;&omicron;&kappa;&epsilon;ί&mu;&epsilon;&nu;&omicron;
          &tau;&omega;&nu; &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&epsilon;&tau;&alpha;&iota;,
          &epsilon;&gamma;&gamma;&rho;ά&phi;&epsilon;&tau;&alpha;&iota;,
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&epsilon;ί
          &kappa;&alpha;&theta;&rsquo;
          &omicron;&iota;&omicron;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
          &tau;&rho;ό&pi;&omicron; &tau;&omicron;&upsilon;&sigmaf;
          &delta;&iota;&alpha;&delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;&omicron;ύ&sigmaf;
          &tau;ό&pi;&omicron;&upsilon;&sigmaf; &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf; &ndash;
          &Delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;&omicron;ί
          &Tau;ό&pi;&omicron;&iota; &ndash; &kappa;&alpha;&theta;ώ&sigmaf;
          &kappa;&alpha;&iota; &tau;&iota;&sigmaf;
          &kappa;&iota;&nu;&eta;&tau;έ&sigmaf; /
          &tau;&epsilon;&chi;&nu;&omicron;&lambda;&omicron;&gamma;&iota;&kappa;έ&sigmaf;
          &epsilon;&phi;&alpha;&rho;&mu;&omicron;&gamma;έ&sigmaf;
          &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf; &ndash;
          Applications (Apps).&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-indent: 36pt;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Kappa;&alpha;&theta;ώ&sigmaf; &eta;
          &pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha; &tau;&omega;&nu;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;&omicron;ύ
          &chi;&alpha;&rho;&alpha;&kappa;&tau;ή&rho;&alpha;
          &epsilon;ί&nu;&alpha;&iota;
          &epsilon;&xi;&alpha;&iota;&rho;&epsilon;&tau;&iota;&kappa;ά
          &sigma;&eta;&mu;&alpha;&nu;&tau;&iota;&kappa;ή &gamma;&iota;&alpha;
          &tau;&eta;&nu; &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
          &mu;&alpha;&sigmaf; &tau;&omicron; &pi;&alpha;&rho;ό&nu;
          &omicron;&rho;ί&zeta;&epsilon;&iota; &tau;&iota;&sigmaf;
          &upsilon;&pi;&omicron;&chi;&rho;&epsilon;ώ&sigma;&epsilon;&iota;&sigmaf;,
          &tau;&omicron;&nu; &tau;&rho;ό&pi;&omicron;
          &chi;&rho;ή&sigma;&eta;&sigmaf;,
          &kappa;&omicron;&iota;&nu;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;&sigmaf;
          &kappa;&alpha;&iota;
          &pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;&sigmaf;
          &tau;&omega;&nu; &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &pi;&omicron;&upsilon;
          &lambda;&alpha;&mu;&beta;ά&nu;&omicron;&upsilon;&mu;&epsilon;
          &kappa;&alpha;&theta;ώ&sigmaf; &kappa;&alpha;&iota;
          &pi;&omega;&sigmaf; &mu;&pi;&omicron;&rho;&omicron;ύ&mu;&epsilon;
          &nu;&alpha;
          &epsilon;&pi;&iota;&lambda;ύ&sigma;&omicron;&upsilon;&mu;&epsilon;
          &pi;ά&sigma;&eta;&sigmaf; &phi;ύ&sigma;&epsilon;&omega;&sigmaf;
          &zeta;ή&tau;&eta;&mu;&alpha; &delta;&iota;&alpha; &mu;έ&sigma;&omega;
          &tau;&eta;&sigmaf;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ί&alpha;&sigmaf;
          &mu;&epsilon;&tau;&alpha;&xi;ύ &mu;&alpha;&sigmaf;.&nbsp;</span
        >
      </p>
      <p><br /></p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >1. &Omicron;&rho;&iota;&sigma;&mu;&omicron;ί</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >1.1 &Nu;&omicron;&mu;&omicron;&theta;&epsilon;&sigma;ί&alpha;
          &pi;&epsilon;&rho;ί
          &Pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;&sigmaf;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&eta;&mu;&alpha;ί&nu;&epsilon;&iota; &kappa;ά&theta;&epsilon;
          &nu;ό&mu;&omicron;&sigmaf; &pi;&omicron;&upsilon;
          &alpha;&phi;&omicron;&rho;ά &sigma;&tau;&eta;&nu;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;&omicron;ύ
          &chi;&alpha;&rho;&alpha;&kappa;&tau;ή&rho;&alpha;,
          &sigma;&tau;&eta;&nu; &iota;&delta;&iota;&omega;&tau;&iota;&kappa;ή
          &zeta;&omega;ή &kappa;&alpha;&iota; &tau;&eta;&nu;
          &alpha;&sigma;&phi;ά&lambda;&epsilon;&iota;&alpha;,
          &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;&alpha;&nu;&omicron;&mu;έ&nu;&omega;&nu;,
          &chi;&omega;&rho;ί&sigmaf;
          &pi;&epsilon;&rho;&iota;&omicron;&rho;&iota;&sigma;&mu;ό,
          &tau;&omicron;&upsilon;
          &Kappa;&alpha;&nu;&omicron;&nu;&iota;&sigma;&mu;&omicron;ύ
          (&Epsilon;&Epsilon;) 2016/679 &laquo;&gamma;&iota;&alpha;
          &tau;&eta;&nu; &pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;
          &tau;&omega;&nu; &phi;&upsilon;&sigma;&iota;&kappa;ώ&nu;
          &pi;&rho;&omicron;&sigma;ώ&pi;&omega;&nu; έ&nu;&alpha;&nu;&tau;&iota;
          &tau;&eta;&sigmaf;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;&sigmaf;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;&omicron;ύ
          &chi;&alpha;&rho;&alpha;&kappa;&tau;ή&rho;&alpha; &kappa;&alpha;&iota;
          &gamma;&iota;&alpha; &tau;&eta;&nu;
          &epsilon;&lambda;&epsilon;ύ&theta;&epsilon;&rho;&eta;
          &kappa;&upsilon;&kappa;&lambda;&omicron;&phi;&omicron;&rho;ί&alpha;
          &tau;&omega;&nu; &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &alpha;&upsilon;&tau;ώ&nu; &kappa;&alpha;&iota; &tau;&eta;&nu;
          &kappa;&alpha;&tau;ά&rho;&gamma;&eta;&sigma;&eta; &tau;&eta;&sigmaf;
          &omicron;&delta;&eta;&gamma;ί&alpha;&sigmaf; 95/46/&Epsilon;&Kappa;
          (&Gamma;&epsilon;&nu;&iota;&kappa;ό&sigmaf;
          &Kappa;&alpha;&nu;&omicron;&nu;&iota;&sigma;&mu;ό&sigmaf;
          &gamma;&iota;&alpha; &tau;&eta;&nu;
          &Pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;)&raquo;
          (&laquo;&Gamma;&Kappa;&Pi;&Delta;&raquo;), &tau;&eta;&sigmaf;
          &Omicron;&delta;&eta;&gamma;ί&alpha;&sigmaf; 2002/58/&Epsilon;&Kappa;
          &laquo;&gamma;&iota;&alpha; &tau;&eta;&nu;
          &pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;
          &iota;&delta;&iota;&omega;&tau;&iota;&kappa;ή&sigmaf;
          &zeta;&omega;ή&sigmaf; &sigma;&tau;&iota;&sigmaf;
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;έ&sigmaf;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ί&epsilon;&sigmaf;&raquo;,
          ό&pi;&omega;&sigmaf;
          &epsilon;&nu;&sigma;&omega;&mu;&alpha;&tau;ώ&nu;&omicron;&nu;&tau;&alpha;&iota;,
          &iota;&sigma;&chi;ύ&omicron;&upsilon;&nu; &kappa;&alpha;&iota;
          &epsilon;&phi;&alpha;&rho;&mu;ό&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &sigma;&tau;&eta;&nu; &epsilon;&lambda;&lambda;&eta;&nu;&iota;&kappa;ή
          έ&nu;&nu;&omicron;&mu;&eta; &tau;ά&xi;&eta; &kappa;&alpha;&iota;
          ό&pi;&omega;&sigmaf;
          &epsilon;&kappa;ά&sigma;&tau;&omicron;&tau;&epsilon;
          &tau;&rho;&omicron;&pi;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;&tau;&alpha;&iota;,
          ή ά&lambda;&lambda;&omicron;&upsilon;&sigmaf;
          &epsilon;&phi;&alpha;&rho;&mu;&omicron;&sigma;&tau;έ&omicron;&upsilon;&sigmaf;
          ή &sigma;&epsilon;
          &alpha;&nu;&tau;&iota;&kappa;&alpha;&tau;ά&sigma;&tau;&alpha;&sigma;&eta;
          &tau;&omega;&nu; &alpha;&nu;&omega;&tau;έ&rho;&omega;
          &delta;&iota;&epsilon;&theta;&nu;&epsilon;ί&sigmaf; ή
          &epsilon;&theta;&nu;&iota;&kappa;&omicron;ύ&sigmaf;
          &nu;ό&mu;&omicron;&upsilon;&sigmaf; ή
          &kappa;&alpha;&nu;ό&nu;&epsilon;&sigmaf; &pi;&omicron;&upsilon;
          &alpha;&phi;&omicron;&rho;&omicron;ύ&nu; &sigma;&tau;&eta;&nu;
          &pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;&omicron;ύ
          &chi;&alpha;&rho;&alpha;&kappa;&tau;ή&rho;&alpha;,
          &alpha;&lambda;&lambda;ά &kappa;&alpha;&iota;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;&omicron;ύ&sigmaf;
          &kappa;&alpha;&nu;&omicron;&nu;&iota;&sigma;&mu;&omicron;ύ&sigmaf;,
          &omicron;&delta;&eta;&gamma;ί&epsilon;&sigmaf; ή
          &kappa;&alpha;&tau;&epsilon;&upsilon;&theta;&upsilon;&nu;&tau;ή&rho;&iota;&epsilon;&sigmaf;
          &gamma;&rho;&alpha;&mu;&mu;έ&sigmaf;
          &alpha;&rho;&mu;ό&delta;&iota;&omega;&nu;
          &delta;&iota;&omicron;&iota;&kappa;&eta;&tau;&iota;&kappa;ώ&nu;
          &alpha;&rho;&chi;ώ&nu;, ό&pi;&omega;&sigmaf; &eta;
          &Epsilon;&lambda;&lambda;&eta;&nu;&iota;&kappa;ή &Alpha;&rho;&chi;ή
          &Pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;&sigmaf;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;&omicron;ύ
          &Chi;&alpha;&rho;&alpha;&kappa;&tau;ή&rho;&alpha;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >1.2
          &laquo;&Upsilon;&pi;&epsilon;ύ&theta;&upsilon;&nu;&omicron;&sigmaf;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;&sigmaf;&raquo;,
          &laquo;&epsilon;&kappa;&tau;&epsilon;&lambda;ώ&nu; &tau;&eta;&nu;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;&raquo;,
          &laquo;&upsilon;&pi;&omicron;&kappa;&epsilon;ί&mu;&epsilon;&nu;&omicron;
          &tau;&omega;&nu;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;&raquo;,
          &laquo;&delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;&omicron;ύ
          &chi;&alpha;&rho;&alpha;&kappa;&tau;ή&rho;&alpha;&raquo;
          &kappa;&alpha;&iota;
          &laquo;&epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;&raquo;
          &theta;&alpha; έ&chi;&omicron;&upsilon;&nu; &tau;&omicron;
          &nu;ό&eta;&mu;&alpha; &pi;&omicron;&upsilon;
          &tau;&omicron;&upsilon;&sigmaf;
          &alpha;&pi;&omicron;&delta;ί&delta;&epsilon;&tau;&alpha;&iota;
          &sigma;&tau;&eta;&nu;
          &epsilon;&phi;&alpha;&rho;&mu;&omicron;&sigma;&tau;έ&alpha;
          &Nu;&omicron;&mu;&omicron;&theta;&epsilon;&sigma;ί&alpha;
          &pi;&epsilon;&rho;ί
          &Pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;&sigmaf;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >1.3 &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;&omicron;ύ
          &Chi;&alpha;&rho;&alpha;&kappa;&tau;ή&rho;&alpha;
          &sigma;&eta;&mu;&alpha;ί&nu;&epsilon;&iota; &tau;&omicron;
          &sigma;ύ&nu;&omicron;&lambda;&omicron; &tau;&omega;&nu;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;&omicron;ύ
          &chi;&alpha;&rho;&alpha;&kappa;&tau;ή&rho;&alpha;,
          ό&pi;&omega;&sigmaf; &alpha;&upsilon;&tau;ά
          &omicron;&rho;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &kappa;&alpha;&tau;ά &tau;&eta;&nu;
          &epsilon;&phi;&alpha;&rho;&mu;&omicron;&sigma;&tau;έ&alpha;
          &Nu;&omicron;&mu;&omicron;&theta;&epsilon;&sigma;ί&alpha;
          &pi;&epsilon;&rho;ί
          &Pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;&sigmaf;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;, &tau;&alpha;
          &omicron;&pi;&omicron;ί&alpha;
          &gamma;&nu;&omega;&sigma;&tau;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;&tau;&alpha;&iota;
          ή &epsilon;&nu;&delta;έ&chi;&epsilon;&tau;&alpha;&iota; &nu;&alpha;
          &gamma;&nu;&omega;&sigma;&tau;&omicron;&pi;&omicron;&iota;&eta;&theta;&omicron;ύ&nu;
          &sigma;&tau;&omicron;&nu;
          &Epsilon;&kappa;&tau;&epsilon;&lambda;&omicron;ύ&nu;&tau;&alpha;
          &tau;&eta;&nu;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
          ή &nu;&alpha; &tau;&epsilon;&theta;&omicron;ύ&nu; &sigma;&tau;&eta;
          &delta;&iota;ά&theta;&epsilon;&sigma;ή &tau;&omicron;&upsilon;
          &gamma;&iota;&alpha; &tau;&omicron;&upsilon;&sigmaf;
          &sigma;&kappa;&omicron;&pi;&omicron;ύ&sigmaf; &kappa;&alpha;&iota;
          &sigma;&tau;&omicron; &pi;&lambda;&alpha;ί&sigma;&iota;&omicron;
          &tau;&eta;&sigmaf;
          &epsilon;&kappa;ά&sigma;&tau;&omicron;&tau;&epsilon;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ί&alpha;&sigmaf;,
          ή/&kappa;&alpha;&iota; &tau;&alpha; &omicron;&pi;&omicron;ί&alpha;
          &omicron; &Epsilon;&kappa;&tau;&epsilon;&lambda;ώ&nu; &tau;&eta;&nu;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
          (ή/&kappa;&alpha;&iota; &omicron;
          &epsilon;&kappa;ά&sigma;&tau;&omicron;&tau;&epsilon;
          &upsilon;&pi;&epsilon;&rho;&gamma;&omicron;&lambda;ά&beta;&omicron;&sigmaf;
          &tau;&omicron;&upsilon;)
          &sigma;&upsilon;&lambda;&lambda;έ&gamma;&epsilon;&iota;,
          &alpha;&pi;&omicron;&theta;&eta;&kappa;&epsilon;ύ&epsilon;&iota; ή
          &kappa;&alpha;&theta;&rsquo;
          &omicron;&iota;&omicron;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
          ά&lambda;&lambda;&omicron;&nu; &tau;&rho;ό&pi;&omicron;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;ά&zeta;&epsilon;&tau;&alpha;&iota;
          &gamma;&iota;&alpha;
          &lambda;&omicron;&gamma;&alpha;&rho;&iota;&alpha;&sigma;&mu;ό
          &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;,
          &omega;&sigmaf; &epsilon;&kappa;&tau;&epsilon;&lambda;ώ&nu;
          &tau;&eta;&nu;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
          &sigma;&tau;&omicron; &pi;&lambda;&alpha;ί&sigma;&iota;&omicron;
          &tau;&eta;&sigmaf;
          &epsilon;&kappa;ά&sigma;&tau;&omicron;&tau;&epsilon;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ί&alpha;&sigmaf;
          &kappa;&alpha;&iota;
          &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;&sigmaf;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >1.4 &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;:</span
        ><span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp;&Omicron;&iota;
          &alpha;&nu;&alpha;&phi;&epsilon;&rho;ό&mu;&epsilon;&nu;&epsilon;&sigmaf;
          &sigma;&tau;&omicron; &epsilon;&pi;ί&sigma;&eta;&mu;&omicron; site
          &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;
          &mu;&alpha;&sigmaf;
          &alpha;&nu;&alpha;&phi;&epsilon;&rho;ό&mu;&epsilon;&nu;&epsilon;&sigmaf;
          &kappa;&alpha;&iota;
          &pi;&rho;&omicron;&sigma;&phi;&epsilon;&rho;ό&mu;&epsilon;&nu;&epsilon;&sigmaf;
          &pi;&rho;&omicron;&sigmaf; &chi;&rho;ή&sigma;&eta;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >1.5.
          &Delta;&iota;&alpha;&delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;&omicron;ί
          &Tau;ό&pi;&omicron;&iota;: &Alpha;&phi;&omicron;&rho;ά &tau;&omicron;
          &lsquo;www.stoferno.gr&rsquo; &pi;&omicron;&upsilon;
          &epsilon;ί&nu;&alpha;&iota; &omicron;
          &delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;ό&sigmaf;
          &tau;ό&pi;&omicron;&sigmaf; &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;
          &mu;&alpha;&sigmaf;, &eta; &omicron;&pi;&omicron;ί&alpha;
          &delta;&iota;&alpha;&tau;&eta;&rho;&epsilon;ί
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ή
          &pi;&lambda;&alpha;&tau;&phi;ό&rho;&mu;&alpha;
          &pi;&rho;&alpha;&gamma;&mu;&alpha;&tau;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;&sigmaf;
          &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;&iota;ώ&nu;
          &alpha;&pi;ό
          &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&zeta;ό&mu;&epsilon;&nu;&alpha;
          &kappa;&alpha;&tau;&alpha;&sigma;&tau;ή&mu;&alpha;&tau;&alpha;
          &upsilon;&gamma;&epsilon;&iota;&omicron;&nu;&omicron;&mu;&iota;&kappa;&omicron;ύ
          &epsilon;&nu;&delta;&iota;&alpha;&phi;έ&rho;&omicron;&nu;&tau;&omicron;&sigmaf;,
          &pi;&alpha;&rho;έ&chi;&omicron;&nu;&tau;&alpha;&sigmaf;
          &sigma;&tau;&omicron;&upsilon;&sigmaf;
          &pi;&epsilon;&lambda;ά&tau;&epsilon;&sigmaf; &tau;&eta;&sigmaf;
          ά&mu;&epsilon;&sigma;&eta;
          &epsilon;&xi;&upsilon;&pi;&eta;&rho;έ&tau;&eta;&sigma;&eta;
          &kappa;&alpha;&iota; &mu;&epsilon;&tau;&alpha;&phi;&omicron;&rho;ά
          &tau;&omega;&nu; &omega;&sigmaf; ά&nu;&omega;
          &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;&iota;ώ&nu;
          &mu;έ&sigma;&omega; &tau;&eta;&sigmaf;
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ή&sigmaf;
          &pi;&lambda;&alpha;&tau;&phi;ό&rho;&mu;&alpha;&sigmaf;
          &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf; &alpha;&pi;ό
          &delta;ί&kappa;&tau;&upsilon;&omicron;
          &alpha;&nu;&epsilon;&xi;ά&rho;&tau;&eta;&tau;&omega;&nu;
          &delta;&iota;&alpha;&nu;&omicron;&mu;έ&omega;&nu; &kappa;&alpha;&iota;
          &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&zeta;ό&mu;&epsilon;&nu;&omega;&nu;
          &kappa;&alpha;&tau;&alpha;&sigma;&tau;&eta;&mu;ά&tau;&omega;&nu;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >1.6.
          &Epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ώ&nu;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &epsilon;ί&nu;&alpha;&iota; &kappa;ά&theta;&epsilon;
          &pi;&rho;ά&xi;&eta; ή &sigma;&epsilon;&iota;&rho;ά
          &pi;&rho;ά&xi;&epsilon;&omega;&nu; &pi;&omicron;&upsilon;
          &pi;&rho;&alpha;&gamma;&mu;&alpha;&tau;&omicron;&pi;&omicron;&iota;&epsilon;ί&tau;&alpha;&iota;
          &mu;&epsilon; ή &chi;&omega;&rho;ί&sigmaf; &tau;&eta;
          &chi;&rho;ή&sigma;&eta;
          &alpha;&upsilon;&tau;&omicron;&mu;&alpha;&tau;&omicron;&pi;&omicron;&iota;&eta;&mu;έ&nu;&omega;&nu;
          &mu;έ&sigma;&omega;&nu;, &sigma;&epsilon;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;&omicron;ύ
          &chi;&alpha;&rho;&alpha;&kappa;&tau;ή&rho;&alpha; ή &sigma;&epsilon;
          &sigma;ύ&nu;&omicron;&lambda;&alpha;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;&omicron;ύ
          &chi;&alpha;&rho;&alpha;&kappa;&tau;ή&rho;&alpha;,
          ό&pi;&omega;&sigmaf; &eta;
          &sigma;&upsilon;&lambda;&lambda;&omicron;&gamma;ή,
          &kappa;&alpha;&tau;&alpha;&chi;ώ&rho;&iota;&sigma;&eta;,
          &omicron;&rho;&gamma;ά&nu;&omega;&sigma;&eta;,
          &delta;&iota;ά&rho;&theta;&rho;&omega;&sigma;&eta;,
          &alpha;&pi;&omicron;&theta;ή&kappa;&epsilon;&upsilon;&sigma;&eta;,
          &pi;&rho;&omicron;&sigma;&alpha;&rho;&mu;&omicron;&gamma;ή ή
          &mu;&epsilon;&tau;&alpha;&beta;&omicron;&lambda;ή,
          &alpha;&nu;ά&kappa;&tau;&eta;&sigma;&eta;,
          &alpha;&nu;&alpha;&zeta;ή&tau;&eta;&sigma;&eta;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;&iota;ώ&nu;,
          &chi;&rho;ή&sigma;&eta;,
          &kappa;&omicron;&iota;&nu;&omicron;&lambda;ό&gamma;&eta;&sigma;&eta;
          &mu;&epsilon; &delta;&iota;&alpha;&beta;ί&beta;&alpha;&sigma;&eta;,
          &delta;&iota;ά&delta;&omicron;&sigma;&eta; ή &kappa;ά&theta;&epsilon;
          ά&lambda;&lambda;&eta; &mu;&omicron;&rho;&phi;ή
          &delta;&iota;ά&theta;&epsilon;&sigma;&eta;&sigmaf;,
          &sigma;&upsilon;&sigma;&chi;έ&tau;&iota;&sigma;&eta; ή
          &sigma;&upsilon;&nu;&delta;&upsilon;&alpha;&sigma;&mu;ό&sigmaf;,
          &pi;&epsilon;&rho;&iota;&omicron;&rho;&iota;&sigma;&mu;ό&sigmaf;,
          &delta;&iota;&alpha;&gamma;&rho;&alpha;&phi;ή ή
          &kappa;&alpha;&tau;&alpha;&sigma;&tau;&rho;&omicron;&phi;ή.</span
        >
      </p>
      <p><br /></p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >2. &Sigma;&kappa;&omicron;&pi;ό&sigmaf;
          &Epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;&sigmaf;
          &kappa;&alpha;&iota;
          &Delta;&iota;ά&rho;&kappa;&epsilon;&iota;&alpha;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >2.1 &Eta; &Upsilon;&pi;&epsilon;ύ&theta;&upsilon;&nu;&eta;
          &Epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;&sigmaf;
          &pi;&rho;&iota;&nu;
          &pi;&alpha;&rho;&alpha;&delta;ώ&sigma;&epsilon;&iota;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &sigma;&tau;&omicron;&nu;
          &Epsilon;&kappa;&tau;&epsilon;&lambda;&omicron;ύ&nu;&tau;&alpha;
          &tau;&eta;&nu;
          &Epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
          &pi;&omicron;&upsilon;
          &epsilon;&mu;&pi;ί&pi;&tau;&omicron;&upsilon;&nu;
          &sigma;&tau;&omicron; &pi;&epsilon;&delta;ί&omicron;
          &epsilon;&phi;&alpha;&rho;&mu;&omicron;&gamma;ή&sigmaf;
          &tau;&eta;&sigmaf; &sigma;&chi;&epsilon;&tau;&iota;&kappa;ή&sigmaf;
          &nu;&omicron;&mu;&omicron;&theta;&epsilon;&sigma;ί&alpha;&sigmaf;
          &gamma;&iota;&alpha; &tau;&eta;&nu;
          &Pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &kappa;&alpha;&iota; &gamma;&iota;&alpha; &tau;&alpha;
          &omicron;&pi;&omicron;ί&alpha; &eta;
          &Upsilon;&pi;&epsilon;ύ&theta;&upsilon;&nu;&eta;
          &Epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;&sigmaf;
          &epsilon;ί&nu;&alpha;&iota; &kappa;&upsilon;&rho;ί&omega;&sigmaf;
          &upsilon;&pi;&epsilon;ύ&theta;&upsilon;&nu;&eta;
          ή/&kappa;&alpha;&iota; &upsilon;&pi;ό&lambda;&omicron;&gamma;&eta;
          &sigma;ύ&mu;&phi;&omega;&nu;&alpha; &mu;&epsilon; &tau;&eta;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;ή
          &nu;&omicron;&mu;&omicron;&theta;&epsilon;&sigma;ί&alpha;
          &gamma;&iota;&alpha; &tau;&eta;&nu;
          &Pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu; (&tau;&alpha;
          &quot;&Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &tau;&omicron;&upsilon;
          &Upsilon;&pi;&epsilon;&upsilon;&theta;ύ&nu;&omicron;&upsilon;
          &Epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;&sigmaf;&quot;)
          &delta;&upsilon;&nu;ά&mu;&epsilon;&iota; ή &sigma;&epsilon;
          &sigma;&chi;έ&sigma;&eta; &mu;&epsilon;
          &tau;&omicron;&upsilon;&sigmaf; Ό&rho;&omicron;&upsilon;&sigmaf;
          &kappa;&alpha;&iota;
          &Pi;&rho;&omicron;ϋ&pi;&omicron;&theta;έ&sigma;&epsilon;&iota;&sigmaf;
          &tau;&omicron;&upsilon; &pi;&alpha;&rho;ό&nu;&tau;&omicron;&sigmaf; ή
          &omicron;&pi;&omicron;&iota;&alpha;&sigma;&delta;ή&pi;&omicron;&tau;&epsilon;
          &Sigma;&upsilon;&mu;&phi;&omega;&nu;ί&alpha;&sigmaf;
          &pi;&rho;έ&pi;&epsilon;&iota; &nu;&alpha; έ&chi;&epsilon;&iota;
          &lambda;ά&beta;&epsilon;&iota; &alpha;&pi;ό &tau;&omicron;
          &upsilon;&pi;&omicron;&kappa;&epsilon;ί&mu;&epsilon;&nu;&omicron;
          &tau;&omega;&nu; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &tau;&eta;
          &sigma;&upsilon;&gamma;&kappa;&alpha;&tau;ά&theta;&epsilon;&sigma;&eta;
          &pi;&omicron;&upsilon;
          &alpha;&pi;&alpha;&iota;&tau;&epsilon;ί&tau;&alpha;&iota;
          &gamma;&iota;&alpha; &tau;&eta;
          &delta;&iota;&alpha;&beta;ί&beta;&alpha;&sigma;&eta;. &Tau;&omicron;
          ί&delta;&iota;&omicron; &iota;&sigma;&chi;ύ&epsilon;&iota;
          &gamma;&iota;&alpha; &tau;&eta;&nu;
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta; &pi;&omicron;&upsilon;
          &omicron; &Epsilon;&kappa;&tau;&epsilon;&lambda;ώ&nu; &tau;&eta;&nu;
          &Epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
          &sigma;&upsilon;&lambda;&lambda;έ&gamma;&epsilon;&iota;
          &kappa;&alpha;&iota;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;ά&zeta;&epsilon;&tau;&alpha;&iota;
          &epsilon;&nu; &gamma;έ&nu;&epsilon;&iota;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;&omicron;ύ
          &chi;&alpha;&rho;&alpha;&kappa;&tau;ή&rho;&alpha;
          &pi;&omicron;&upsilon; &lambda;&alpha;&mu;&beta;ά&nu;&epsilon;&iota;
          &alpha;&pi;&epsilon;&upsilon;&theta;&epsilon;ί&alpha;&sigmaf;
          &alpha;&pi;ό &tau;&omicron;
          &upsilon;&pi;&omicron;&kappa;&epsilon;ί&mu;&epsilon;&nu;&omicron;
          &gamma;&iota;&alpha;
          &lambda;&omicron;&gamma;&alpha;&rho;&iota;&alpha;&sigma;&mu;ό
          &tau;&eta;&sigmaf;
          &Upsilon;&pi;&epsilon;ύ&theta;&upsilon;&nu;&eta;&sigmaf;
          &Epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;&sigmaf;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >2.2 &Eta; &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
          &theta;&alpha;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;ά&zeta;&epsilon;&tau;&alpha;&iota;
          &tau;&alpha; &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha; &mu;&epsilon;
          &sigma;&kappa;&omicron;&pi;ό &tau;&eta;&nu;
          &pi;&alpha;&rho;&omicron;&chi;ή &tau;&omega;&nu;
          &pi;&rho;&omicron;&sigma;&phi;&epsilon;&rho;ό&mu;&epsilon;&nu;&omega;&nu;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;.
          &Alpha;&kappa;&omicron;&lambda;&omicron;ύ&theta;&omega;&sigmaf;,
          &theta;&alpha;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&epsilon;ί
          &tau;&alpha; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;&omicron;ύ
          &Chi;&alpha;&rho;&alpha;&kappa;&tau;ή&rho;&alpha; &mu;ό&nu;&omicron;
          ό&tau;&alpha;&nu; &epsilon;ί&nu;&alpha;&iota;
          &alpha;&pi;&omicron;&lambda;ύ&tau;&omega;&sigmaf;
          &alpha;&nu;&alpha;&gamma;&kappa;&alpha;ί&alpha; &gamma;&iota;&alpha;
          &tau;&eta;&nu; &epsilon;&kappa;&pi;&lambda;ή&rho;&omega;&sigma;&eta;
          &tau;&omega;&nu;
          &upsilon;&pi;&omicron;&chi;&rho;&epsilon;ώ&sigma;&epsilon;ώ&nu;
          &tau;&eta;&sigmaf;, &sigma;ύ&mu;&phi;&omega;&nu;&alpha; &mu;&epsilon;
          &tau;&omicron;&upsilon;&sigmaf; Ό&rho;&omicron;&upsilon;&sigmaf;
          &kappa;&alpha;&iota;
          &Pi;&rho;&omicron;ϋ&pi;&omicron;&theta;έ&sigma;&epsilon;&iota;&sigmaf;
          &pi;&omicron;&upsilon; &omicron; &Nu;ό&mu;&omicron;&sigmaf;
          &epsilon;&pi;&iota;&beta;ά&lambda;&lambda;&epsilon;&iota;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >2.3 &Omicron;&iota;
          &epsilon;&nu;έ&rho;&gamma;&epsilon;&iota;&epsilon;&sigmaf;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;&sigmaf;
          &theta;&alpha;
          &delta;&iota;&alpha;&rho;&kappa;έ&sigma;&omicron;&upsilon;&nu;
          &gamma;&iota;&alpha; &tau;&omicron;
          &chi;&rho;&omicron;&nu;&iota;&kappa;ό
          &delta;&iota;ά&sigma;&tau;&eta;&mu;&alpha; &pi;&omicron;&upsilon;
          &alpha;&pi;&alpha;&iota;&tau;&epsilon;ί&tau;&alpha;&iota;
          &gamma;&iota;&alpha; &tau;&eta;&nu;
          &omicron;&lambda;&omicron;&kappa;&lambda;ή&rho;&omega;&sigma;&eta;
          &tau;&omega;&nu;
          &Upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >2.4 &Pi;ά&nu;&tau;&omicron;&tau;&epsilon; &tau;&alpha;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha; &theta;&alpha;
          &pi;&rho;&omicron;&sigma;&tau;&alpha;&tau;&epsilon;ύ&omicron;&nu;&tau;&alpha;&iota;
          &alpha;&pi;ό &tau;&eta;&nu;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha; &omega;&sigmaf;
          &Upsilon;&pi;&epsilon;ύ&theta;&upsilon;&nu;&eta;
          &Epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;&sigmaf;
          &mu;&epsilon; &tau;&omicron;&nu;
          &chi;&alpha;&rho;&alpha;&kappa;&tau;ή&rho;&alpha;
          &Epsilon;&mu;&pi;&iota;&sigma;&tau;&epsilon;&upsilon;&tau;&iota;&kappa;ώ&nu;
          &pi;&rho;&omicron;&sigma;&tau;&alpha;&tau;&epsilon;&upsilon;ό&mu;&epsilon;&nu;&omega;&nu;
          &Pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;&iota;ώ&nu;.</span
        >
      </p>
      <p><br /></p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >3. &Epsilon;ί&nu;&alpha;&iota;
          &upsilon;&pi;&omicron;&chi;&rho;&epsilon;&omega;&tau;&iota;&kappa;ή
          &eta; &pi;&alpha;&rho;&alpha;&chi;ώ&rho;&eta;&sigma;&eta;
          &tau;&omega;&nu;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ώ&nu;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&alpha;&sigmaf;;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Eta; &pi;&alpha;&rho;&alpha;&chi;ώ&rho;&eta;&sigma;&eta;
          &tau;&omega;&nu; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&tau;&eta;&nu;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
          &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
          &epsilon;ί&nu;&alpha;&iota;
          &alpha;&pi;&alpha;&rho;&alpha;ί&tau;&eta;&tau;&eta;
          &gamma;&iota;&alpha; &nu;&alpha;
          &epsilon;&pi;&iota;&tau;&epsilon;&upsilon;&chi;&theta;&omicron;ύ&nu;
          &omicron;&iota; &sigma;&kappa;&omicron;&pi;&omicron;ί
          &pi;&omicron;&upsilon;
          &pi;&rho;&omicron;&sigma;&delta;&iota;&omicron;&rho;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &sigma;&tau;&eta;&nu; &pi;&alpha;&rho;&omicron;ύ&sigma;&alpha;
          &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή
          &Alpha;&pi;&omicron;&rho;&rho;ή&tau;&omicron;&upsilon; ή &nu;&alpha;
          &epsilon;ί&nu;&alpha;&iota;
          &pi;&rho;&omicron;&alpha;&iota;&rho;&epsilon;&tau;&iota;&kappa;ή.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Eta;
          &upsilon;&pi;&omicron;&chi;&rho;&epsilon;&omega;&tau;&iota;&kappa;ή ή
          &pi;&rho;&omicron;&alpha;&iota;&rho;&epsilon;&tau;&iota;&kappa;ή
          &phi;ύ&sigma;&eta; &tau;&eta;&sigmaf;
          &pi;&alpha;&rho;&alpha;&chi;ώ&rho;&eta;&sigma;&eta;&sigmaf;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &epsilon;&pi;&iota;&sigma;&eta;&mu;&alpha;ί&nu;&epsilon;&tau;&alpha;&iota;
          &mu;&epsilon; έ&nu;&alpha;&nu;
          &alpha;&sigma;&tau;&epsilon;&rho;ί&sigma;&kappa;&omicron; (*)
          &delta;ί&pi;&lambda;&alpha; &sigma;&tau;&alpha;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &upsilon;&pi;&omicron;&chi;&rho;&epsilon;&omega;&tau;&iota;&kappa;&omicron;ύ
          &chi;&alpha;&rho;&alpha;&kappa;&tau;ή&rho;&alpha;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Epsilon;ά&nu; &alpha;&rho;&nu;&eta;&theta;&epsilon;ί&tau;&epsilon;
          &nu;&alpha;
          &pi;&alpha;&rho;&alpha;&chi;&omega;&rho;ή&sigma;&epsilon;&tau;&epsilon;
          &tau;&alpha; &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha;
          &pi;&omicron;&upsilon;
          &epsilon;&pi;&iota;&sigma;&eta;&mu;&alpha;ί&nu;&omicron;&nu;&tau;&alpha;&iota;
          &omega;&sigmaf;
          &upsilon;&pi;&omicron;&chi;&rho;&epsilon;&omega;&tau;&iota;&kappa;ά
          &sigma;&tau;&omicron;&upsilon;&sigmaf;
          &Delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;&omicron;ύ&sigmaf;
          &Tau;ό&pi;&omicron;&upsilon;&sigmaf;, &theta;&alpha;
          &epsilon;ί&nu;&alpha;&iota; &alpha;&delta;ύ&nu;&alpha;&tau;&omicron;
          &nu;&alpha;
          &epsilon;&pi;&iota;&tau;&epsilon;&upsilon;&chi;&theta;&epsilon;ί
          &omicron; &beta;&alpha;&sigma;&iota;&kappa;ό&sigmaf;
          &sigma;&kappa;&omicron;&pi;ό&sigmaf; &gamma;&iota;&alpha;
          &tau;&omicron;&nu; &omicron;&pi;&omicron;ί&omicron;
          &sigma;&upsilon;&lambda;&lambda;έ&gamma;&omicron;&nu;&tau;&alpha;&iota;
          &tau;&alpha;
          &sigma;&upsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&alpha;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;, &kappa;&alpha;&iota;
          &mu;&pi;&omicron;&rho;&epsilon;ί, &gamma;&iota;&alpha;
          &pi;&alpha;&rho;ά&delta;&epsilon;&iota;&gamma;&mu;&alpha;, &nu;&alpha;
          &kappa;&alpha;&tau;&alpha;&sigma;&tau;&epsilon;ί
          &alpha;&delta;ύ&nu;&alpha;&tau;&eta; &gamma;&iota;&alpha;
          &tau;&eta;&nu; &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha; &eta;
          &epsilon;&kappa;&pi;&lambda;ή&rho;&omega;&sigma;&eta;&sigmaf;
          &tau;&eta;&sigmaf;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;&sigmaf;
          &mu;&epsilon;&tau;&alpha;&phi;&omicron;&rho;ά&sigmaf; ή &eta;
          &pi;&alpha;&rho;&omicron;&chi;ή &tau;&omega;&nu;
          ά&lambda;&lambda;&omega;&nu;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
          &pi;&omicron;&upsilon; &epsilon;ί&nu;&alpha;&iota;
          &delta;&iota;&alpha;&theta;έ&sigma;&iota;&mu;&epsilon;&sigmaf;
          &sigma;&tau;&omicron;&upsilon;&sigmaf;
          &Delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;&omicron;ύ&sigmaf;
          &Tau;ό&pi;&omicron;&upsilon;&sigmaf; &tau;&eta;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Eta; &pi;&alpha;&rho;&alpha;&chi;ώ&rho;&eta;&sigma;&eta;
          &epsilon;&pi;&iota;&pi;&rho;ό&sigma;&theta;&epsilon;&tau;&omega;&nu;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&tau;&eta;&nu;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;,
          &pi;έ&rho;&alpha;&nu; &epsilon;&kappa;&epsilon;ί&nu;&omega;&nu;
          &pi;&omicron;&upsilon;
          &epsilon;&pi;&iota;&sigma;&eta;&mu;&alpha;ί&nu;&omicron;&nu;&tau;&alpha;&iota;
          &omega;&sigmaf;
          &upsilon;&pi;&omicron;&chi;&rho;&epsilon;&omega;&tau;&iota;&kappa;ά,
          &epsilon;ί&nu;&alpha;&iota;
          &pi;&rho;&omicron;&alpha;&iota;&rho;&epsilon;&tau;&iota;&kappa;ή
          &kappa;&alpha;&iota; &delta;&epsilon;&nu;
          &epsilon;&pi;&iota;&phi;έ&rho;&epsilon;&iota;
          &sigma;&upsilon;&nu;έ&pi;&epsilon;&iota;&epsilon;&sigmaf;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά &mu;&epsilon;
          &tau;&omicron;&upsilon;&sigmaf;
          &kappa;ύ&rho;&iota;&omicron;&upsilon;&sigmaf;
          &sigma;&kappa;&omicron;&pi;&omicron;ύ&sigmaf; &tau;&eta;&sigmaf;
          &sigma;&upsilon;&lambda;&lambda;&omicron;&gamma;ή&sigmaf;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;,
          &alpha;&phi;&omicron;ύ &eta;
          &pi;&alpha;&rho;&alpha;&chi;ώ&rho;&eta;&sigma;ή
          &tau;&omicron;&upsilon;&sigmaf;
          &chi;&rho;&eta;&sigma;&iota;&mu;&epsilon;ύ&epsilon;&iota;
          &alpha;&pi;&omicron;&kappa;&lambda;&epsilon;&iota;&sigma;&tau;&iota;&kappa;ά
          &sigma;&tau;&eta;
          &beta;&epsilon;&lambda;&tau;&iota;&sigma;&tau;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
          &tau;&eta;&sigmaf; &pi;&omicron;&iota;ό&tau;&eta;&tau;&alpha;&sigmaf;
          &tau;&omega;&nu;
          &pi;&alpha;&rho;&epsilon;&chi;ό&mu;&epsilon;&nu;&omega;&nu;
          &alpha;&pi;ό &epsilon;&mu;ά&sigmaf;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >4. &Pi;&omicron;&iota;&alpha;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &sigma;&alpha;&sigmaf;
          &sigma;&upsilon;&lambda;&lambda;έ&gamma;&omicron;&upsilon;&mu;&epsilon;;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Phi;&rho;&omicron;&nu;&tau;ί&zeta;&omicron;&upsilon;&mu;&epsilon;
          &nu;&alpha;
          &sigma;&upsilon;&lambda;&lambda;έ&gamma;&omicron;&upsilon;&mu;&epsilon;
          &mu;ό&nu;&omicron; &tau;&alpha;
          &alpha;&pi;&omicron;&lambda;ύ&tau;&omega;&sigmaf;
          &alpha;&pi;&alpha;&rho;&alpha;ί&tau;&eta;&tau;&alpha;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &sigma;&alpha;&sigmaf;, &tau;&alpha; &omicron;&pi;&omicron;ί&alpha;
          &epsilon;ί&nu;&alpha;&iota;
          &kappa;&alpha;&tau;ά&lambda;&lambda;&eta;&lambda;&alpha;
          &kappa;&alpha;&iota; &sigma;&alpha;&phi;ή &gamma;&iota;&alpha;
          &tau;&omicron;&nu; &sigma;&kappa;&omicron;&pi;ό &pi;&omicron;&upsilon;
          &pi;&rho;&omicron;&omicron;&rho;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;.
          &Alpha;&upsilon;&tau;ά &tau;&alpha;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;ά&nu;&omicron;&upsilon;&nu;
          &tau;&alpha; &epsilon;&xi;ή&sigmaf;:</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&alpha;. &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &pi;&omicron;&upsilon; &mu;&alpha;&sigmaf;
          &pi;&alpha;&rho;έ&chi;&epsilon;&tau;&epsilon; &kappa;&alpha;&tau;ά
          &tau;&eta;&nu; &epsilon;&gamma;&gamma;&rho;&alpha;&phi;ή
          &sigma;&alpha;&sigmaf; &kappa;&alpha;&iota; &tau;&eta;
          &delta;&eta;&mu;&iota;&omicron;&upsilon;&rho;&gamma;ί&alpha;
          &lambda;&omicron;&gamma;&alpha;&rho;&iota;&alpha;&sigma;&mu;&omicron;ύ
          &chi;&rho;ή&sigma;&tau;&eta; &sigma;&tau;&omicron;&upsilon;&sigmaf;
          &Delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;&omicron;ύ&sigmaf;
          &Tau;ό&pi;&omicron;&upsilon;&sigmaf; ή &sigma;&tau;&alpha; Apps
          &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;,
          &mu;έ&sigma;&omega; &tau;&omicron;&upsilon;
          &delta;&iota;&alpha;&delta;&iota;&kappa;&tau;ύ&omicron;&upsilon; ή
          &tau;&omicron;&upsilon; &kappa;&iota;&nu;&eta;&tau;&omicron;ύ
          &sigma;&alpha;&sigmaf; ή &mu;έ&sigma;&omega; &tau;&eta;&sigmaf;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ή&sigmaf;
          &sigma;&alpha;&sigmaf; &epsilon;&pi;&alpha;&phi;ή&sigmaf;
          &mu;&epsilon; &tau;&alpha;
          &kappa;&alpha;&tau;&alpha;&sigma;&tau;ή&mu;&alpha;&tau;&alpha;
          &mu;&alpha;&sigmaf; ή &mu;&epsilon; &tau;&omicron;&upsilon;&sigmaf;
          &pi;&omega;&lambda;&eta;&tau;έ&sigmaf; &mu;&alpha;&sigmaf;
          &kappa;&alpha;&iota;
          &sigma;&upsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&alpha;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha; ό&pi;&omega;&sigmaf;
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ή
          &delta;&iota;&epsilon;ύ&theta;&upsilon;&nu;&sigma;&eta; (e-mail)*
          &kappa;&alpha;&iota; &kappa;&omega;&delta;&iota;&kappa;ό
          &pi;&rho;ό&sigma;&beta;&alpha;&sigma;&eta;&sigmaf;/login password
          (&omega;&sigmaf;
          &upsilon;&pi;&omicron;&chi;&rho;&epsilon;&omega;&tau;&iota;&kappa;ά)
          &kappa;&alpha;&iota; ό&nu;&omicron;&mu;&alpha;,
          &epsilon;&pi;ί&theta;&epsilon;&tau;&omicron;,
          &tau;&alpha;&chi;&upsilon;&delta;&rho;&omicron;&mu;&iota;&kappa;ή
          &delta;&iota;&epsilon;ύ&theta;&upsilon;&nu;&sigma;&eta;,
          &alpha;&rho;&iota;&theta;&mu;ό
          &tau;&eta;&lambda;&epsilon;&phi;ώ&nu;&omicron;&upsilon;
          (&omega;&sigmaf;
          &pi;&rho;&omicron;&alpha;&iota;&rho;&epsilon;&tau;&iota;&kappa;ά):</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&beta;. &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &kappa;&alpha;&iota;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
          &pi;&omicron;&upsilon; &mu;&alpha;&sigmaf;
          &pi;&alpha;&rho;έ&chi;&epsilon;&tau;&epsilon; &mu;έ&sigma;&alpha;
          &alpha;&pi;ό &tau;&iota;&sigmaf; &mu;&epsilon;&tau;&alpha;&xi;ύ
          &mu;&alpha;&sigmaf;
          &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;έ&sigmaf;
          (&alpha;&gamma;&omicron;&rho;έ&sigmaf;,
          &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&epsilon;&sigmaf;
          &kappa;.&lambda;&pi;.) &kappa;&alpha;&iota; &tau;&eta;
          &mu;&epsilon;&tau;&alpha;&xi;ύ &mu;&alpha;&sigmaf;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ί&alpha; (
          &tau;&omicron;&upsilon;
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;&omicron;ύ
          &mu;&alpha;&sigmaf;
          &kappa;&alpha;&tau;&alpha;&sigma;&tau;ή&mu;&alpha;&tau;&omicron;&sigmaf;,
          &tau;&omega;&nu; &pi;&omega;&lambda;&eta;&tau;ώ&nu;
          &mu;&alpha;&sigmaf;, &tau;&omicron;&upsilon;
          &tau;&eta;&lambda;&epsilon;&phi;ώ&nu;&omicron;&upsilon;,
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;&omicron;ύ
          &tau;&alpha;&chi;&upsilon;&delta;&rho;&omicron;&mu;&epsilon;ί&omicron;&upsilon;
          ή &mu;έ&sigma;&omega;
          &omicron;&pi;&omicron;&iota;&omicron;&upsilon;&delta;ή&pi;&omicron;&tau;&epsilon;
          ά&lambda;&lambda;&omicron;&upsilon;
          &tau;&rho;ό&pi;&omicron;&upsilon;). &Gamma;&iota;&alpha;
          &pi;&alpha;&rho;ά&delta;&epsilon;&iota;&gamma;&mu;&alpha;,
          &sigma;&upsilon;&lambda;&lambda;έ&gamma;&omicron;&upsilon;&mu;&epsilon;
          &sigma;&eta;&mu;&epsilon;&iota;ώ&sigma;&epsilon;&iota;&sigmaf;
          &alpha;&pi;ό &tau;&iota;&sigmaf;
          &sigma;&upsilon;&nu;&omicron;&mu;&iota;&lambda;ί&epsilon;&sigmaf;
          &mu;&alpha;&sigmaf; &mu;&alpha;&zeta;ί &sigma;&alpha;&sigmaf;,
          &lambda;&epsilon;&pi;&tau;&omicron;&mu;έ&rho;&epsilon;&iota;&epsilon;&sigmaf;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά &mu;&epsilon;
          &tau;&upsilon;&chi;ό&nu; &pi;&alpha;&rho;ά&pi;&omicron;&nu;&alpha; ή
          &sigma;&chi;ό&lambda;&iota;&alpha; &pi;&omicron;&upsilon;
          &kappa;ά&nu;&epsilon;&tau;&epsilon;,
          &lambda;&epsilon;&pi;&tau;&omicron;&mu;έ&rho;&epsilon;&iota;&epsilon;&sigmaf;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά &mu;&epsilon;
          &tau;&iota;&sigmaf; &alpha;&gamma;&omicron;&rho;έ&sigmaf;
          &pi;&omicron;&upsilon; &kappa;ά&nu;&alpha;&tau;&epsilon;,
          &pi;&rho;&omicron;ϊό&nu;&tau;&alpha; &pi;&omicron;&upsilon;
          &pi;&rho;&omicron;&sigma;&tau;έ&theta;&eta;&kappa;&alpha;&nu;
          &sigma;&tau;&omicron; &kappa;&alpha;&lambda;ά&theta;&iota;
          &sigma;&alpha;&sigmaf; ή
          &alpha;&phi;&alpha;&iota;&rho;έ&theta;&eta;&kappa;&alpha;&nu;
          &alpha;&pi;ό &alpha;&upsilon;&tau;ό, &lambda;ί&sigma;&tau;&alpha;
          &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu; &pi;&omicron;&upsilon;
          &epsilon;&pi;&iota;&theta;&upsilon;&mu;&epsilon;ί&tau;&epsilon;
          &nu;&alpha;
          &alpha;&gamma;&omicron;&rho;ά&sigma;&epsilon;&tau;&epsilon; (wish
          list),
          &epsilon;&xi;&alpha;&rho;&gamma;&upsilon;&rho;ώ&sigma;&epsilon;&iota;&sigmaf;
          &kappa;&omicron;&upsilon;&pi;&omicron;&nu;&iota;ώ&nu;,
          &pi;&omicron;&iota;&epsilon;&sigmaf; &alpha;&pi;ό &tau;&iota;&sigmaf;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&epsilon;&sigmaf;
          &mu;&alpha;&sigmaf;
          &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&epsilon;&sigma;&tau;&epsilon;
          &kappa;&alpha;&iota; &pi;ώ&sigmaf; &kappa;&alpha;&iota;
          &pi;ό&tau;&epsilon;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;&epsilon;ί&tau;&epsilon;
          &mu;&alpha;&zeta;ί &mu;&alpha;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&gamma;. &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &pi;&omicron;&upsilon; &alpha;&phi;&omicron;&rho;&omicron;ύ&nu;
          &sigma;&tau;&omicron;&nu; &tau;&rho;ό&pi;&omicron;
          &pi;&lambda;&eta;&rho;&omega;&mu;ή&sigmaf; &gamma;&iota;&alpha;
          &tau;&iota;&sigmaf;
          &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;έ&sigmaf;
          &pi;&omicron;&upsilon;
          &pi;&rho;&alpha;&gamma;&mu;&alpha;&tau;&omicron;&pi;&omicron;&iota;&epsilon;ί&tau;&epsilon;
          &mu;&alpha;&zeta;ί &mu;&alpha;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&delta;. &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &pi;&omicron;&upsilon; &mu;&alpha;&sigmaf;
          &pi;&alpha;&rho;έ&chi;&epsilon;&tau;&epsilon; ό&tau;&alpha;&nu;
          &kappa;ά&nu;&epsilon;&tau;&epsilon;
          &epsilon;&gamma;&gamma;&rho;&alpha;&phi;ή &sigma;&epsilon; newsletter
          &mu;&alpha;&sigmaf; ή &pi;ά&sigma;&eta;&sigmaf;
          &phi;ύ&sigma;&epsilon;&omega;&sigmaf;
          &epsilon;&nu;&eta;&mu;&epsilon;&rho;&omega;&tau;&iota;&kappa;ό
          &upsilon;&lambda;&iota;&kappa;ό &mu;&alpha;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&epsilon;. &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &gamma;&iota;&alpha; &tau;&alpha; &pi;&rho;&omicron;ϊό&nu;&tau;&alpha;
          &kappa;&alpha;&iota; &tau;&iota;&sigmaf;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
          &pi;&omicron;&upsilon; &kappa;&alpha;&tau;ά
          &pi;&rho;&omicron;&tau;ί&mu;&eta;&sigma;&eta;
          &epsilon;&pi;&iota;&lambda;έ&gamma;&epsilon;&tau;&epsilon;
          &sigma;&upsilon;&nu;ή&theta;&omega;&sigmaf;.
          &Pi;&rho;&omicron;&kappa;&epsilon;&iota;&mu;έ&nu;&omicron;&upsilon;
          &nu;&alpha; &sigma;&alpha;&sigmaf;
          &pi;&rho;&omicron;&tau;&epsilon;ί&nu;&omicron;&upsilon;&mu;&epsilon;
          &pi;&rho;&omicron;ϊό&nu;&tau;&alpha; ή
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
          &tau;&omicron;&upsilon;
          &epsilon;&nu;&delta;&iota;&alpha;&phi;έ&rho;&omicron;&nu;&tau;ό&sigmaf;
          &sigma;&alpha;&sigmaf; &kappa;&alpha;&iota; &nu;&alpha;
          &beta;&epsilon;&lambda;&tau;&iota;ώ&sigma;&omicron;&upsilon;&mu;&epsilon;
          &alpha;&kappa;ό&mu;&alpha;
          &pi;&epsilon;&rho;&iota;&sigma;&sigma;ό&tau;&epsilon;&rho;&omicron;
          &tau;&eta;&nu; &epsilon;&mu;&pi;&epsilon;&iota;&rho;ί&alpha;
          &alpha;&gamma;&omicron;&rho;ώ&nu; &kappa;&alpha;&iota; &epsilon;&nu;
          &gamma;έ&nu;&epsilon;&iota;
          &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;ώ&nu;
          &sigma;&alpha;&sigmaf; &mu;&alpha;&zeta;ί &mu;&alpha;&sigmaf;.
          &Phi;&upsilon;&sigma;&iota;&kappa;ά, έ&chi;&epsilon;&tau;&epsilon;
          &pi;ά&nu;&tau;&alpha; &tau;&eta;&nu;
          &epsilon;&pi;&iota;&lambda;&omicron;&gamma;ή &nu;&alpha; &mu;&eta;&nu;
          &mu;&omicron;&iota;&rho;ά&zeta;&epsilon;&sigma;&tau;&epsilon;
          &tau;έ&tau;&omicron;&iota;&alpha;
          &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha; &mu;&alpha;&zeta;ί
          &mu;&alpha;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&sigma;&tau;. &Sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha;
          &epsilon;&pi;&iota;&sigma;&kappa;&epsilon;&psi;&iota;&mu;ό&tau;&eta;&tau;&alpha;&sigmaf;
          &tau;&eta;&sigmaf;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
          &mu;&alpha;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&zeta;.
          &Pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
          &pi;&omicron;&upsilon;
          &sigma;&upsilon;&lambda;&lambda;έ&gamma;&omicron;&nu;&tau;&alpha;&iota;
          &alpha;&pi;ό &tau;&eta; &chi;&rho;ή&sigma;&eta; cookies
          &sigma;&tau;&omicron; &pi;&rho;ό&gamma;&rho;&alpha;&mu;&mu;&alpha;
          &pi;&epsilon;&rho;&iota;ή&gamma;&eta;&sigma;ή&sigmaf;
          &sigma;&alpha;&sigmaf;.&nbsp;</span
        ><span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #ff0000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Mu;ά&theta;&epsilon;&tau;&epsilon;
          &pi;&epsilon;&rho;&iota;&sigma;&sigma;ό&tau;&epsilon;&rho;&alpha;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά &mu;&epsilon;
          &tau;&omicron;&nu; &tau;&rho;ό&pi;&omicron;
          &chi;&rho;ή&sigma;&eta;&sigmaf; &tau;&omega;&nu; cookies
          &epsilon;&delta;ώ (Link).</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&eta;. &Gamma;&iota;&alpha; &nu;&alpha;
          &pi;&rho;&omicron;&sigma;&phi;έ&rho;&omicron;&upsilon;&mu;&epsilon;
          &tau;&eta;&nu; &kappa;&alpha;&lambda;ύ&tau;&epsilon;&rho;&eta;
          &delta;&upsilon;&nu;&alpha;&tau;ή
          &epsilon;&mu;&pi;&epsilon;&iota;&rho;ί&alpha;
          &iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;,
          &sigma;&upsilon;&lambda;&lambda;έ&gamma;&omicron;&upsilon;&mu;&epsilon;
          &tau;&epsilon;&chi;&nu;&iota;&kappa;έ&sigmaf;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά &mu;&epsilon; &tau;&eta;
          &sigma;ύ&nu;&delta;&epsilon;&sigma;ή &sigma;&alpha;&sigmaf;
          &sigma;&tau;&omicron;
          &delta;&iota;&alpha;&delta;ί&kappa;&tau;&upsilon;&omicron;
          &kappa;&alpha;&iota; &tau;&omicron;
          &pi;&rho;ό&gamma;&rho;&alpha;&mu;&mu;&alpha;
          &pi;&epsilon;&rho;&iota;ή&gamma;&eta;&sigma;&eta;&sigmaf;,
          &kappa;&alpha;&theta;ώ&sigmaf; &kappa;&alpha;&iota; &tau;&omicron;&nu;
          &kappa;&omega;&delta;&iota;&kappa;ό &chi;ώ&rho;&alpha;&sigmaf;
          &kappa;&alpha;&iota;
          &tau;&eta;&lambda;&epsilon;&phi;ώ&nu;&omicron;&upsilon;
          ό&pi;&omicron;&upsilon;
          &beta;&rho;ί&sigma;&kappa;&epsilon;&tau;&alpha;&iota; &omicron;
          &upsilon;&pi;&omicron;&lambda;&omicron;&gamma;&iota;&sigma;&tau;ή&sigmaf;
          &sigma;&alpha;&sigmaf;, &tau;&iota;&sigmaf;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&epsilon;&sigmaf;
          &pi;&omicron;&upsilon;
          &epsilon;&mu;&phi;&alpha;&nu;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &kappa;&alpha;&tau;ά &tau;&eta;&nu;
          &epsilon;&pi;ί&sigma;&kappa;&epsilon;&psi;ή &sigma;&alpha;&sigmaf;,
          &tau;&iota;&sigmaf;
          &delta;&iota;&alpha;&phi;&eta;&mu;ί&sigma;&epsilon;&iota;&sigmaf;
          &sigma;&tau;&iota;&sigmaf; &omicron;&pi;&omicron;ί&epsilon;&sigmaf;
          &kappa;ά&nu;&epsilon;&tau;&epsilon; &kappa;&lambda;&iota;&kappa;
          &kappa;&alpha;&iota; ό&pi;&omicron;&iota;&omicron;&upsilon;&sigmaf;
          ό&rho;&omicron;&upsilon;&sigmaf;
          &alpha;&nu;&alpha;&zeta;ή&tau;&eta;&sigma;&eta;&sigmaf;
          &mu;&pi;ή&kappa;&epsilon;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&theta;. &Tau;&omicron; ό&nu;&omicron;&mu;&alpha;
          &chi;&rho;ή&sigma;&tau;&eta; &tau;&omicron;&upsilon;
          &kappa;&omicron;&iota;&nu;&omega;&nu;&iota;&kappa;&omicron;ύ
          &mu;έ&sigma;&omicron;&upsilon;
          &delta;&iota;&kappa;&tau;ύ&omega;&sigma;ή&sigmaf;
          &sigma;&alpha;&sigmaf;, &epsilon;ά&nu;
          &alpha;&lambda;&lambda;&eta;&lambda;&epsilon;&pi;&iota;&delta;&rho;ά&tau;&epsilon;
          &mu;&alpha;&zeta;ί &mu;&alpha;&sigmaf; &mu;έ&sigma;&omega;
          &alpha;&upsilon;&tau;ώ&nu; &tau;&omega;&nu;
          &kappa;&alpha;&nu;&alpha;&lambda;&iota;ώ&nu;, &gamma;&iota;&alpha;
          &nu;&alpha; &mu;&alpha;&sigmaf;
          &beta;&omicron;&eta;&theta;ή&sigma;&epsilon;&tau;&epsilon; &nu;&alpha;
          &alpha;&pi;&alpha;&nu;&tau;ή&sigma;&omicron;&upsilon;&mu;&epsilon;
          &sigma;&tau;&alpha; &sigma;&chi;ό&lambda;&iota;&alpha;,
          &tau;&iota;&sigmaf;
          &epsilon;&rho;&omega;&tau;ή&sigma;&epsilon;&iota;&sigmaf; ή
          &tau;&alpha; &sigma;&chi;ό&lambda;&iota;ά
          &sigma;&alpha;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&iota;. &Mu;&omicron;&rho;&phi;&omega;&tau;&iota;&kappa;ά
          &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha;,
          ό&pi;&omega;&sigmaf; &sigma;&pi;&omicron;&upsilon;&delta;έ&sigmaf;,
          &delta;&epsilon;&xi;&iota;ό&tau;&eta;&tau;&epsilon;&sigmaf;,
          &gamma;&nu;ώ&sigma;&eta; &xi;έ&nu;&omega;&nu;
          &gamma;&lambda;&omega;&sigma;&sigma;ώ&nu;,
          &epsilon;&pi;&alpha;&gamma;&gamma;&epsilon;&lambda;&mu;&alpha;&tau;&iota;&kappa;ή
          &epsilon;&mu;&pi;&epsilon;&iota;&rho;ί&alpha; (&mu;ό&nu;&omicron;
          &sigma;&tau;&iota;&sigmaf;
          &pi;&epsilon;&rho;&iota;&pi;&tau;ώ&sigma;&epsilon;&iota;&sigmaf;
          &pi;&omicron;&upsilon; &alpha;&pi;&alpha;&nu;&tau;ά&tau;&epsilon;
          &sigma;&epsilon; &alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;
          &gamma;&iota;&alpha; &epsilon;ύ&rho;&epsilon;&sigma;&eta;
          &epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;&sigmaf;)</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >5. &Pi;ώ&sigmaf;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&mu;&epsilon;
          &tau;&alpha; &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &sigma;&alpha;&sigmaf;;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Kappa;&alpha;&tau;ά
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;&nbsp;&chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&mu;&epsilon;
          &tau;&alpha; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &sigma;&alpha;&sigmaf;:</span
        >
      </p>
      <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 10pt;
            font-family: 'Noto Sans Symbols', sans-serif;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&Gamma;&iota;&alpha; &nu;&alpha;
              &omicron;&lambda;&omicron;&kappa;&lambda;&eta;&rho;ώ&sigma;&omicron;&upsilon;&mu;&epsilon;
              &tau;&iota;&sigmaf;
              &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&epsilon;&sigmaf;
              &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu; &kappa;&alpha;&iota;
              &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;: &Eta;
              &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
              &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;ά&zeta;&epsilon;&tau;&alpha;&iota;
              &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
              &sigma;&alpha;&sigmaf;
              &pi;&rho;&omicron;&kappa;&epsilon;&iota;&mu;έ&nu;&omicron;&upsilon;
              &nu;&alpha;
              &epsilon;&kappa;&pi;&lambda;&eta;&rho;ώ&sigma;&epsilon;&iota;
              &tau;&eta; &sigma;&upsilon;&mu;&beta;&alpha;&tau;&iota;&kappa;ή
              &tau;&eta;&sigmaf; &sigma;&chi;έ&sigma;&eta;, &nu;&alpha;
              &delta;&iota;&epsilon;&kappa;&pi;&epsilon;&rho;&alpha;&iota;ώ&sigma;&epsilon;&iota;
              &tau;&eta;&nu;
              &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;
              &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu; ή/&kappa;&alpha;&iota;
              &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;, &nu;&alpha;
              &pi;&alpha;&rho;έ&chi;&epsilon;&iota;
              &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
              &epsilon;&xi;&upsilon;&pi;&eta;&rho;έ&tau;&eta;&sigma;&eta;&sigmaf;
              &pi;&epsilon;&lambda;&alpha;&tau;ώ&nu;, &nu;&alpha;
              &sigma;&upsilon;&mu;&mu;&omicron;&rho;&phi;&omega;&theta;&epsilon;ί
              &mu;&epsilon; &nu;&omicron;&mu;&iota;&kappa;έ&sigmaf;
              &upsilon;&pi;&omicron;&chi;&rho;&epsilon;ώ&sigma;&epsilon;&iota;&sigmaf;,
              &nu;&alpha;
              &alpha;&nu;&tau;&iota;&kappa;&rho;&omicron;ύ&sigma;&epsilon;&iota;,
              &epsilon;&gamma;&epsilon;ί&rho;&epsilon;&iota; ή
              &alpha;&sigma;&kappa;ή&sigma;&epsilon;&iota;
              &nu;&omicron;&mu;&iota;&kappa;έ&sigmaf;
              &alpha;&pi;&alpha;&iota;&tau;ή&sigma;&epsilon;&iota;&sigmaf;.
              &Epsilon;ά&nu; &delta;&epsilon;&nu;
              &sigma;&upsilon;&lambda;&lambda;έ&xi;&omicron;&upsilon;&mu;&epsilon;
              &tau;&alpha; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
              &sigma;&alpha;&sigmaf; &kappa;&alpha;&tau;ά &tau;&eta;&nu;
              &omicron;&lambda;&omicron;&kappa;&lambda;ή&rho;&omega;&sigma;&eta;
              &tau;&eta;&sigmaf;
              &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;
              (&mu;έ&sigma;&omega;
              &tau;&eta;&lambda;&epsilon;&phi;ώ&nu;&omicron;&upsilon;
              &epsilon;&xi;&upsilon;&pi;&eta;&rho;έ&tau;&eta;&sigma;&eta;&sigmaf;
              ή &mu;έ&sigma;&omega; &tau;&omicron;&upsilon;
              &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;&omicron;ύ
              &mu;&alpha;&sigmaf;
              &kappa;&alpha;&tau;&alpha;&sigma;&tau;ή&mu;&alpha;&tau;&omicron;&sigmaf;),
              &delta;&epsilon;&nu; &theta;&alpha;
              &mu;&pi;&omicron;&rho;έ&sigma;&omicron;&upsilon;&mu;&epsilon;
              &nu;&alpha;
              &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;&tau;&omicron;ύ&mu;&epsilon;
              &tau;&eta;&nu;
              &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;
              &sigma;&alpha;&sigmaf; &kappa;&alpha;&iota; &nu;&alpha;
              &sigma;&upsilon;&mu;&mu;&omicron;&rho;&phi;&omega;&theta;&omicron;ύ&mu;&epsilon;
              &mu;&epsilon; &tau;&iota;&sigmaf;
              &nu;&omicron;&mu;&iota;&kappa;έ&sigmaf; &mu;&alpha;&sigmaf;
              &upsilon;&pi;&omicron;&chi;&rho;&epsilon;ώ&sigma;&epsilon;&iota;&sigmaf;.
              &Epsilon;&pi;&iota;&sigma;&eta;&mu;&alpha;ί&nu;&omicron;&upsilon;&mu;&epsilon;
              ό&tau;&iota; &epsilon;&nu;&delta;έ&chi;&epsilon;&tau;&alpha;&iota;
              &nu;&alpha; &chi;&rho;&epsilon;&iota;&alpha;&sigma;&tau;&epsilon;ί
              &nu;&alpha;
              &mu;&epsilon;&tau;&alpha;&beta;&iota;&beta;&alpha;&sigma;&tau;&omicron;ύ&nu;
              &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
              &sigma;&alpha;&sigmaf; &sigma;&epsilon;
              &tau;&rho;ί&tau;&omicron;&upsilon;&sigmaf; &gamma;&iota;&alpha;
              &tau;&eta;&nu; &pi;&alpha;&rho;ά&delta;&omicron;&sigma;&eta;
              &tau;&omicron;&upsilon;
              &pi;&rho;&omicron;ϊό&nu;&tau;&omicron;&sigmaf; ή
              &tau;&eta;&sigmaf;
              &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;&sigmaf;
              &pi;&omicron;&upsilon; έ&chi;&epsilon;&tau;&epsilon;
              &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;ί&lambda;&epsilon;&iota;
              (&Gamma;&iota;&alpha;
              &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
              &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά &mu;&epsilon;
              &tau;&omicron;&nu; &tau;&rho;ό&pi;&omicron; &mu;&epsilon;
              &tau;&omicron;&nu; &omicron;&pi;&omicron;ί&omicron;
              &delta;&iota;&alpha;&theta;έ&tau;&omicron;&upsilon;&mu;&epsilon;
              &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
              &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha; &sigma;&epsilon;
              &tau;&rho;ί&tau;&omicron;&upsilon;&sigmaf;,
              &delta;&epsilon;ί&tau;&epsilon; &tau;&omicron;&upsilon;&sigmaf;
              &sigma;&chi;&epsilon;&tau;&iota;&kappa;&omicron;ύ&sigmaf;
              ό&rho;&omicron;&upsilon;&sigmaf;
              &kappa;&alpha;&tau;&omega;&tau;έ&rho;&omega;).</span
            >
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Epsilon;&pi;&iota;&pi;&lambda;έ&omicron;&nu;,
          &mu;&pi;&omicron;&rho;&omicron;ύ&mu;&epsilon; &nu;&alpha;
          &delta;&iota;&alpha;&tau;&eta;&rho;ή&sigma;&omicron;&upsilon;&mu;&epsilon;
          &tau;&alpha; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &sigma;&alpha;&sigmaf; &gamma;&iota;&alpha;
          &epsilon;ύ&lambda;&omicron;&gamma;&omicron;
          &chi;&rho;&omicron;&nu;&iota;&kappa;ό
          &delta;&iota;ά&sigma;&tau;&eta;&mu;&alpha;
          &pi;&rho;&omicron;&kappa;&epsilon;&iota;&mu;έ&nu;&omicron;&upsilon;
          &nu;&alpha;
          &epsilon;&kappa;&pi;&lambda;&eta;&rho;ώ&sigma;&omicron;&upsilon;&mu;&epsilon;
          &tau;&iota;&sigmaf;
          &sigma;&upsilon;&mu;&beta;&alpha;&tau;&iota;&kappa;έ&sigmaf;
          &mu;&alpha;&sigmaf;
          &upsilon;&pi;&omicron;&chi;&rho;&epsilon;ώ&sigma;&epsilon;&iota;&sigmaf;,
          ό&pi;&omega;&sigmaf;
          &epsilon;&pi;&iota;&sigma;&tau;&rho;&omicron;&phi;έ&sigmaf;
          &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu;, ό&pi;&omega;&sigmaf; &eta;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;ή
          &nu;&omicron;&mu;&omicron;&theta;&epsilon;&sigma;ί&alpha;
          &pi;&rho;&omicron;&beta;&lambda;έ&pi;&epsilon;&iota;.</span
        >
      </p>
      <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&Gamma;&iota;&alpha; &tau;&eta;
              &Delta;&eta;&mu;&iota;&omicron;&upsilon;&rho;&gamma;ί&alpha;
              &Lambda;&omicron;&gamma;&alpha;&rho;&iota;&alpha;&sigma;&mu;&omicron;ύ
              &Chi;&rho;ή&sigma;&tau;&eta;: &Eta;
              &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
              &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;ά&zeta;&epsilon;&tau;&alpha;&iota;
              &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
              &sigma;&alpha;&sigmaf;
              &pi;&rho;&omicron;&kappa;&epsilon;&iota;&mu;έ&nu;&omicron;&upsilon;
              &nu;&alpha; &sigma;&alpha;&sigmaf;
              &pi;&alpha;&rho;έ&chi;&epsilon;&iota; &tau;&iota;&sigmaf;
              &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&epsilon;&sigmaf;
              &lambda;&omicron;&gamma;&alpha;&rho;&iota;&alpha;&sigma;&mu;&omicron;ύ
              &kappa;&alpha;&iota; &nu;&alpha;
              &delta;&iota;&epsilon;&upsilon;&kappa;&omicron;&lambda;&upsilon;&nu;&theta;&epsilon;ί
              &eta; &sigma;ύ&nu;&alpha;&psi;&eta;
              &alpha;&gamma;&omicron;&rho;ά&sigmaf;
              &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu; ή/&kappa;&alpha;&iota;
              &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;.</span
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&Gamma;&iota;&alpha; &tau;&eta;&nu;
              &Epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ί&alpha;:
              &Eta; &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
              &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&epsilon;ί
              &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
              &sigma;&alpha;&sigmaf; &gamma;&iota;&alpha; &nu;&alpha;
              &alpha;&pi;&alpha;&nu;&tau;ή&sigma;&epsilon;&iota;
              &sigma;&tau;&alpha;
              &alpha;&iota;&tau;ή&mu;&alpha;&tau;&alpha;/&epsilon;&rho;&omega;&tau;ή&mu;&alpha;&tau;&alpha;
              &pi;&omicron;&upsilon;
              &upsilon;&pi;&omicron;&beta;ά&lambda;&lambda;&epsilon;&tau;&epsilon;,
              &sigma;&tau;&alpha; &alpha;&iota;&tau;ή&mu;&alpha;&tau;&alpha;
              &epsilon;&pi;&iota;&sigma;&tau;&rho;&omicron;&phi;ή&sigmaf;
              &chi;&rho;&eta;&mu;ά&tau;&omega;&nu; ή/&kappa;&alpha;&iota;
              &sigma;&tau;&alpha; &tau;&upsilon;&chi;ό&nu;
              &pi;&alpha;&rho;ά&pi;&omicron;&nu;&alpha;. &Omicron;&iota;
              &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
              &pi;&omicron;&upsilon;
              &mu;&omicron;&iota;&rho;ά&zeta;&epsilon;&sigma;&tau;&epsilon;
              &mu;&alpha;&zeta;ί &mu;&alpha;&sigmaf;, &mu;&alpha;&sigmaf;
              &delta;ί&nu;&omicron;&upsilon;&nu; &tau;&eta;
              &delta;&upsilon;&nu;&alpha;&tau;ό&tau;&eta;&tau;&alpha;
              &nu;&alpha;
              &delta;&iota;&alpha;&chi;&epsilon;&iota;&rho;&iota;&sigma;&tau;&omicron;ύ&mu;&epsilon;
              &tau;&alpha; &alpha;&iota;&tau;ή&mu;&alpha;&tau;ά
              &sigma;&alpha;&sigmaf; &kappa;&alpha;&iota; &nu;&alpha;
              &sigma;&alpha;&sigmaf;
              &alpha;&pi;&alpha;&nu;&tau;ή&sigma;&omicron;&upsilon;&mu;&epsilon;
              &mu;&epsilon; &tau;&omicron;&nu;
              &kappa;&alpha;&lambda;ύ&tau;&epsilon;&rho;&omicron;
              &delta;&upsilon;&nu;&alpha;&tau;ό &tau;&rho;ό&pi;&omicron;.
              &Mu;&pi;&omicron;&rho;&omicron;ύ&mu;&epsilon;
              &epsilon;&pi;ί&sigma;&eta;&sigmaf; &nu;&alpha;
              &delta;&iota;&alpha;&tau;&eta;&rho;ή&sigma;&omicron;&upsilon;&mu;&epsilon;
              έ&nu;&alpha; &alpha;&rho;&chi;&epsilon;ί&omicron; &tau;&omega;&nu;
              &epsilon;&rho;&omega;&tau;&eta;&mu;ά&tau;&omega;&nu;/&alpha;&iota;&tau;&eta;&mu;ά&tau;&omega;&nu;
              &sigma;&alpha;&sigmaf; &pi;&rho;&omicron;&sigmaf;
              &epsilon;&mu;ά&sigmaf; ώ&sigma;&tau;&epsilon; &nu;&alpha;
              &alpha;&nu;&tau;&alpha;&pi;&omicron;&kappa;&rho;&iota;&nu;ό&mu;&alpha;&sigma;&tau;&epsilon;
              &kappa;&alpha;&lambda;ύ&tau;&epsilon;&rho;&alpha; &sigma;&epsilon;
              &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
              &mu;&epsilon;&lambda;&lambda;&omicron;&nu;&tau;&iota;&kappa;ή
              &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ί&alpha;.
              &Tau;&omicron; &kappa;ά&nu;&omicron;&upsilon;&mu;&epsilon;
              &alpha;&upsilon;&tau;ό &mu;&epsilon; &beta;ά&sigma;&eta;
              &tau;&iota;&sigmaf;
              &sigma;&upsilon;&mu;&beta;&alpha;&tau;&iota;&kappa;έ&sigmaf;
              &mu;&alpha;&sigmaf;
              &upsilon;&pi;&omicron;&chi;&rho;&epsilon;ώ&sigma;&epsilon;&iota;&sigmaf;
              &pi;&rho;&omicron;&sigmaf; &epsilon;&sigma;ά&sigmaf;,
              &tau;&iota;&sigmaf; &nu;&omicron;&mu;&iota;&kappa;έ&sigmaf;
              &mu;&alpha;&sigmaf;
              &upsilon;&pi;&omicron;&chi;&rho;&epsilon;ώ&sigma;&epsilon;&iota;&sigmaf;
              &alpha;&lambda;&lambda;ά &kappa;&alpha;&iota; &tau;&alpha;
              &nu;ό&mu;&iota;&mu;&alpha;
              &sigma;&upsilon;&mu;&phi;έ&rho;&omicron;&nu;&tau;ά
              &mu;&alpha;&sigmaf; ώ&sigma;&tau;&epsilon; &nu;&alpha;
              &sigma;&alpha;&sigmaf;
              &pi;&alpha;&rho;έ&chi;&omicron;&upsilon;&mu;&epsilon;
              &tau;&eta;&nu; &kappa;&alpha;&lambda;ύ&tau;&epsilon;&rho;&eta;
              &delta;&upsilon;&nu;&alpha;&tau;ή
              &epsilon;&xi;&upsilon;&pi;&eta;&rho;έ&tau;&eta;&sigma;&eta;
              &kappa;&alpha;&iota; &nu;&alpha;
              &epsilon;ί&mu;&alpha;&sigma;&tau;&epsilon; &sigma;&epsilon;
              &theta;έ&sigma;&eta; &nu;&alpha;
              &beta;&epsilon;&lambda;&tau;&iota;ώ&sigma;&omicron;&upsilon;&mu;&epsilon;
              &tau;&iota;&sigmaf;
              &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
              &mu;&alpha;&sigmaf; &mu;&epsilon; &beta;ά&sigma;&eta; &tau;&eta;
              &delta;&iota;&kappa;ή &sigma;&alpha;&sigmaf;
              &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ή
              &epsilon;&mu;&pi;&epsilon;&iota;&rho;ί&alpha;.</span
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&Gamma;&iota;&alpha; &tau;&eta;&nu;
              &Alpha;&pi;&omicron;&sigma;&tau;&omicron;&lambda;ή
              &epsilon;&nu;&eta;&mu;&epsilon;&rho;&omega;&tau;&iota;&kappa;&omicron;ύ
              &delta;&epsilon;&lambda;&tau;ί&omicron;&upsilon;
              (newsletter)/&pi;&rho;&omicron;&sigma;&phi;&omicron;&rho;ώ&nu;:
              &Mu;&epsilon; &tau;&eta;
              &sigma;&upsilon;&gamma;&kappa;&alpha;&tau;ά&theta;&epsilon;&sigma;ή
              &sigma;&alpha;&sigmaf;, &theta;&alpha;
              &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;ή&sigma;&omicron;&upsilon;&mu;&epsilon;
              &tau;&alpha; &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
              &sigma;&alpha;&sigmaf;
              &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;,
              &tau;&iota;&sigmaf;
              &pi;&rho;&omicron;&tau;&iota;&mu;ή&sigma;&epsilon;&iota;&sigmaf;
              &kappa;&alpha;&iota; &tau;&alpha;
              &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha; &tau;&omega;&nu;
              &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;ώ&nu;
              &sigma;&alpha;&sigmaf; &gamma;&iota;&alpha; &nu;&alpha;
              &sigma;&alpha;&sigmaf;
              &epsilon;&nu;&eta;&mu;&epsilon;&rho;ώ&nu;&omicron;&upsilon;&mu;&epsilon;
              &mu;έ&sigma;&omega; e-mail,
              &delta;&iota;&alpha;&delta;&iota;&kappa;&tau;ύ&omicron;&upsilon;,
              &tau;&eta;&lambda;&epsilon;&phi;ώ&nu;&omicron;&upsilon;
              ή/&kappa;&alpha;&iota; &mu;έ&sigma;&omega; &mu;έ&sigma;&omega;&nu;
              &kappa;&omicron;&iota;&nu;&omega;&nu;&iota;&kappa;ή&sigmaf;
              &delta;&iota;&kappa;&tau;ύ&omega;&sigma;&eta;&sigmaf;
              &gamma;&iota;&alpha; &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά
              &pi;&rho;&omicron;ϊό&nu;&tau;&alpha; &kappa;&alpha;&iota;
              &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;,
              &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;&alpha;&nu;&omicron;&mu;έ&nu;&omega;&nu;
              &epsilon;&xi;&alpha;&tau;&omicron;&mu;&iota;&kappa;&epsilon;&upsilon;&mu;έ&nu;&omega;&nu;
              /
              &pi;&rho;&omicron;&sigma;&omega;&pi;&omicron;&pi;&omicron;&iota;&eta;&mu;έ&nu;&omega;&nu;
              &pi;&rho;&omicron;&sigma;&phi;&omicron;&rho;ώ&nu;
              &kappa;.&lambda;&pi;. &Phi;&upsilon;&sigma;&iota;&kappa;ά
              έ&chi;&epsilon;&tau;&epsilon; &tau;&eta;
              &delta;&upsilon;&nu;&alpha;&tau;ό&tau;&eta;&tau;&alpha;
              &nu;&alpha;
              &alpha;&nu;&alpha;&kappa;&alpha;&lambda;έ&sigma;&epsilon;&tau;&epsilon;
              &tau;&eta;
              &sigma;&upsilon;&gamma;&kappa;&alpha;&tau;ά&theta;&epsilon;&sigma;&eta;
              &alpha;&upsilon;&tau;ή
              &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
              &sigma;&tau;&iota;&gamma;&mu;ή.</span
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&Gamma;&iota;&alpha; Web push notifications:
              &Alpha;&nu;ά&lambda;&omicron;&gamma;&alpha; &mu;&epsilon;
              &tau;&eta;&nu; &pi;&lambda;&omicron;ή&gamma;&eta;&sigma;ή
              &sigma;&alpha;&sigmaf;,
              &mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon; &nu;&alpha;
              &lambda;&alpha;&mu;&beta;ά&nu;&epsilon;&tau;&epsilon;,
              έ&chi;&omicron;&nu;&tau;&alpha;&sigmaf;
              &pi;&rho;o&eta;&gamma;&omicron;&upsilon;&mu;έ&nu;&omega;&sigmaf;
              &delta;ώ&sigma;&epsilon;&iota; &tau;&eta;
              &sigma;&upsilon;&gamma;&kappa;&alpha;&tau;ά&theta;&epsilon;&sigma;ή
              &sigma;&alpha;&sigmaf;,
              &epsilon;&iota;&delta;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&iota;&sigmaf;
              &gamma;&iota;&alpha; &tau;&iota;&sigmaf;
              &pi;&rho;&omicron;&sigma;&phi;&omicron;&rho;έ&sigmaf;
              &mu;&alpha;&sigmaf;, &tau;&alpha; &nu;έ&alpha;, &tau;&eta; wish
              list &sigma;&alpha;&sigmaf; &kappa;&alpha;&iota; &tau;&omicron;
              &kappa;&alpha;&lambda;ά&theta;&iota;
              &alpha;&gamma;&omicron;&rho;ώ&nu; &sigma;&alpha;&sigmaf;.
              &Phi;&upsilon;&sigma;&iota;&kappa;ά έ&chi;&epsilon;&tau;&epsilon;
              &tau;&eta; &delta;&upsilon;&nu;&alpha;&tau;ό&tau;&eta;&tau;&alpha;
              &nu;&alpha;
              &alpha;&nu;&alpha;&kappa;&alpha;&lambda;έ&sigma;&epsilon;&tau;&epsilon;
              &tau;&eta;
              &sigma;&upsilon;&gamma;&kappa;&alpha;&tau;ά&theta;&epsilon;&sigma;&eta;
              &alpha;&upsilon;&tau;ή
              &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
              &sigma;&tau;&iota;&gamma;&mu;ή.</span
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&Gamma;&iota;&alpha;
              &Sigma;&upsilon;&mu;&mu;&epsilon;&tau;&omicron;&chi;ή
              &sigma;&epsilon; &pi;&rho;ό&gamma;&rho;&alpha;&mu;&mu;&alpha;
              &epsilon;&pi;&iota;&beta;&rho;ά&beta;&epsilon;&upsilon;&sigma;&eta;&sigmaf;:
              &Eta; &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
              &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
              &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;ά&zeta;&epsilon;&tau;&alpha;&iota;
              &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
              &sigma;&alpha;&sigmaf; &gamma;&iota;&alpha; &tau;&iota;&sigmaf;
              &alpha;&nu;ά&gamma;&kappa;&epsilon;&sigmaf; &tau;&eta;&sigmaf;
              &sigma;&upsilon;&mu;&mu;&epsilon;&tau;&omicron;&chi;ή&sigmaf;
              &sigma;&alpha;&sigmaf; &sigma;&epsilon;
              &pi;&rho;ό&gamma;&rho;&alpha;&mu;&mu;&alpha;
              &epsilon;&pi;&iota;&beta;&rho;ά&beta;&epsilon;&upsilon;&sigma;&eta;&sigmaf;,
              &delta;&eta;&lambda;&alpha;&delta;ή &tau;ό&sigma;&omicron;
              &tau;&eta;&sigmaf; &epsilon;&xi;έ&tau;&alpha;&sigma;&eta;&sigmaf;
              &tau;&eta;&sigmaf; &alpha;ί&tau;&eta;&sigma;&eta;&sigmaf;
              &sigma;&upsilon;&mu;&mu;&epsilon;&tau;&omicron;&chi;ή&sigmaf;
              &sigma;&alpha;&sigmaf;, ό&sigma;&omicron; &kappa;&alpha;&iota;
              &tau;&eta;&sigmaf;
              &sigma;&upsilon;&gamma;&kappa;έ&nu;&tau;&rho;&omega;&sigma;&eta;&sigmaf;
              &kappa;&alpha;&iota;
              &epsilon;&xi;&alpha;&rho;&gamma;ύ&rho;&omega;&sigma;&eta;&sigmaf;
              &pi;ό&nu;&tau;&omega;&nu; &kappa;&alpha;&iota;
              &gamma;&epsilon;&nu;&iota;&kappa;ό&tau;&epsilon;&rho;&alpha;
              &tau;&eta;&sigmaf;
              &alpha;&pi;ό&lambda;&alpha;&upsilon;&sigma;&eta;&sigmaf;
              &pi;&rho;&omicron;&nu;&omicron;&mu;ί&omega;&nu;
              &pi;&epsilon;&lambda;ά&tau;&eta;, ό&pi;&omega;&sigmaf;
              &alpha;&upsilon;&tau;ά &theta;&alpha;
              &alpha;&nu;&alpha;&lambda;ύ&omicron;&nu;&tau;&alpha;&iota;
              &sigma;&tau;&omicron;&upsilon;&sigmaf;
              ό&rho;&omicron;&upsilon;&sigmaf;
              &sigma;&upsilon;&mu;&mu;&epsilon;&tau;&omicron;&chi;ή&sigmaf;
              &tau;&omicron;&upsilon;
              &pi;&rho;&omicron;&gamma;&rho;ά&mu;&mu;&alpha;&tau;&omicron;&sigmaf;
              &epsilon;&pi;&iota;&beta;&rho;ά&beta;&epsilon;&upsilon;&sigma;&eta;&sigmaf;.
              Έ&tau;&sigma;&iota; &epsilon;ί&mu;&alpha;&sigma;&tau;&epsilon;
              &sigma;&epsilon; &theta;έ&sigma;&eta; &nu;&alpha;
              &sigma;&alpha;&sigmaf;
              &pi;&rho;&omicron;&sigma;&phi;έ&rho;&omicron;&upsilon;&mu;&epsilon;
              &epsilon;&xi;&alpha;&tau;&omicron;&mu;&iota;&kappa;&epsilon;&upsilon;&mu;έ&nu;&epsilon;&sigmaf;
              &pi;&rho;&omicron;&sigma;&phi;&omicron;&rho;έ&sigmaf;
              &pi;&omicron;&upsilon; &sigma;&alpha;&sigmaf;
              &epsilon;&nu;&delta;&iota;&alpha;&phi;έ&rho;&omicron;&upsilon;&nu;.
              &Phi;&upsilon;&sigma;&iota;&kappa;ά,
              &epsilon;ί&sigma;&tau;&epsilon;
              &epsilon;&lambda;&epsilon;ύ&theta;&epsilon;&rho;&omicron;&iota;
              &nu;&alpha;
              &epsilon;&pi;&iota;&lambda;έ&xi;&epsilon;&tau;&epsilon;
              &alpha;&nu; &theta;&alpha;
              &epsilon;&pi;&omega;&phi;&epsilon;&lambda;&eta;&theta;&epsilon;ί&tau;&epsilon;
              &alpha;&pi;ό &alpha;&upsilon;&tau;έ&sigmaf;.</span
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&Gamma;&iota;&alpha; &tau;&eta;&nu;
              &Alpha;&nu;ά&pi;&tau;&upsilon;&xi;&eta; &kappa;&alpha;&iota;
              &beta;&epsilon;&lambda;&tau;ί&omega;&sigma;&eta; &tau;&omega;&nu;
              &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu; &kappa;&alpha;&iota;
              &tau;&omega;&nu;
              &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
              &pi;&omicron;&upsilon; &sigma;&alpha;&sigmaf;
              &pi;&alpha;&rho;έ&chi;&omicron;&upsilon;&mu;&epsilon;.
              &Tau;&omicron; &kappa;ά&nu;&omicron;&upsilon;&mu;&epsilon;
              &alpha;&upsilon;&tau;ό &mu;&epsilon; &beta;ά&sigma;&eta;
              &tau;&alpha; &nu;ό&mu;&iota;&mu;&alpha;
              &epsilon;&pi;&iota;&chi;&epsilon;&iota;&rho;&eta;&mu;&alpha;&tau;&iota;&kappa;ά
              &mu;&alpha;&sigmaf;
              &sigma;&upsilon;&mu;&phi;έ&rho;&omicron;&nu;&tau;&alpha;.</span
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&Gamma;&iota;&alpha;&tau;ί
              &theta;έ&lambda;&omicron;&upsilon;&mu;&epsilon; &nu;&alpha;
              &sigma;&alpha;&sigmaf;
              &pi;&rho;&omicron;&sigma;&phi;έ&rho;&omicron;&upsilon;&mu;&epsilon;
              &pi;&rho;&omicron;&sigma;&phi;&omicron;&rho;έ&sigmaf;
              &kappa;&alpha;&iota;
              &pi;&rho;&omicron;&tau;ά&sigma;&epsilon;&iota;&sigmaf;
              &pi;&omicron;&upsilon; &epsilon;ί&nu;&alpha;&iota;
              &pi;&iota;&omicron;
              &sigma;&chi;&epsilon;&tau;&iota;&kappa;έ&sigmaf; &mu;&epsilon;
              &tau;&alpha;
              &epsilon;&nu;&delta;&iota;&alpha;&phi;έ&rho;&omicron;&nu;&tau;&alpha;
              &kappa;&alpha;&iota; &tau;&iota;&sigmaf;
              &alpha;&nu;ά&gamma;&kappa;&epsilon;&sigmaf;
              &sigma;&alpha;&sigmaf;.</span
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&Gamma;&iota;&alpha; &nu;&alpha;
              &epsilon;&xi;&alpha;&sigma;&phi;&alpha;&lambda;ί&sigma;&omicron;&upsilon;&mu;&epsilon;
              ό&tau;&iota; &theta;&alpha; &sigma;&alpha;&sigmaf;
              &epsilon;&mu;&phi;&alpha;&nu;ί&zeta;&epsilon;&tau;&alpha;&iota;
              &pi;ά&nu;&tau;&alpha; &tau;&omicron; &pi;&iota;&omicron;
              &epsilon;&nu;&delta;&iota;&alpha;&phi;έ&rho;&omicron;&nu;
              &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
              &sigma;&tau;&omicron;&upsilon;&sigmaf;
              &Delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;&omicron;ύ&sigmaf;
              &Tau;ό&pi;&omicron;&upsilon;&sigmaf; ή &sigma;&tau;&alpha; Apps
              &mu;&alpha;&sigmaf;, &theta;&alpha;
              &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;ή&sigma;&omicron;&upsilon;&mu;&epsilon;
              &tau;&alpha; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
              &pi;&omicron;&upsilon; &mu;&alpha;&sigmaf;
              έ&chi;&epsilon;&tau;&epsilon;
              &pi;&alpha;&rho;ά&sigma;&chi;&epsilon;&iota;
              &delta;ί&nu;&omicron;&nu;&tau;ά&sigmaf; &mu;&alpha;&sigmaf;
              &tau;&eta;
              &sigma;&upsilon;&gamma;&kappa;&alpha;&tau;ά&theta;&epsilon;&sigma;ή
              &sigma;&alpha;&sigmaf; &nu;&alpha;
              &lambda;&alpha;&mu;&beta;ά&nu;&epsilon;&tau;&epsilon;
              &epsilon;&iota;&delta;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&iota;&sigmaf;
              Apps ή - &gamma;&iota;&alpha; &tau;&omicron;&upsilon;&sigmaf;
              &Delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;&omicron;ύ&sigmaf;
              &mu;&alpha;&sigmaf; &Tau;ό&pi;&omicron;&upsilon;&sigmaf; &ndash;
              &tau;&eta;
              &sigma;&upsilon;&gamma;&kappa;&alpha;&tau;ά&theta;&epsilon;&sigma;ή
              &sigma;&alpha;&sigmaf; &gamma;&iota;&alpha; &tau;&eta;&nu;
              &tau;&omicron;&pi;&omicron;&theta;έ&tau;&eta;&sigma;&eta; cookies
              &sigma;&tau;&eta;
              &sigma;&upsilon;&sigma;&kappa;&epsilon;&upsilon;ή
              &sigma;&alpha;&sigmaf;. &Gamma;&iota;&alpha;
              &pi;&alpha;&rho;ά&delta;&epsilon;&iota;&gamma;&mu;&alpha;,
              &epsilon;&nu;&delta;έ&chi;&epsilon;&tau;&alpha;&iota; &nu;&alpha;
              &epsilon;&mu;&phi;&alpha;&nu;ί&sigma;&omicron;&upsilon;&mu;&epsilon;
              &mu;&iota;&alpha; &lambda;ί&sigma;&tau;&alpha; &mu;&epsilon;
              &pi;&rho;&omicron;ϊό&nu;&tau;&alpha; &pi;&omicron;&upsilon;
              &kappa;&omicron;&iota;&tau;ά&xi;&alpha;&tau;&epsilon;
              &pi;&rho;ό&sigma;&phi;&alpha;&tau;&alpha; ή &nu;&alpha;
              &sigma;&alpha;&sigmaf;
              &pi;&rho;&omicron;&sigma;&phi;έ&rho;&omicron;&upsilon;&mu;&epsilon;
              &sigma;&upsilon;&sigma;&tau;ά&sigma;&epsilon;&iota;&sigmaf;
              &mu;&epsilon; &beta;ά&sigma;&eta; &tau;&omicron;
              &iota;&sigma;&tau;&omicron;&rho;&iota;&kappa;ό
              &alpha;&gamma;&omicron;&rho;ώ&nu; &sigma;&alpha;&sigmaf;
              &kappa;&alpha;&iota;
              &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
              ά&lambda;&lambda;&alpha;
              &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
              έ&chi;&epsilon;&tau;&epsilon;
              &mu;&omicron;&iota;&rho;&alpha;&sigma;&tau;&epsilon;ί
              &mu;&alpha;&zeta;ί &mu;&alpha;&sigmaf;.</span
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&Gamma;&iota;&alpha; &nu;&alpha; &sigma;&alpha;&sigmaf;
              &sigma;&tau;&epsilon;ί&lambda;&omicron;&upsilon;&mu;&epsilon;
              &alpha;&iota;&tau;ή&mu;&alpha;&tau;&alpha;
              έ&rho;&epsilon;&upsilon;&nu;&alpha;&sigmaf; &kappa;&alpha;&iota;
              &alpha;&xi;&iota;&omicron;&lambda;ό&gamma;&eta;&sigma;&eta;&sigmaf;
              &pi;&rho;&omicron;&kappa;&epsilon;&iota;&mu;έ&nu;&omicron;&upsilon;
              &nu;&alpha; &mu;&pi;&omicron;&rho;&omicron;ύ&mu;&epsilon;
              &nu;&alpha;
              &beta;&epsilon;&lambda;&tau;&iota;ώ&sigma;&omicron;&upsilon;&mu;&epsilon;
              &tau;&iota;&sigmaf;
              &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
              &mu;&alpha;&sigmaf;. &Alpha;&upsilon;&tau;ά &tau;&alpha;
              &mu;&eta;&nu;ύ&mu;&alpha;&tau;&alpha; &delta;&epsilon;&nu;
              &theta;&alpha;
              &pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;ά&nu;&omicron;&upsilon;&nu;
              &delta;&iota;&alpha;&phi;&eta;&mu;&iota;&sigma;&tau;&iota;&kappa;ό
              &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
              &kappa;&alpha;&iota; &delta;&epsilon;&nu;
              &alpha;&pi;&alpha;&iota;&tau;&omicron;ύ&nu;
              &pi;&rho;&omicron;&eta;&gamma;&omicron;ύ&mu;&epsilon;&nu;&eta;
              &sigma;&upsilon;&gamma;&kappa;&alpha;&tau;ά&theta;&epsilon;&sigma;&eta;
              ό&tau;&alpha;&nu;
              &alpha;&pi;&omicron;&sigma;&tau;έ&lambda;&lambda;&omicron;&nu;&tau;&alpha;&iota;
              &mu;&epsilon; &mu;ή&nu;&upsilon;&mu;&alpha;
              &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;&omicron;ύ
              &tau;&alpha;&chi;&upsilon;&delta;&rho;&omicron;&mu;&epsilon;ί&omicron;&upsilon;
              ή &mu;&epsilon; &mu;ή&nu;&upsilon;&mu;&alpha;
              &kappa;&epsilon;&iota;&mu;έ&nu;&omicron;&upsilon; (SMS).
              Έ&chi;&omicron;&upsilon;&mu;&epsilon; &nu;ό&mu;&iota;&mu;&omicron;
              &sigma;&upsilon;&mu;&phi;έ&rho;&omicron;&nu; &nu;&alpha;
              &tau;&omicron; &pi;&rho;ά&xi;&omicron;&upsilon;&mu;&epsilon;,
              &kappa;&alpha;&theta;ώ&sigmaf; &alpha;&upsilon;&tau;ό
              &beta;&omicron;&eta;&theta;ά &tau;&alpha;
              &pi;&rho;&omicron;ϊό&nu;&tau;&alpha; ή &tau;&iota;&sigmaf;
              &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
              &mu;&alpha;&sigmaf; &nu;&alpha; &epsilon;ί&nu;&alpha;&iota;
              &pi;&iota;&omicron; &sigma;&upsilon;&nu;&alpha;&phi;ή
              &mu;&epsilon; &epsilon;&sigma;ά&sigmaf;.
              &Phi;&upsilon;&sigma;&iota;&kappa;ά,
              &epsilon;ί&sigma;&tau;&epsilon;
              &epsilon;&lambda;&epsilon;ύ&theta;&epsilon;&rho;&omicron;&iota;
              &nu;&alpha; &alpha;&rho;&nu;&eta;&theta;&epsilon;ί&tau;&epsilon;
              &nu;&alpha; &lambda;ά&beta;&epsilon;&tau;&epsilon;
              &alpha;&upsilon;&tau;ά &tau;&alpha;
              &alpha;&iota;&tau;ή&mu;&alpha;&tau;&alpha; &alpha;&pi;ό
              &epsilon;&mu;ά&sigmaf; &alpha;&nu;ά &pi;ά&sigma;&alpha;
              &sigma;&tau;&iota;&gamma;&mu;ή
              &epsilon;&nu;&eta;&mu;&epsilon;&rho;ώ&nu;&omicron;&nu;&tau;&alpha;&sigmaf;
              &tau;&iota;&sigmaf;
              &pi;&rho;&omicron;&tau;&iota;&mu;ή&sigma;&epsilon;&iota;&sigmaf;
              &sigma;&alpha;&sigmaf; &sigma;&tau;&omicron;&nu;
              &lambda;&omicron;&gamma;&alpha;&rho;&iota;&alpha;&sigma;&mu;ό
              &sigma;&alpha;&sigmaf; &sigma;&tau;&omicron;
              &delta;&iota;&alpha;&delta;ί&kappa;&tau;&upsilon;&omicron;.</span
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&Gamma;&iota;&alpha; &tau;&eta;&nu;
              &pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;
              &tau;&omicron;&upsilon;
              &lambda;&omicron;&gamma;&alpha;&rho;&iota;&alpha;&sigma;&mu;&omicron;ύ
              &sigma;&alpha;&sigmaf; &alpha;&pi;ό
              &alpha;&pi;ά&tau;&epsilon;&sigmaf; &kappa;&alpha;&iota;
              ά&lambda;&lambda;&epsilon;&sigmaf;
              &pi;&alpha;&rho;ά&nu;&omicron;&mu;&epsilon;&sigmaf;
              &delta;&rho;&alpha;&sigma;&tau;&eta;&rho;&iota;ό&tau;&eta;&tau;&epsilon;&sigmaf;:
              &Alpha;&upsilon;&tau;ό
              &pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;ά&nu;&epsilon;&iota;
              &tau;&eta; &chi;&rho;ή&sigma;&eta; &tau;&omega;&nu;
              &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
              &sigma;&alpha;&sigmaf; &gamma;&iota;&alpha; &tau;&eta;
              &sigma;&upsilon;&nu;&tau;ή&rho;&eta;&sigma;&eta;,
              &epsilon;&nu;&eta;&mu;έ&rho;&omega;&sigma;&eta;
              &kappa;&alpha;&iota;
              &pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;
              &tau;&omicron;&upsilon;
              &lambda;&omicron;&gamma;&alpha;&rho;&iota;&alpha;&sigma;&mu;&omicron;ύ
              &sigma;&alpha;&sigmaf;.
              &Pi;&alpha;&rho;&alpha;&kappa;&omicron;&lambda;&omicron;&upsilon;&theta;&omicron;ύ&mu;&epsilon;
              &epsilon;&pi;ί&sigma;&eta;&sigmaf; &tau;&eta;
              &delta;&rho;&alpha;&sigma;&tau;&eta;&rho;&iota;ό&tau;&eta;&tau;&alpha;
              &pi;&epsilon;&rho;&iota;ή&gamma;&eta;&sigma;&eta;&sigmaf;
              &mu;&alpha;&zeta;ί &mu;&alpha;&sigmaf; &gamma;&iota;&alpha;
              &nu;&alpha;
              &epsilon;&nu;&tau;&omicron;&pi;ί&sigma;&omicron;&upsilon;&mu;&epsilon;
              &kappa;&alpha;&iota; &nu;&alpha;
              &epsilon;&pi;&iota;&lambda;ύ&sigma;&omicron;&upsilon;&mu;&epsilon;
              &gamma;&rho;ή&gamma;&omicron;&rho;&alpha; &tau;&upsilon;&chi;ό&nu;
              &pi;&rho;&omicron;&beta;&lambda;ή&mu;&alpha;&tau;&alpha;
              &kappa;&alpha;&iota; &nu;&alpha;
              &pi;&rho;&omicron;&sigma;&tau;&alpha;&tau;έ&psi;&omicron;&upsilon;&mu;&epsilon;
              &tau;&eta;&nu;
              &alpha;&kappa;&epsilon;&rho;&alpha;&iota;ό&tau;&eta;&tau;&alpha;
              &tau;&eta;&sigmaf;
              &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
              &mu;&alpha;&sigmaf;. Ό&lambda;&alpha; &tau;&alpha;
              &pi;&alpha;&rho;&alpha;&pi;ά&nu;&omega;
              &alpha;&pi;&omicron;&tau;&epsilon;&lambda;&omicron;ύ&nu;
              &mu;έ&rho;&omicron;&sigmaf; &tau;&omicron;&upsilon;
              &nu;ό&mu;&iota;&mu;&omicron;&upsilon;
              &sigma;&upsilon;&mu;&phi;έ&rho;&omicron;&nu;&tau;ό&sigmaf;
              &mu;&alpha;&sigmaf;. &Gamma;&iota;&alpha;
              &pi;&alpha;&rho;ά&delta;&epsilon;&iota;&gamma;&mu;&alpha;,
              &epsilon;&lambda;έ&gamma;&chi;&omicron;&upsilon;&mu;&epsilon;
              &tau;&omicron;&nu; &kappa;&omega;&delta;&iota;&kappa;ό
              &pi;&rho;ό&sigma;&beta;&alpha;&sigma;ή&sigmaf;
              &sigma;&alpha;&sigmaf; ό&tau;&alpha;&nu;
              &sigma;&upsilon;&nu;&delta;έ&epsilon;&sigma;&tau;&epsilon;
              &kappa;&alpha;&iota;
              &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&mu;&epsilon;
              &alpha;&upsilon;&tau;&omicron;&mu;&alpha;&tau;&omicron;&pi;&omicron;&iota;&eta;&mu;έ&nu;&eta;
              &pi;&alpha;&rho;&alpha;&kappa;&omicron;&lambda;&omicron;ύ&theta;&eta;&sigma;&eta;
              &delta;&iota;&epsilon;&upsilon;&theta;ύ&nu;&sigma;&epsilon;&omega;&nu;
              IP &gamma;&iota;&alpha; &nu;&alpha;
              &epsilon;&nu;&tau;&omicron;&pi;ί&sigma;&omicron;&upsilon;&mu;&epsilon;
              &pi;&iota;&theta;&alpha;&nu;έ&sigmaf;
              &psi;&epsilon;&upsilon;&delta;&epsilon;ί&sigmaf;
              &epsilon;&iota;&sigma;ό&delta;&omicron;&upsilon;&sigmaf;
              &alpha;&pi;ό
              &alpha;&pi;&rho;&omicron;&sigma;&delta;ό&kappa;&eta;&tau;&epsilon;&sigmaf;
              &tau;&omicron;&pi;&omicron;&theta;&epsilon;&sigma;ί&epsilon;&sigmaf;.</span
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&Gamma;&iota;&alpha; &tau;&eta;&nu;
              &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
              &tau;&omega;&nu; &pi;&lambda;&eta;&rho;&omega;&mu;ώ&nu;
              &kappa;&alpha;&iota; &tau;&eta;&nu;
              &alpha;&pi;&omicron;&tau;&rho;&omicron;&pi;ή
              &delta;ό&lambda;&iota;&omega;&nu;
              &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;ώ&nu;:
              &Tau;&omicron; &kappa;ά&nu;&omicron;&upsilon;&mu;&epsilon;
              &alpha;&upsilon;&tau;ό &mu;&epsilon; &beta;ά&sigma;&eta;
              &tau;&alpha; &nu;ό&mu;&iota;&mu;&alpha;
              &epsilon;&pi;&iota;&chi;&epsilon;&iota;&rho;&eta;&mu;&alpha;&tau;&iota;&kappa;ά
              &mu;&alpha;&sigmaf;
              &sigma;&upsilon;&mu;&phi;έ&rho;&omicron;&nu;&tau;&alpha;.
              &Alpha;&upsilon;&tau;ό &beta;&omicron;&eta;&theta;ά
              &epsilon;&pi;ί&sigma;&eta;&sigmaf; &sigma;&tau;&eta;&nu;
              &pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;
              &tau;&omega;&nu; &pi;&epsilon;&lambda;&alpha;&tau;ώ&nu;
              &mu;&alpha;&sigmaf; &alpha;&pi;ό
              &alpha;&pi;ά&tau;&epsilon;&sigmaf;.</span
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&Gamma;&iota;&alpha; &nu;&alpha;
              &sigma;&upsilon;&mu;&mu;&omicron;&rho;&phi;&omega;&theta;&omicron;ύ&mu;&epsilon;
              &mu;&epsilon; &tau;&iota;&sigmaf;
              &sigma;&upsilon;&mu;&beta;&alpha;&tau;&iota;&kappa;έ&sigmaf;
              &mu;&alpha;&sigmaf; &pi;&rho;&omicron;&sigmaf;
              &epsilon;&sigma;ά&sigmaf;
              &upsilon;&pi;&omicron;&chi;&rho;&epsilon;ώ&sigma;&epsilon;&iota;&sigmaf;
              ή &kappa;&alpha;&tau;&rsquo;
              &epsilon;&phi;&alpha;&rho;&mu;&omicron;&gamma;ή
              &delta;&iota;&alpha;&tau;ά&xi;&epsilon;&omega;&nu;
              &nu;ό&mu;&omicron;&upsilon; ή &sigma;&epsilon;
              &epsilon;&kappa;&tau;έ&lambda;&epsilon;&sigma;&eta;
              &delta;&iota;&kappa;&alpha;&sigma;&tau;&iota;&kappa;ώ&nu;
              &alpha;&pi;&omicron;&phi;ά&sigma;&epsilon;&omega;&nu;.</span
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&Gamma;&iota;&alpha; &nu;&alpha; &sigma;&alpha;&sigmaf;
              &sigma;&tau;&epsilon;ί&lambda;&omicron;&upsilon;&mu;&epsilon;
              &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ί&epsilon;&sigmaf;
              &pi;&omicron;&upsilon;
              &alpha;&pi;&alpha;&iota;&tau;&omicron;ύ&nu;&tau;&alpha;&iota;
              &alpha;&pi;ό &tau;&omicron; &nu;ό&mu;&omicron; ή
              &pi;&omicron;&upsilon; &epsilon;ί&nu;&alpha;&iota;
              &alpha;&pi;&alpha;&rho;&alpha;ί&tau;&eta;&tau;&epsilon;&sigmaf;
              &gamma;&iota;&alpha; &nu;&alpha; &sigma;&alpha;&sigmaf;
              &epsilon;&nu;&eta;&mu;&epsilon;&rho;ώ&sigma;&omicron;&upsilon;&mu;&epsilon;
              &gamma;&iota;&alpha; &tau;&iota;&sigmaf;
              &alpha;&lambda;&lambda;&alpha;&gamma;έ&sigmaf;
              &sigma;&tau;&iota;&sigmaf;
              &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
              &pi;&omicron;&upsilon; &sigma;&alpha;&sigmaf;
              &pi;&alpha;&rho;έ&chi;&omicron;&upsilon;&mu;&epsilon;.
              &Gamma;&iota;&alpha;
              &pi;&alpha;&rho;ά&delta;&epsilon;&iota;&gamma;&mu;&alpha;,
              &epsilon;&nu;&eta;&mu;&epsilon;&rho;ώ&sigma;&epsilon;&iota;&sigmaf;
              &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά &mu;&epsilon;
              &alpha;&upsilon;&tau;έ&sigmaf; &tau;&iota;&sigmaf;
              &epsilon;&iota;&delta;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&iota;&sigmaf;
              &alpha;&pi;&omicron;&rho;&rho;ή&tau;&omicron;&upsilon;,
              &epsilon;&iota;&delta;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&iota;&sigmaf;
              &alpha;&nu;ά&kappa;&lambda;&eta;&sigma;&eta;&sigmaf;
              &pi;&rho;&omicron;ϊό&nu;&tau;&omicron;&sigmaf;
              &kappa;&alpha;&iota; &nu;&omicron;&mu;&iota;&kappa;ά
              &alpha;&pi;&alpha;&iota;&tau;&omicron;ύ&mu;&epsilon;&nu;&epsilon;&sigmaf;
              &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
              &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά &mu;&epsilon;
              &tau;&iota;&sigmaf;
              &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&epsilon;&sigmaf;
              &sigma;&alpha;&sigmaf;. &Alpha;&upsilon;&tau;ά &tau;&alpha;
              &mu;&eta;&nu;ύ&mu;&alpha;&tau;&alpha;
              &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;&sigmaf;
              &delta;&epsilon;&nu; &theta;&alpha;
              &pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;ά&nu;&omicron;&upsilon;&nu;
              &delta;&iota;&alpha;&phi;&eta;&mu;&iota;&sigma;&tau;&iota;&kappa;ό
              &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
              &kappa;&alpha;&iota; &delta;&epsilon;&nu;
              &alpha;&pi;&alpha;&iota;&tau;&omicron;ύ&nu;
              &pi;&rho;&omicron;&eta;&gamma;&omicron;ύ&mu;&epsilon;&nu;&eta;
              &sigma;&upsilon;&gamma;&kappa;&alpha;&tau;ά&theta;&epsilon;&sigma;&eta;
              ό&tau;&alpha;&nu;
              &alpha;&pi;&omicron;&sigma;&tau;έ&lambda;&lambda;&omicron;&nu;&tau;&alpha;&iota;
              &mu;&epsilon; &mu;ή&nu;&upsilon;&mu;&alpha;
              &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;&omicron;ύ
              &tau;&alpha;&chi;&upsilon;&delta;&rho;&omicron;&mu;&epsilon;ί&omicron;&upsilon;
              ή &mu;&epsilon; &mu;ή&nu;&upsilon;&mu;&alpha;
              &kappa;&epsilon;&iota;&mu;έ&nu;&omicron;&upsilon; (SMS).
              &Epsilon;ά&nu; &delta;&epsilon;&nu;
              &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;ή&sigma;&omicron;&upsilon;&mu;&epsilon;
              &tau;&alpha; &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
              &sigma;&alpha;&sigmaf;
              &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
              &gamma;&iota;&alpha; &tau;&omicron;&upsilon;&sigmaf;
              &sigma;&kappa;&omicron;&pi;&omicron;ύ&sigmaf;
              &alpha;&upsilon;&tau;&omicron;ύ&sigmaf;, &delta;&epsilon;&nu;
              &mu;&pi;&omicron;&rho;&omicron;ύ&mu;&epsilon; &nu;&alpha;
              &sigma;&upsilon;&mu;&mu;&omicron;&rho;&phi;&omega;&theta;&omicron;ύ&mu;&epsilon;
              &mu;&epsilon; &tau;&iota;&sigmaf;
              &nu;&omicron;&mu;&iota;&kappa;έ&sigmaf; &mu;&alpha;&sigmaf;
              &upsilon;&pi;&omicron;&chi;&rho;&epsilon;ώ&sigma;&epsilon;&iota;&sigmaf;.</span
            >
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Sigma;&alpha;&sigmaf;
          &gamma;&nu;&omega;&rho;ί&zeta;&omicron;&upsilon;&mu;&epsilon;
          &tau;έ&lambda;&omicron;&sigmaf; ό&tau;&iota; &eta;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
          &tau;&omega;&nu; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&alpha;&sigmaf;
          &delta;&iota;&epsilon;&xi;ά&gamma;&epsilon;&tau;&alpha;&iota;
          &epsilon;ί&tau;&epsilon; &alpha;&pi;ό &tau;&omicron;
          &epsilon;&iota;&delta;&iota;&kappa;ά
          &epsilon;&xi;&omicron;&upsilon;&sigma;&iota;&omicron;&delta;&omicron;&tau;&eta;&mu;έ&nu;&omicron;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ό &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;,
          &epsilon;ί&tau;&epsilon; &mu;έ&sigma;&omega;
          &sigma;&upsilon;&sigma;&tau;&eta;&mu;ά&tau;&omega;&nu;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;&iota;&kappa;ή&sigmaf;
          &kappa;&alpha;&iota;
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ώ&nu;
          &sigma;&upsilon;&sigma;&kappa;&epsilon;&upsilon;ώ&nu; &alpha;&pi;ό
          &tau;&eta;&nu; &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
          &kappa;&alpha;&iota; &kappa;&alpha;&tau;&rsquo;
          &epsilon;&xi;&alpha;ί&rho;&epsilon;&sigma;&eta; &alpha;&pi;ό
          &tau;&rho;ί&tau;&omicron;&upsilon;&sigmaf;, &omicron;&iota;
          &omicron;&pi;&omicron;ί&omicron;&iota;,
          έ&chi;&omicron;&nu;&tau;&alpha;&sigmaf;
          &delta;&epsilon;&sigma;&mu;&epsilon;&upsilon;&tau;&epsilon;ί
          &sigma;&upsilon;&mu;&beta;&alpha;&tau;&iota;&kappa;ά
          &gamma;&iota;&alpha; &tau;&eta;&nu; &tau;ή&rho;&eta;&sigma;&eta;
          &epsilon;&mu;&pi;&iota;&sigma;&tau;&epsilon;&upsilon;&tau;&iota;&kappa;ό&tau;&eta;&tau;&alpha;&sigmaf;
          &kappa;&alpha;&iota; &tau;&eta;&nu;
          &pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha; &tau;&omega;&nu;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&alpha;&sigmaf;
          &delta;&iota;&epsilon;&xi;ά&gamma;&omicron;&upsilon;&nu;
          &epsilon;&rho;&gamma;&alpha;&sigma;ί&epsilon;&sigmaf;
          &pi;&omicron;&upsilon; &epsilon;ί&nu;&alpha;&iota;
          &alpha;&pi;&alpha;&rho;&alpha;ί&tau;&eta;&tau;&epsilon;&sigmaf;
          &gamma;&iota;&alpha; &tau;&eta;&nu;
          &epsilon;&pi;ί&tau;&epsilon;&upsilon;&xi;&eta; &tau;&omega;&nu;
          &sigma;&kappa;&omicron;&pi;ώ&nu; &pi;&omicron;&upsilon;
          &sigma;&upsilon;&nu;&delta;έ&omicron;&nu;&tau;&alpha;&iota;
          &alpha;&upsilon;&sigma;&tau;&eta;&rho;ά &mu;&epsilon; &tau;&eta;
          &chi;&rho;ή&sigma;&eta; &tau;&omega;&nu;
          &Delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;ώ&nu;
          &mu;&alpha;&sigmaf; &Tau;ό&pi;&omega;&nu;, &tau;&iota;&sigmaf;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
          &tau;&omicron;&upsilon; &kappa;&alpha;&iota; &tau;&eta;&nu;
          &pi;ώ&lambda;&eta;&sigma;&eta;
          &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu; &mu;έ&sigma;&omega;
          &tau;&omega;&nu; &Delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;ώ&nu;
          &mu;&alpha;&sigmaf; &Tau;ό&pi;&omega;&nu;.
          &Pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά &theta;&alpha;
          &beta;&rho;&epsilon;ί&tau;&epsilon;
          &pi;&alpha;&rho;&alpha;&kappa;ά&tau;&omega;
          &sigma;&tau;&omicron;&upsilon;&sigmaf;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;&omicron;ύ&sigmaf;
          ό&rho;&omicron;&upsilon;&sigmaf;
          &laquo;&Pi;&omicron;&iota;&omicron;&iota; &epsilon;ί&nu;&alpha;&iota;
          &omicron;&iota;
          &alpha;&pi;&omicron;&delta;έ&kappa;&tau;&epsilon;&sigmaf;
          &tau;&omega;&nu; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&alpha;&sigmaf;; &Pi;ώ&sigmaf;
          &kappa;&omicron;&iota;&nu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;&tau;&alpha;&iota;
          &tau;&alpha; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &sigma;&alpha;&sigmaf;&raquo;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >6. &Pi;&omicron;&iota;&alpha; &epsilon;ί&nu;&alpha;&iota; &eta;
          &nu;ό&mu;&iota;&mu;&eta; &beta;ά&sigma;&eta;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;&sigmaf;
          &tau;&omega;&nu; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&alpha;&sigmaf; &alpha;&pi;ό &tau;&eta;&nu;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;;</span
        >
      </p>
      <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&eta; &nu;&omicron;&mu;&omicron;&theta;&epsilon;&sigma;ί&alpha;
              &gamma;&iota;&alpha; &tau;&eta;&nu;
              &pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;
              &tau;&omega;&nu;
              &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
              &pi;&omicron;&upsilon;
              &kappa;&alpha;&theta;&omicron;&rho;ί&zeta;&epsilon;&iota;
              &delta;&iota;ά&phi;&omicron;&rho;&omicron;&upsilon;&sigmaf;
              &lambda;ό&gamma;&omicron;&upsilon;&sigmaf; &gamma;&iota;&alpha;
              &tau;&omicron;&upsilon;&sigmaf;
              &omicron;&pi;&omicron;ί&omicron;&upsilon;&sigmaf;
              &mu;&iota;&alpha;
              &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
              &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
              &sigma;&upsilon;&lambda;&lambda;έ&xi;&epsilon;&iota;
              &kappa;&alpha;&iota; &nu;&alpha;
              &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;&tau;&epsilon;ί
              &tau;&alpha; &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
              &sigma;&alpha;&sigmaf;
              &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;,
              &mu;&epsilon;&tau;&alpha;&xi;ύ &tau;&omega;&nu;
              &omicron;&pi;&omicron;ί&omega;&nu; &kappa;&alpha;&iota;
              &tau;&omicron;&upsilon;&sigmaf; ό&rho;&omicron;&upsilon;&sigmaf;
              &tau;&eta;&sigmaf;
              &sigma;&upsilon;&mu;&beta;&alpha;&tau;&iota;&kappa;ή&sigmaf;
              &mu;&alpha;&sigmaf; &sigma;&chi;έ&sigma;&eta;&sigmaf;</span
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&eta;
              &sigma;&upsilon;&gamma;&kappa;&alpha;&tau;ά&theta;&epsilon;&sigma;&eta;
              &sigma;&alpha;&sigmaf;, ό&pi;&omicron;&upsilon;
              &alpha;&pi;&alpha;&iota;&tau;&epsilon;ί&tau;&alpha;&iota;.
              &Gamma;&iota;&alpha;
              &pi;&alpha;&rho;ά&delta;&epsilon;&iota;&gamma;&mu;&alpha;
              ό&tau;&alpha;&nu;
              &epsilon;&pi;&iota;&lambda;έ&gamma;&epsilon;&tau;&epsilon;
              &nu;&alpha; &lambda;&alpha;&mu;&beta;ά&nu;&epsilon;&tau;&epsilon;
              newsletter. &Kappa;&alpha;&tau;ά &tau;&eta;
              &sigma;&upsilon;&lambda;&lambda;&omicron;&gamma;ή &tau;&omega;&nu;
              &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ώ&nu;
              &sigma;&alpha;&sigmaf;
              &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;,
              &theta;&alpha; &sigma;&alpha;&sigmaf;
              &epsilon;&nu;&eta;&mu;&epsilon;&rho;ώ&nu;&omicron;&upsilon;&mu;&epsilon;
              &pi;ά&nu;&tau;&alpha; &pi;&omicron;&iota;&alpha;
              &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
              &epsilon;ί&nu;&alpha;&iota;
              &alpha;&pi;&alpha;&rho;&alpha;ί&tau;&eta;&tau;&alpha;
              &sigma;&epsilon; &sigma;&chi;έ&sigma;&eta; &mu;&epsilon;
              &mu;&iota;&alpha;
              &sigma;&upsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&eta;
              &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;.</span
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&tau;&iota;&sigmaf;
              &upsilon;&pi;&omicron;&chi;&rho;&epsilon;ώ&sigma;&epsilon;&iota;&sigmaf;
              &tau;&eta;&sigmaf;
              &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;
              &pi;&omicron;&upsilon;
              &pi;&eta;&gamma;ά&zeta;&omicron;&upsilon;&nu; &alpha;&pi;ό
              &tau;&omicron;&nu; &nu;ό&mu;&omicron; (&pi;.&chi;.
              &phi;&omicron;&rho;&omicron;&lambda;&omicron;&gamma;&iota;&kappa;ή
              &nu;&omicron;&mu;&omicron;&theta;&epsilon;&sigma;ί&alpha;,
              &nu;&omicron;&mu;&omicron;&theta;&epsilon;&sigma;ί&alpha;
              &gamma;&iota;&alpha; &tau;&omicron;
              &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ό
              &epsilon;&mu;&pi;ό&rho;&iota;&omicron; &kappa;.ά.)</span
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&tau;&omicron; έ&nu;&nu;&omicron;&mu;&omicron;
              &sigma;&upsilon;&mu;&phi;έ&rho;&omicron;&nu; &tau;&eta;&sigmaf;
              &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;
              &mu;&alpha;&sigmaf;. &Sigma;&epsilon;
              &sigma;&upsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&epsilon;&sigmaf;
              &pi;&epsilon;&rho;&iota;&pi;&tau;ώ&sigma;&epsilon;&iota;&sigmaf;,
              &sigma;&upsilon;&lambda;&lambda;έ&gamma;&omicron;&upsilon;&mu;&epsilon;
              &tau;&alpha; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
              &sigma;&alpha;&sigmaf; &mu;&epsilon; &tau;&rho;ό&pi;&omicron;
              &pi;&omicron;&upsilon; &epsilon;ύ&lambda;&omicron;&gamma;&alpha;
              &alpha;&nu;&alpha;&mu;έ&nu;&epsilon;&tau;&alpha;&iota;
              &omega;&sigmaf; &mu;έ&rho;&omicron;&sigmaf; &tau;&eta;&sigmaf;
              &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;&sigmaf;
              &tau;&eta;&sigmaf;
              &epsilon;&pi;&iota;&chi;&epsilon;ί&rho;&eta;&sigma;ή&sigmaf;
              &mu;&alpha;&sigmaf; &kappa;&alpha;&iota; &pi;&omicron;&upsilon;
              &delta;&epsilon;&nu;
              &epsilon;&pi;&eta;&rho;&epsilon;ά&zeta;&epsilon;&iota;
              &omicron;&upsilon;&sigma;&iota;&alpha;&sigma;&tau;&iota;&kappa;ά
              &tau;&alpha; &delta;&iota;&kappa;&alpha;&iota;ώ&mu;&alpha;&tau;ά
              &sigma;&alpha;&sigmaf;, &tau;&eta;&nu;
              &epsilon;&lambda;&epsilon;&upsilon;&theta;&epsilon;&rho;ί&alpha; ή
              &tau;&alpha; &sigma;&upsilon;&mu;&phi;έ&rho;&omicron;&nu;&tau;ά
              &sigma;&alpha;&sigmaf;.</span
            >
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >7. &Pi;&omicron;&iota;&omicron;&iota; &epsilon;ί&nu;&alpha;&iota;
          &omicron;&iota;
          &alpha;&pi;&omicron;&delta;έ&kappa;&tau;&epsilon;&sigmaf;
          &tau;&omega;&nu; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&alpha;&sigmaf; ;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Pi;&rho;ό&sigma;&beta;&alpha;&sigma;&eta; &sigma;&tau;&alpha;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &sigma;&alpha;&sigmaf; έ&chi;&epsilon;&iota; &tau;&omicron;
          &alpha;&pi;&omicron;&lambda;ύ&tau;&omega;&sigmaf;
          &alpha;&pi;&alpha;&rho;&alpha;ί&tau;&eta;&tau;&omicron;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ό &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;,
          &tau;&omicron; &omicron;&pi;&omicron;ί&omicron; έ&chi;&epsilon;&iota;
          &delta;&epsilon;&sigma;&mu;&epsilon;&upsilon;&tau;&epsilon;ί
          &mu;&epsilon; &tau;ή&rho;&eta;&sigma;&eta;
          &epsilon;&mu;&pi;&iota;&sigma;&tau;&epsilon;&upsilon;&tau;&iota;&kappa;ό&tau;&eta;&tau;&alpha;&sigmaf;
          &kappa;&alpha;&iota; &omicron;&iota;
          &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&zeta;ό&mu;&epsilon;&nu;&epsilon;&sigmaf;
          &mu;&epsilon; &epsilon;&mu;ά&sigmaf;
          &epsilon;&pi;&iota;&chi;&epsilon;&iota;&rho;ή&sigma;&epsilon;&iota;&sigmaf;
          ή &tau;&rho;ί&tau;&omicron;&iota;
          &pi;ά&rho;&omicron;&chi;&omicron;&iota;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;, &omicron;&iota;
          &omicron;&pi;&omicron;ί&epsilon;&sigmaf;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;ά&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &tau;&alpha; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &sigma;&alpha;&sigmaf; &omega;&sigmaf;
          &Epsilon;&kappa;&tau;&epsilon;&lambda;&omicron;ύ&nu;&tau;&epsilon;&sigmaf;
          &tau;&eta;&nu;
          &Epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
          &gamma;&iota;&alpha;
          &lambda;&omicron;&gamma;&alpha;&rho;&iota;&alpha;&sigma;&mu;ό
          &mu;&alpha;&sigmaf; &kappa;&alpha;&iota;
          &sigma;ύ&mu;&phi;&omega;&nu;&alpha; &mu;&epsilon; &tau;&iota;&sigmaf;
          &epsilon;&nu;&tau;&omicron;&lambda;έ&sigmaf;
          &mu;&alpha;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >8. &Pi;ώ&sigmaf;
          &kappa;&omicron;&iota;&nu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;&tau;&alpha;&iota;
          &tau;&alpha; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &sigma;&alpha;&sigmaf;;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Kappa;&omicron;&iota;&nu;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu; &alpha;&pi;ό
          &tau;&eta;&nu; &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
          &mu;&alpha;&sigmaf;:</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Eta; &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
          &kappa;ά&nu;&epsilon;&iota; &kappa;&omicron;&iota;&nu;ή
          &chi;&rho;ή&sigma;&eta; &tau;&omega;&nu;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&alpha;&sigmaf; &mu;&epsilon;:</span
        >
      </p>
      <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&Tau;&rho;ί&tau;&omicron;&upsilon;&sigmaf;
              &pi;&alpha;&rho;ό&chi;&omicron;&upsilon;&sigmaf;
              &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
              &pi;&omicron;&upsilon;
              &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;ά&zeta;&omicron;&nu;&tau;&alpha;&iota;
              &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
              &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
              &gamma;&iota;&alpha;
              &lambda;&omicron;&gamma;&alpha;&rho;&iota;&alpha;&sigma;&mu;ό
              &tau;&eta;&sigmaf;
              &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;,
              &gamma;&iota;&alpha;
              &pi;&alpha;&rho;ά&delta;&epsilon;&iota;&gamma;&mu;&alpha;
              (&epsilon;&nu;&delta;&epsilon;&iota;&kappa;&tau;&iota;&kappa;ά
              &alpha;&nu;&alpha;&phi;έ&rho;&omicron;&nu;&tau;&alpha;&iota;)
              &gamma;&iota;&alpha; &tau;&eta;&nu;
              &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
              &pi;&iota;&sigma;&tau;&omega;&tau;&iota;&kappa;ώ&nu;
              &kappa;&alpha;&rho;&tau;ώ&nu; &kappa;&alpha;&iota;
              &pi;&lambda;&eta;&rho;&omega;&mu;ώ&nu;,
              &mu;&epsilon;&tau;&alpha;&phi;&omicron;&rho;έ&sigmaf;
              &kappa;&alpha;&iota;
              &pi;&alpha;&rho;&alpha;&delta;ό&sigma;&epsilon;&iota;&sigmaf;,
              &phi;&iota;&lambda;&omicron;&xi;&epsilon;&nu;ί&alpha;,
              &delta;&iota;&alpha;&chi;&epsilon;ί&rho;&iota;&sigma;&eta;
              &kappa;&alpha;&iota;
              &sigma;&upsilon;&nu;&tau;ή&rho;&eta;&sigma;&eta; &tau;&omega;&nu;
              &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
              &mu;&alpha;&sigmaf;, &delta;&iota;&alpha;&nu;&omicron;&mu;ή email,
              έ&rho;&epsilon;&upsilon;&nu;&alpha; &kappa;&alpha;&iota;
              &alpha;&nu;ά&lambda;&upsilon;&sigma;&eta;,
              &delta;&iota;&alpha;&chi;&epsilon;ί&rho;&iota;&sigma;&eta;
              &pi;&rho;&omicron;&omega;&theta;&eta;&tau;&iota;&kappa;ώ&nu;
              &epsilon;&nu;&epsilon;&rho;&gamma;&epsilon;&iota;ώ&nu;,
              &kappa;&alpha;&theta;ώ&sigmaf; &kappa;&alpha;&iota;
              &delta;&iota;&alpha;&chi;&epsilon;ί&rho;&iota;&sigma;&eta;
              &omicron;&rho;&iota;&sigma;&mu;έ&nu;&omega;&nu;
              &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
              &kappa;&alpha;&iota;
              &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&omega;&nu;.
              Ό&tau;&alpha;&nu;
              &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&mu;&epsilon;
              &tau;&rho;ί&tau;&omicron;&upsilon;&sigmaf;
              &pi;&alpha;&rho;ό&chi;&omicron;&upsilon;&sigmaf;
              &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
              &sigma;&upsilon;&nu;ά&pi;&tau;&omicron;&upsilon;&mu;&epsilon;
              &sigma;&upsilon;&mu;&phi;&omega;&nu;ί&epsilon;&sigmaf;
              &pi;&omicron;&upsilon; &tau;&omicron;&upsilon;&sigmaf;
              &upsilon;&pi;&omicron;&chi;&rho;&epsilon;ώ&nu;&omicron;&upsilon;&nu;
              &nu;&alpha;
              &epsilon;&phi;&alpha;&rho;&mu;ό&zeta;&omicron;&upsilon;&nu;
              &kappa;&alpha;&tau;ά&lambda;&lambda;&eta;&lambda;&alpha;
              &tau;&epsilon;&chi;&nu;&iota;&kappa;ά &kappa;&alpha;&iota;
              &omicron;&rho;&gamma;&alpha;&nu;&omega;&tau;&iota;&kappa;ά
              &mu;έ&tau;&rho;&alpha; &gamma;&iota;&alpha; &tau;&eta;&nu;
              &pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;
              &tau;&omega;&nu;
              &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ώ&nu;
              &sigma;&alpha;&sigmaf;
              &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;.</span
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >Ά&lambda;&lambda;&omicron;&upsilon;&sigmaf;
              &tau;&rho;ί&tau;&omicron;&upsilon;&sigmaf;
              &phi;&omicron;&rho;&epsilon;ί&sigmaf;, &sigma;&tau;&omicron;&nu;
              &beta;&alpha;&theta;&mu;ό &pi;&omicron;&upsilon;
              &alpha;&pi;&alpha;&iota;&tau;&epsilon;ί&tau;&alpha;&iota;
              &gamma;&iota;&alpha; &tau;&omicron;&upsilon;&sigmaf;
              &epsilon;&xi;ή&sigmaf;
              &sigma;&kappa;&omicron;&pi;&omicron;ύ&sigmaf;: (i)
              &sigma;&upsilon;&mu;&mu;ό&rho;&phi;&omega;&sigma;&eta;
              &mu;&epsilon;&tau;ά &alpha;&pi;ό &alpha;ί&tau;&eta;&mu;&alpha;
              &omicron;&rho;&gamma;ά&nu;&omicron;&upsilon; &tau;&eta;&sigmaf;
              &Epsilon;&lambda;&lambda;&eta;&nu;&iota;&kappa;ή&sigmaf;
              &Pi;&omicron;&lambda;&iota;&tau;&epsilon;ί&alpha;&sigmaf;,
              &delta;&iota;&kappa;&alpha;&sigma;&tau;&iota;&kappa;ή
              &alpha;&pi;ό&phi;&alpha;&sigma;&eta; ή
              &iota;&sigma;&chi;ύ&omicron;&nu;&tau;&alpha; &nu;ό&mu;&omicron;,
              (ii) &alpha;&pi;&omicron;&tau;&rho;&omicron;&pi;ή
              &pi;&alpha;&rho;ά&nu;&omicron;&mu;&omega;&nu;
              &chi;&rho;ή&sigma;&epsilon;&omega;&nu; &tau;&omega;&nu;
              &Delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;ώ&nu;
              &Tau;ό&pi;&omega;&nu; &kappa;&alpha;&iota; Apps
              &mu;&alpha;&sigmaf; ή
              &pi;&alpha;&rho;&alpha;&beta;&iota;ά&sigma;&epsilon;&omega;&nu;
              &tau;&omega;&nu; Ό&rho;&omega;&nu; &Chi;&rho;ή&sigma;&eta;&sigmaf;
              &tau;&omega;&nu;
              &Delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;ώ&nu;
              &Tau;ό&pi;&omega;&nu; &kappa;&alpha;&iota; Apps
              &mu;&alpha;&sigmaf; &kappa;&alpha;&iota; &tau;&omega;&nu;
              &pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ώ&nu;
              &mu;&alpha;&sigmaf;, (iii) &delta;&iota;&kappa;ή
              &mu;&alpha;&sigmaf;
              &pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha; &alpha;&pi;ό
              &alpha;&xi;&iota;ώ&sigma;&epsilon;&iota;&sigmaf;
              &tau;&rho;ί&tau;&omega;&nu;, &kappa;&alpha;&iota; (iv)
              &sigma;&upsilon;&mu;&beta;&omicron;&lambda;ή &sigma;&tau;&eta;&nu;
              &alpha;&pi;&omicron;&tau;&rho;&omicron;&pi;ή ή
              έ&rho;&epsilon;&upsilon;&nu;&alpha;
              &pi;&epsilon;&rho;&iota;&pi;&tau;ώ&sigma;&epsilon;&omega;&nu;
              &alpha;&pi;ά&tau;&eta;&sigmaf; (&pi;.&chi;.
              &pi;&alpha;&rho;&alpha;&chi;ά&rho;&alpha;&xi;&eta;).</span
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >ά&lambda;&lambda;&omicron;&upsilon;&sigmaf;
              &tau;&rho;ί&tau;&omicron;&upsilon;&sigmaf;
              &sigma;&tau;&omicron;&upsilon;&sigmaf;
              &omicron;&pi;&omicron;ί&omicron;&upsilon;&sigmaf;
              &epsilon;&sigma;&epsilon;ί&sigmaf; &omicron;&iota;
              ί&delta;&iota;&omicron;&iota; έ&chi;&epsilon;&tau;&epsilon;
              &delta;ώ&sigma;&epsilon;&iota; &tau;&eta;
              &sigma;&upsilon;&gamma;&kappa;&alpha;&tau;ά&theta;&epsilon;&sigma;ή
              &sigma;&alpha;&sigmaf;.</span
            >
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Kappa;&omicron;&iota;&nu;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu; &alpha;&pi;ό
          &epsilon;&sigma;ά&sigmaf;:&nbsp;</span
        >
      </p>
      <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >Ό&tau;&alpha;&nu;
              &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&epsilon;ί&tau;&epsilon;
              &omicron;&rho;&iota;&sigma;&mu;έ&nu;&alpha;
              &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha;
              &mu;έ&sigma;&omega;&nu;
              &kappa;&omicron;&iota;&nu;&omega;&nu;&iota;&kappa;ή&sigmaf;
              &delta;&iota;&kappa;&tau;ύ&omega;&sigma;&eta;&sigmaf;
              &sigma;&tau;&omicron;&upsilon;&sigmaf;
              &Delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;&omicron;ύ&sigmaf;
              &Tau;ό&pi;&omicron;&upsilon;&sigmaf; ή Apps &mu;&alpha;&sigmaf;,
              &mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon; &nu;&alpha;
              &delta;&eta;&mu;&iota;&omicron;&upsilon;&rho;&gamma;ή&sigma;&epsilon;&tau;&epsilon;
              έ&nu;&alpha; &delta;&eta;&mu;ό&sigma;&iota;&omicron;
              &pi;&rho;&omicron;&phi;ί&lambda; &tau;&omicron;
              &omicron;&pi;&omicron;ί&omicron; &nu;&alpha;
              &pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;ά&nu;&epsilon;&iota;
              &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
              ό&pi;&omega;&sigmaf; ό&nu;&omicron;&mu;&alpha;
              &chi;&rho;ή&sigma;&tau;&eta;, &epsilon;&iota;&kappa;ό&nu;&alpha;
              &pi;&rho;&omicron;&phi;ί&lambda; &kappa;&alpha;&iota;
              &pi;ό&lambda;&eta;. &Mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon;
              &epsilon;&pi;ί&sigma;&eta;&sigmaf; &nu;&alpha;
              &kappa;ά&nu;&epsilon;&tau;&epsilon; &kappa;&omicron;&iota;&nu;ή
              &chi;&rho;ή&sigma;&eta;
              &pi;&epsilon;&rho;&iota;&epsilon;&chi;&omicron;&mu;έ&nu;&omicron;&upsilon;
              &mu;&epsilon; &tau;&omicron;&upsilon;&sigmaf;
              &phi;ί&lambda;&omicron;&upsilon;&sigmaf; &sigma;&alpha;&sigmaf; ή
              &tau;&omicron; &epsilon;&upsilon;&rho;ύ
              &kappa;&omicron;&iota;&nu;ό,
              &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;&alpha;&nu;&omicron;&mu;έ&nu;&omega;&nu;
              &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;&iota;ώ&nu;
              &gamma;&iota;&alpha; &tau;&eta;&nu;
              &alpha;&lambda;&lambda;&eta;&lambda;&epsilon;&pi;ί&delta;&rho;&alpha;&sigma;ή
              &sigma;&alpha;&sigmaf; &mu;&epsilon; &tau;&eta;&nu;
              &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;.
              &Sigma;&alpha;&sigmaf;
              &epsilon;&nu;&theta;&alpha;&rho;&rho;ύ&nu;&omicron;&upsilon;&mu;&epsilon;
              &nu;&alpha;
              &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&epsilon;ί&tau;&epsilon;
              &tau;&alpha; &epsilon;&rho;&gamma;&alpha;&lambda;&epsilon;ί&alpha;
              &pi;&omicron;&upsilon; &sigma;&alpha;&sigmaf;
              &pi;&alpha;&rho;έ&chi;&omicron;&upsilon;&mu;&epsilon;
              &gamma;&iota;&alpha; &tau;&eta;
              &delta;&iota;&alpha;&chi;&epsilon;ί&rho;&iota;&sigma;&eta;
              &tau;&eta;&sigmaf; &kappa;&omicron;&iota;&nu;ή&sigmaf;
              &chi;&rho;ή&sigma;&eta;&sigmaf; &sigma;&tau;&alpha;
              &mu;έ&sigma;&alpha;
              &kappa;&omicron;&iota;&nu;&omega;&nu;&iota;&kappa;ή&sigmaf;
              &delta;&iota;&kappa;&tau;ύ&omega;&sigma;&eta;&sigmaf;
              &tau;&eta;&sigmaf;
              &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;
              &mu;&epsilon; &sigma;&kappa;&omicron;&pi;ό &tau;&omicron;&nu;
              έ&lambda;&epsilon;&gamma;&chi;&omicron; &tau;&omega;&nu;
              &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;&iota;ώ&nu;
              &pi;&omicron;&upsilon;
              &kappa;&alpha;&theta;&iota;&sigma;&tau;ά&tau;&epsilon;
              &delta;&iota;&alpha;&theta;έ&sigma;&iota;&mu;&epsilon;&sigmaf;
              &mu;έ&sigma;&omega; &tau;&omega;&nu;
              &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&omega;&nu;
              &mu;έ&sigma;&omega;&nu;
              &kappa;&omicron;&iota;&nu;&omega;&nu;&iota;&kappa;ή&sigmaf;
              &delta;&iota;&kappa;&tau;ύ&omega;&sigma;&eta;&sigmaf;
              &tau;&eta;&sigmaf;
              &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;.</span
            >
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >9. &Pi;&omicron;&iota;&alpha; &eta;
          &pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή &pi;&omicron;&upsilon;
          &epsilon;&phi;&alpha;&rho;&mu;ό&zeta;&omicron;&upsilon;&mu;&epsilon;
          &mu;&epsilon; &tau;&omicron;&upsilon;&sigmaf;
          &tau;&rho;ί&tau;&omicron;&upsilon;&sigmaf;
          &Epsilon;&kappa;&tau;&epsilon;&lambda;&omicron;ύ&nu;&tau;&epsilon;&sigmaf;
          &tau;&eta;&nu;
          &Epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
          &tau;&omega;&nu; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&alpha;&sigmaf; &sigma;ύ&mu;&phi;&omega;&nu;&alpha;
          &mu;&epsilon; &tau;&alpha; &alpha;&nu;&omega;&tau;έ&rho;&omega;:</span
        >
      </p>
      <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&Pi;&alpha;&rho;έ&chi;&omicron;&upsilon;&mu;&epsilon;
              &mu;ό&nu;&omicron; &tau;&iota;&sigmaf;
              &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
              &pi;&omicron;&upsilon;
              &chi;&rho;&epsilon;&iota;ά&zeta;&omicron;&nu;&tau;&alpha;&iota;
              &gamma;&iota;&alpha; &tau;&eta;&nu;
              &epsilon;&kappa;&tau;έ&lambda;&epsilon;&sigma;&eta;
              &tau;&omega;&nu;
              &sigma;&upsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&omega;&nu;
              &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
              &tau;&omicron;&upsilon;&sigmaf;.</span
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&Mu;&pi;&omicron;&rho;&omicron;ύ&nu; &nu;&alpha;
              &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;ή&sigma;&omicron;&upsilon;&nu;
              &tau;&alpha; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
              &sigma;&alpha;&sigmaf; &mu;ό&nu;&omicron; &gamma;&iota;&alpha;
              &tau;&omicron;&upsilon;&sigmaf;
              &alpha;&kappa;&rho;&iota;&beta;&epsilon;ί&sigmaf;
              &sigma;&kappa;&omicron;&pi;&omicron;ύ&sigmaf;
              &pi;&omicron;&upsilon;
              &kappa;&alpha;&theta;&omicron;&rho;ί&zeta;&omicron;&upsilon;&mu;&epsilon;
              &sigma;&tau;&eta; &sigma;ύ&mu;&beta;&alpha;&sigma;ή
              &mu;&alpha;&sigmaf; &mu;&alpha;&zeta;ί
              &tau;&omicron;&upsilon;&sigmaf;.</span
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&Sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&zeta;ό&mu;&alpha;&sigma;&tau;&epsilon;
              &sigma;&tau;&epsilon;&nu;ά &mu;&alpha;&zeta;ί
              &tau;&omicron;&upsilon;&sigmaf; &gamma;&iota;&alpha; &nu;&alpha;
              &delta;&iota;&alpha;&sigma;&phi;&alpha;&lambda;ί&sigma;&omicron;&upsilon;&mu;&epsilon;
              ό&tau;&iota; &tau;&omicron; &alpha;&pi;ό&rho;&rho;&eta;&tau;ό
              &sigma;&alpha;&sigmaf; &epsilon;ί&nu;&alpha;&iota;
              &sigma;&epsilon;&beta;&alpha;&sigma;&tau;ό &kappa;&alpha;&iota;
              &pi;&rho;&omicron;&sigma;&tau;&alpha;&tau;&epsilon;ύ&epsilon;&tau;&alpha;&iota;
              &alpha;&nu;ά &pi;ά&sigma;&alpha;
              &sigma;&tau;&iota;&gamma;&mu;ή.</span
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&Alpha;&nu;
              &sigma;&tau;&alpha;&mu;&alpha;&tau;ή&sigma;&omicron;&upsilon;&mu;&epsilon;
              &nu;&alpha;
              &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&mu;&epsilon;
              &tau;&iota;&sigmaf;
              &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
              &tau;&omicron;&upsilon;&sigmaf;,
              &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
              &alpha;&pi;ό &tau;&alpha;
              &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
              &pi;&omicron;&upsilon;
              &kappa;&alpha;&tau;έ&chi;&omicron;&upsilon;&nu; &theta;&alpha;
              &delta;&iota;&alpha;&gamma;&rho;&alpha;&phi;&omicron;ύ&nu; ή
              &theta;&alpha;
              &kappa;&alpha;&tau;&alpha;&sigma;&tau;&omicron;ύ&nu;
              &alpha;&nu;ώ&nu;&upsilon;&mu;&alpha;.</span
            >
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Gamma;&iota;&alpha; &nu;&alpha;
          &beta;&epsilon;&lambda;&tau;&iota;ώ&sigma;&omicron;&upsilon;&mu;&epsilon;
          &tau;&eta;&nu; &epsilon;&mu;&pi;&epsilon;&iota;&rho;ί&alpha;
          &sigma;&alpha;&sigmaf; &omega;&sigmaf;
          &pi;&epsilon;&lambda;ά&tau;&eta;
          &sigma;&tau;&omicron;&upsilon;&sigmaf;
          &Delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;&omicron;ύ&sigmaf;
          &mu;&alpha;&sigmaf; &Tau;ό&pi;&omicron;&upsilon;&sigmaf;
          &kappa;&alpha;&iota; Apps,
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&mu;&epsilon;
          &tau;&iota;&sigmaf;
          &alpha;&kappa;ό&lambda;&omicron;&upsilon;&theta;&epsilon;&sigmaf;
          &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&epsilon;&sigmaf;,
          &omicron;&iota; &omicron;&pi;&omicron;ί&epsilon;&sigmaf;
          &theta;&alpha;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;&tau;&omicron;ύ&nu;
          &tau;&alpha; &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &sigma;&alpha;&sigmaf;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha; &omega;&sigmaf;
          &mu;έ&rho;&omicron;&sigmaf; &tau;&omega;&nu;
          &sigma;&upsilon;&mu;&beta;ά&sigma;&epsilon;ώ&nu;
          &tau;&omicron;&upsilon;&sigmaf; &mu;&alpha;&zeta;ί
          &mu;&alpha;&sigmaf;:</span
        >
      </p>
      <div align="left" dir="ltr" style="margin-left: -8.65pt">
        <table style="border: none; border-collapse: collapse">
          <tbody>
            <tr style="height: 0pt">
              <td
                style="
                  vertical-align: top;
                  background-color: #fdfcfb;
                  padding: 6pt 8.65pt 6pt 8.65pt;
                  overflow: hidden;
                  overflow-wrap: break-word;
                "
              >
                <ul
                  style="
                    margin-top: 0;
                    margin-bottom: 0;
                    padding-inline-start: 48px;
                  "
                >
                  <li
                    aria-level="1"
                    dir="ltr"
                    style="
                      list-style-type: disc;
                      font-size: 12pt;
                      font-family: Arial;
                      color: #000000;
                      background-color: transparent;
                      font-weight: 400;
                      font-style: normal;
                      font-variant: normal;
                      text-decoration: none;
                      vertical-align: baseline;
                      white-space: pre;
                    "
                  >
                    <p
                      dir="ltr"
                      style="
                        line-height: 1.7999999999999998;
                        text-align: justify;
                        margin-top: 0pt;
                        margin-bottom: 0pt;
                      "
                    >
                      <span
                        style="
                          font-size: 12pt;
                          font-family: Arial;
                          color: #000000;
                          background-color: #ffffff;
                          font-weight: 400;
                          font-style: normal;
                          font-variant: normal;
                          text-decoration: none;
                          vertical-align: baseline;
                          white-space: pre;
                          white-space: pre-wrap;
                        "
                        >Facebook</span
                      >
                    </p>
                  </li>
                </ul>
              </td>
              <td
                style="
                  vertical-align: top;
                  background-color: #fdfcfb;
                  padding: 6pt 8.65pt 6pt 8.65pt;
                  overflow: hidden;
                  overflow-wrap: break-word;
                "
              >
                <ul
                  style="
                    margin-top: 0;
                    margin-bottom: 0;
                    padding-inline-start: 48px;
                  "
                >
                  <li
                    aria-level="1"
                    dir="ltr"
                    style="
                      list-style-type: disc;
                      font-size: 12pt;
                      font-family: Arial;
                      color: #000000;
                      background-color: transparent;
                      font-weight: 400;
                      font-style: normal;
                      font-variant: normal;
                      text-decoration: none;
                      vertical-align: baseline;
                      white-space: pre;
                    "
                  >
                    <p
                      dir="ltr"
                      style="
                        line-height: 1.7999999999999998;
                        text-align: justify;
                        margin-top: 0pt;
                        margin-bottom: 0pt;
                      "
                    >
                      <span
                        style="
                          font-size: 12pt;
                          font-family: Arial;
                          color: #000000;
                          background-color: #ffffff;
                          font-weight: 400;
                          font-style: normal;
                          font-variant: normal;
                          text-decoration: none;
                          vertical-align: baseline;
                          white-space: pre;
                          white-space: pre-wrap;
                        "
                        >Google</span
                      >
                    </p>
                  </li>
                </ul>
              </td>
              <td
                style="
                  vertical-align: top;
                  background-color: #fdfcfb;
                  padding: 6pt 8.65pt 6pt 8.65pt;
                  overflow: hidden;
                  overflow-wrap: break-word;
                "
              >
                <ul
                  style="
                    margin-top: 0;
                    margin-bottom: 0;
                    padding-inline-start: 48px;
                  "
                >
                  <li
                    aria-level="1"
                    dir="ltr"
                    style="
                      list-style-type: disc;
                      font-size: 12pt;
                      font-family: Arial;
                      color: #000000;
                      background-color: transparent;
                      font-weight: 400;
                      font-style: normal;
                      font-variant: normal;
                      text-decoration: none;
                      vertical-align: baseline;
                      white-space: pre;
                    "
                  >
                    <p
                      dir="ltr"
                      style="
                        line-height: 1.7999999999999998;
                        text-align: justify;
                        margin-top: 0pt;
                        margin-bottom: 0pt;
                      "
                    >
                      <span
                        style="
                          font-size: 12pt;
                          font-family: Arial;
                          color: #000000;
                          background-color: #ffffff;
                          font-weight: 400;
                          font-style: normal;
                          font-variant: normal;
                          text-decoration: none;
                          vertical-align: baseline;
                          white-space: pre;
                          white-space: pre-wrap;
                        "
                        >YouTube</span
                      >
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr style="height: 0pt">
              <td
                style="
                  vertical-align: top;
                  background-color: #fdfcfb;
                  padding: 6pt 8.65pt 6pt 8.65pt;
                  overflow: hidden;
                  overflow-wrap: break-word;
                "
              >
                <ul
                  style="
                    margin-top: 0;
                    margin-bottom: 0;
                    padding-inline-start: 48px;
                  "
                >
                  <li
                    aria-level="1"
                    dir="ltr"
                    style="
                      list-style-type: disc;
                      font-size: 12pt;
                      font-family: Arial;
                      color: #000000;
                      background-color: transparent;
                      font-weight: 400;
                      font-style: normal;
                      font-variant: normal;
                      text-decoration: none;
                      vertical-align: baseline;
                      white-space: pre;
                    "
                  >
                    <p
                      dir="ltr"
                      style="
                        line-height: 1.7999999999999998;
                        text-align: justify;
                        margin-top: 0pt;
                        margin-bottom: 0pt;
                      "
                    >
                      <span
                        style="
                          font-size: 12pt;
                          font-family: Arial;
                          color: #000000;
                          background-color: #ffffff;
                          font-weight: 400;
                          font-style: normal;
                          font-variant: normal;
                          text-decoration: none;
                          vertical-align: baseline;
                          white-space: pre;
                          white-space: pre-wrap;
                        "
                        >Instagram</span
                      >
                    </p>
                  </li>
                </ul>
              </td>
              <td
                style="
                  vertical-align: top;
                  background-color: #fdfcfb;
                  padding: 6pt 8.65pt 6pt 8.65pt;
                  overflow: hidden;
                  overflow-wrap: break-word;
                "
              >
                <ul
                  style="
                    margin-top: 0;
                    margin-bottom: 0;
                    padding-inline-start: 48px;
                  "
                >
                  <li
                    aria-level="1"
                    dir="ltr"
                    style="
                      list-style-type: disc;
                      font-size: 12pt;
                      font-family: Arial;
                      color: #000000;
                      background-color: transparent;
                      font-weight: 400;
                      font-style: normal;
                      font-variant: normal;
                      text-decoration: none;
                      vertical-align: baseline;
                      white-space: pre;
                    "
                  >
                    <p
                      dir="ltr"
                      style="
                        line-height: 1.7999999999999998;
                        text-align: justify;
                        margin-top: 0pt;
                        margin-bottom: 0pt;
                      "
                    >
                      <span
                        style="
                          font-size: 12pt;
                          font-family: Arial;
                          color: #000000;
                          background-color: #ffffff;
                          font-weight: 400;
                          font-style: normal;
                          font-variant: normal;
                          text-decoration: none;
                          vertical-align: baseline;
                          white-space: pre;
                          white-space: pre-wrap;
                        "
                        >Twitter</span
                      >
                    </p>
                  </li>
                </ul>
              </td>
              <td
                style="
                  vertical-align: top;
                  background-color: #fdfcfb;
                  padding: 6pt 8.65pt 6pt 8.65pt;
                  overflow: hidden;
                  overflow-wrap: break-word;
                "
              >
                <ul
                  style="
                    margin-top: 0;
                    margin-bottom: 0;
                    padding-inline-start: 48px;
                  "
                >
                  <li
                    aria-level="1"
                    dir="ltr"
                    style="
                      list-style-type: disc;
                      font-size: 12pt;
                      font-family: Arial;
                      color: #000000;
                      background-color: transparent;
                      font-weight: 400;
                      font-style: normal;
                      font-variant: normal;
                      text-decoration: none;
                      vertical-align: baseline;
                      white-space: pre;
                    "
                  >
                    <p
                      dir="ltr"
                      style="
                        line-height: 1.7999999999999998;
                        text-align: justify;
                        margin-top: 0pt;
                        margin-bottom: 0pt;
                      "
                    >
                      <span
                        style="
                          font-size: 12pt;
                          font-family: Arial;
                          color: #000000;
                          background-color: #ffffff;
                          font-weight: 400;
                          font-style: normal;
                          font-variant: normal;
                          text-decoration: none;
                          vertical-align: baseline;
                          white-space: pre;
                          white-space: pre-wrap;
                        "
                        >LinkedIn</span
                      >
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr style="height: 0pt">
              <td
                style="
                  vertical-align: top;
                  background-color: #fdfcfb;
                  padding: 6pt 8.65pt 6pt 8.65pt;
                  overflow: hidden;
                  overflow-wrap: break-word;
                "
              >
                <ul
                  style="
                    margin-top: 0;
                    margin-bottom: 0;
                    padding-inline-start: 48px;
                  "
                >
                  <li
                    aria-level="1"
                    dir="ltr"
                    style="
                      list-style-type: disc;
                      font-size: 12pt;
                      font-family: Arial;
                      color: #000000;
                      background-color: transparent;
                      font-weight: 400;
                      font-style: normal;
                      font-variant: normal;
                      text-decoration: none;
                      vertical-align: baseline;
                      white-space: pre;
                    "
                  >
                    <br />
                  </li>
                </ul>
              </td>
              <td
                style="
                  vertical-align: top;
                  background-color: #fdfcfb;
                  padding: 6pt 8.65pt 6pt 8.65pt;
                  overflow: hidden;
                  overflow-wrap: break-word;
                "
              >
                <ul
                  style="
                    margin-top: 0;
                    margin-bottom: 0;
                    padding-inline-start: 48px;
                  "
                >
                  <li
                    aria-level="1"
                    dir="ltr"
                    style="
                      list-style-type: disc;
                      font-size: 12pt;
                      font-family: Arial;
                      color: #000000;
                      background-color: transparent;
                      font-weight: 400;
                      font-style: normal;
                      font-variant: normal;
                      text-decoration: none;
                      vertical-align: baseline;
                      white-space: pre;
                    "
                  >
                    <br />
                  </li>
                </ul>
              </td>
              <td
                style="
                  vertical-align: top;
                  background-color: #fdfcfb;
                  padding: 6pt 8.65pt 6pt 8.65pt;
                  overflow: hidden;
                  overflow-wrap: break-word;
                "
              >
                <ul
                  style="
                    margin-top: 0;
                    margin-bottom: 0;
                    padding-inline-start: 48px;
                  "
                >
                  <li
                    aria-level="1"
                    dir="ltr"
                    style="
                      list-style-type: disc;
                      font-size: 12pt;
                      font-family: Arial;
                      color: #000000;
                      background-color: transparent;
                      font-weight: 400;
                      font-style: normal;
                      font-variant: normal;
                      text-decoration: none;
                      vertical-align: baseline;
                      white-space: pre;
                    "
                  >
                    <br />
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Sigma;&epsilon; &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
          &pi;&omicron;&upsilon;
          &epsilon;&pi;&iota;&theta;&upsilon;&mu;&epsilon;ί&tau;&epsilon;
          &nu;&alpha; &lambda;ά&beta;&epsilon;&tau;&epsilon;
          &pi;&epsilon;&rho;&iota;&sigma;&sigma;ό&tau;&epsilon;&rho;&epsilon;&sigmaf;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά &mu;&epsilon; &tau;&eta;&nu;
          &kappa;&omicron;&iota;&nu;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
          &tau;&omega;&nu; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&alpha;&sigmaf; &sigma;&epsilon;
          &tau;&rho;ί&tau;&omicron;&upsilon;&sigmaf;
          &pi;&alpha;&rho;&alpha;&kappa;&alpha;&lambda;&omicron;ύ&mu;&epsilon;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ή&sigma;&tau;&epsilon;
          &mu;&alpha;&zeta;ί &mu;&alpha;&sigmaf; &mu;&epsilon;
          email&nbsp;&sigma;&tau;&omicron; info@stoferno.gr.</span
        ><span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #ff0000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&nbsp; &nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >10. &Pi;ώ&sigmaf;
          &epsilon;&xi;&alpha;&sigma;&phi;&alpha;&lambda;ί&zeta;&omicron;&upsilon;&mu;&epsilon;
          ό&tau;&iota; &omicron;&iota;
          &Epsilon;&kappa;&tau;&epsilon;&lambda;&omicron;ύ&nu;&tau;&epsilon;&sigmaf;
          &tau;&eta;&nu;
          &Epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
          &sigma;έ&beta;&omicron;&nu;&tau;&alpha;&iota; &tau;&alpha;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &sigma;&alpha;&sigmaf;;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Omicron;&iota;
          &Epsilon;&kappa;&tau;&epsilon;&lambda;&omicron;ύ&nu;&tau;&epsilon;&sigmaf;
          &tau;&eta;&nu;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
          &gamma;&iota;&alpha;
          &lambda;&omicron;&gamma;&alpha;&rho;&iota;&alpha;&sigma;&mu;ό
          &mu;&alpha;&sigmaf; έ&chi;&omicron;&upsilon;&nu;
          &sigma;&upsilon;&mu;&phi;&omega;&nu;ή&sigma;&epsilon;&iota;
          &kappa;&alpha;&iota;
          &delta;&epsilon;&sigma;&mu;&epsilon;&upsilon;&tau;&epsilon;ί
          &sigma;&upsilon;&mu;&beta;&alpha;&tau;&iota;&kappa;ά &mu;&epsilon;
          &tau;&eta;&nu; &Epsilon;&tau;&alpha;&iota;&rho;ί&alpha;:</span
        >
      </p>
      <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&nu;&alpha; &tau;&eta;&rho;&omicron;ύ&nu;
              &epsilon;&chi;&epsilon;&mu;ύ&theta;&epsilon;&iota;&alpha;,</span
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&nu;&alpha; &mu;&eta;
              &sigma;&tau;έ&lambda;&nu;&omicron;&upsilon;&nu; &sigma;&epsilon;
              &tau;&rho;ί&tau;&omicron;&upsilon;&sigmaf;
              &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
              &sigma;&alpha;&sigmaf; &chi;&omega;&rho;ί&sigmaf; &tau;&eta;&nu;
              ά&delta;&epsilon;&iota;&alpha; &tau;&eta;&sigmaf;
              &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;,</span
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&nu;&alpha; &lambda;&alpha;&mu;&beta;ά&nu;&omicron;&upsilon;&nu;
              &kappa;&alpha;&tau;ά&lambda;&lambda;&eta;&lambda;&alpha;
              &mu;έ&tau;&rho;&alpha;
              &alpha;&sigma;&phi;ά&lambda;&epsilon;&iota;&alpha;&sigmaf;,</span
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&nu;&alpha;
              &sigma;&upsilon;&mu;&mu;&omicron;&rho;&phi;ώ&nu;&omicron;&nu;&tau;&alpha;&iota;
              &mu;&epsilon; &tau;&omicron; &nu;&omicron;&mu;&iota;&kappa;ό
              &pi;&lambda;&alpha;ί&sigma;&iota;&omicron; &gamma;&iota;&alpha;
              &tau;&eta;&nu;
              &pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;
              &tau;&omega;&nu;
              &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ώ&nu;
              &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
              &kappa;&alpha;&iota; &iota;&delta;ί&omega;&sigmaf;
              &tau;&omicron;&nu;
              &Kappa;&alpha;&nu;&omicron;&nu;&iota;&sigma;&mu;ό
              979/2016/&Epsilon;&Epsilon; (ά&lambda;&lambda;&omega;&sigmaf;
              GDPR).</span
            >
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >11. &Mu;&epsilon;&tau;&alpha;&phi;&omicron;&rho;ά
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Tau;&alpha; &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &pi;&omicron;&upsilon;
          &sigma;&upsilon;&lambda;&lambda;έ&gamma;&omicron;&upsilon;&mu;&epsilon;
          (ή
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&zeta;ό&mu;&alpha;&sigma;&tau;&epsilon;)
          &sigma;&tau;&omicron; &pi;&lambda;&alpha;ί&sigma;&iota;&omicron;
          &tau;&omega;&nu;
          &Delta;&iota;&alpha;&delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;ώ&nu;
          &Tau;ό&pi;&omega;&nu; &kappa;&alpha;&iota; Apps &mu;&alpha;&sigmaf;
          &theta;&alpha;
          &alpha;&pi;&omicron;&theta;&eta;&kappa;&epsilon;ύ&omicron;&nu;&tau;&alpha;&iota;
          &epsilon;&nu;&tau;ό&sigmaf;
          &Epsilon;&upsilon;&rho;&omega;&pi;&alpha;ϊ&kappa;ή&sigmaf;
          Έ&nu;&omega;&sigma;&eta;&sigmaf;.
          &Omega;&sigma;&tau;ό&sigma;&omicron;,
          &mu;&epsilon;&rho;&iota;&kappa;&omicron;ί &alpha;&pi;ό
          &tau;&omicron;&upsilon;&sigmaf;
          &pi;&alpha;&rho;&alpha;&lambda;ή&pi;&tau;&epsilon;&sigmaf;
          &tau;&omega;&nu; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &mu;&epsilon; &tau;&omicron;&upsilon;&sigmaf;
          &omicron;&pi;&omicron;ί&omicron;&upsilon;&sigmaf; &eta;
          &Epsilon;&tau;&alpha;&iota;&rho;ί&alpha;
          &mu;&omicron;&iota;&rho;ά&zeta;&epsilon;&tau;&alpha;&iota;
          &tau;&alpha; &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &sigma;&alpha;&sigmaf; &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
          &beta;&rho;ί&sigma;&kappa;&omicron;&nu;&tau;&alpha;&iota;
          &sigma;&epsilon; ά&lambda;&lambda;&epsilon;&sigmaf;
          &chi;ώ&rho;&epsilon;&sigmaf; &epsilon;&kappa;&tau;ό&sigmaf;
          &alpha;&pi;ό &alpha;&upsilon;&tau;ή&nu; &sigma;&tau;&eta;&nu;
          &omicron;&pi;&omicron;ί&alpha;
          &pi;&rho;&alpha;&gamma;&mu;&alpha;&tau;&omicron;&pi;&omicron;&iota;ή&theta;&eta;&kappa;&epsilon;
          &eta; &alpha;&rho;&chi;&iota;&kappa;ή
          &sigma;&upsilon;&lambda;&lambda;&omicron;&gamma;ή &tau;&omega;&nu;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ώ&nu;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&alpha;&sigmaf;. &Eta;
          &nu;&omicron;&mu;&omicron;&theta;&epsilon;&sigma;ί&alpha;
          &sigma;&tau;&iota;&sigmaf; &epsilon;&nu; &lambda;ό&gamma;&omega;
          &chi;ώ&rho;&epsilon;&sigmaf; &mu;&pi;&omicron;&rho;&epsilon;ί
          &nu;&alpha; &mu;&eta;&nu; &pi;&alpha;&rho;έ&chi;&epsilon;&iota;
          &tau;&omicron; ί&delta;&iota;&omicron;
          &epsilon;&pi;ί&pi;&epsilon;&delta;&omicron;
          &pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;&sigmaf;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu; &sigma;&epsilon;
          &sigma;ύ&gamma;&kappa;&rho;&iota;&sigma;&eta; &mu;&epsilon; &tau;&eta;
          &chi;ώ&rho;&alpha; &pi;&omicron;&upsilon;
          &pi;&alpha;&rho;&epsilon;ί&chi;&epsilon;
          &alpha;&rho;&chi;&iota;&kappa;ά &tau;&alpha;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &sigma;&alpha;&sigmaf;.
          &Epsilon;&nu;&tau;&omicron;ύ&tau;&omicron;&iota;&sigmaf;,
          ό&tau;&alpha;&nu;
          &mu;&epsilon;&tau;&alpha;&phi;έ&rho;&omicron;&upsilon;&mu;&epsilon;
          &tau;&alpha; &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &sigma;&alpha;&sigmaf; &sigma;&epsilon;
          &pi;&alpha;&rho;&alpha;&lambda;ή&pi;&tau;&epsilon;&sigmaf;
          &sigma;&epsilon; ά&lambda;&lambda;&epsilon;&sigmaf;
          &chi;ώ&rho;&epsilon;&sigmaf;,
          &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;&alpha;&nu;&omicron;&mu;έ&nu;&omega;&nu;
          &tau;&omega;&nu; &Eta;&Pi;&Alpha;, &tau;&eta;&sigmaf;
          &Epsilon;&lambda;&beta;&epsilon;&tau;ί&alpha;&sigmaf;
          &kappa;.&omicron;.&kappa;.,
          &nbsp;&delta;&epsilon;&sigma;&mu;&epsilon;&upsilon;ό&mu;&alpha;&sigma;&tau;&epsilon;
          &nu;&alpha;
          &pi;&rho;&omicron;&sigma;&tau;&alpha;&tau;&epsilon;ύ&omicron;&upsilon;&mu;&epsilon;
          &tau;&alpha; &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &sigma;&alpha;&sigmaf; ό&pi;&omega;&sigmaf;
          &pi;&epsilon;&rho;&iota;&gamma;&rho;ά&phi;&epsilon;&tau;&alpha;&iota;
          &sigma;&tau;&eta;&nu; &pi;&alpha;&rho;&omicron;ύ&sigma;&alpha;
          &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή
          &Alpha;&pi;&omicron;&rho;&rho;ή&tau;&omicron;&upsilon;
          &kappa;&alpha;&iota; &sigma;ύ&mu;&phi;&omega;&nu;&alpha; &mu;&epsilon;
          &tau;&eta;&nu; &iota;&sigma;&chi;ύ&omicron;&upsilon;&sigma;&alpha;
          &nu;&omicron;&mu;&omicron;&theta;&epsilon;&sigma;ί&alpha;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Lambda;&alpha;&mu;&beta;ά&nu;&omicron;&upsilon;&mu;&epsilon;
          &mu;έ&tau;&rho;&alpha; &gamma;&iota;&alpha; &tau;&eta;
          &sigma;&upsilon;&mu;&mu;ό&rho;&phi;&omega;&sigma;&eta; &mu;&epsilon;
          &tau;&iota;&sigmaf;
          &iota;&sigma;&chi;ύ&omicron;&upsilon;&sigma;&epsilon;&sigmaf;
          &nu;&omicron;&mu;&iota;&kappa;έ&sigmaf;
          &alpha;&pi;&alpha;&iota;&tau;ή&sigma;&epsilon;&iota;&sigmaf;
          &gamma;&iota;&alpha; &tau;&eta;
          &mu;&epsilon;&tau;&alpha;&phi;&omicron;&rho;ά
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ώ&nu;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu; &sigma;&epsilon;
          &pi;&alpha;&rho;&alpha;&lambda;ή&pi;&tau;&epsilon;&sigmaf;
          &sigma;&epsilon; &chi;ώ&rho;&epsilon;&sigmaf;
          &epsilon;&kappa;&tau;ό&sigmaf; &tau;&omicron;&upsilon;
          &Epsilon;&upsilon;&rho;&omega;&pi;&alpha;ϊ&kappa;&omicron;ύ
          &Omicron;&iota;&kappa;&omicron;&nu;&omicron;&mu;&iota;&kappa;&omicron;ύ
          &Chi;ώ&rho;&omicron;&upsilon; ή &tau;&eta;&nu;
          &Epsilon;&lambda;&beta;&epsilon;&tau;ί&alpha; &pi;&omicron;&upsilon;
          &delta;&epsilon;&nu;
          &epsilon;&xi;&alpha;&sigma;&phi;&alpha;&lambda;ί&zeta;&omicron;&upsilon;&nu;
          &epsilon;&pi;&alpha;&rho;&kappa;έ&sigmaf;
          &epsilon;&pi;ί&pi;&epsilon;&delta;&omicron;
          &pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;&sigmaf;.
          &Chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&mu;&epsilon;
          &delta;&iota;ά&phi;&omicron;&rho;&alpha; &mu;έ&tau;&rho;&alpha;
          &gamma;&iota;&alpha; &nu;&alpha;
          &delta;&iota;&alpha;&sigma;&phi;&alpha;&lambda;ί&zeta;&omicron;&upsilon;&mu;&epsilon;
          ό&tau;&iota; &tau;&alpha;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &sigma;&alpha;&sigmaf;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &pi;&omicron;&upsilon;
          &mu;&epsilon;&tau;&alpha;&phi;έ&rho;&omicron;&nu;&tau;&alpha;&iota;
          &sigma;&epsilon; &alpha;&upsilon;&tau;έ&sigmaf; &tau;&iota;&sigmaf;
          &chi;ώ&rho;&epsilon;&sigmaf;
          &alpha;&pi;&omicron;&lambda;&alpha;&mu;&beta;ά&nu;&omicron;&upsilon;&nu;
          &epsilon;&pi;&alpha;&rho;&kappa;ή
          &pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;
          &sigma;ύ&mu;&phi;&omega;&nu;&alpha; &mu;&epsilon;
          &tau;&omicron;&upsilon;&sigmaf;
          &kappa;&alpha;&nu;ό&nu;&epsilon;&sigmaf;
          &pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;&sigmaf;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;.
          &Alpha;&upsilon;&tau;ά
          &pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;ά&nu;&omicron;&upsilon;&nu;
          &tau;&eta;&nu; &upsilon;&pi;&omicron;&gamma;&rho;&alpha;&phi;ή
          &tau;&omega;&nu;
          &Sigma;&upsilon;&mu;&beta;&alpha;&tau;&iota;&kappa;ώ&nu;
          &Rho;&eta;&tau;&rho;ώ&nu;, &tau;&eta;&nu;
          &pi;&iota;&sigma;&tau;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
          ό&tau;&iota; &omicron;
          &pi;&alpha;&rho;&alpha;&lambda;ή&pi;&tau;&eta;&sigmaf;
          έ&chi;&epsilon;&iota;
          &upsilon;&iota;&omicron;&theta;&epsilon;&tau;ή&sigma;&epsilon;&iota;
          &tau;&omicron;&upsilon;&sigmaf;
          &Epsilon;&upsilon;&rho;&omega;&pi;&alpha;ϊ&kappa;&omicron;ύ&sigmaf;
          &delta;&epsilon;&sigma;&mu;&epsilon;&upsilon;&tau;&iota;&kappa;&omicron;ύ&sigmaf;
          &kappa;&alpha;&nu;ό&nu;&epsilon;&sigmaf; ή &tau;&eta;&rho;&epsilon;ί
          &tau;&eta;&nu; &Alpha;&sigma;&pi;ί&delta;&alpha;
          &Pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;&sigmaf; (Privacy
          Shield) &mu;&epsilon;&tau;&alpha;&xi;ύ
          &Epsilon;&Epsilon;-&Eta;&Pi;&Alpha; &kappa;&alpha;&iota;
          &Epsilon;&lambda;&beta;&epsilon;&tau;ί&alpha; -
          &Eta;&Pi;&Alpha;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >12. &Gamma;&iota;&alpha; &pi;ό&sigma;&omicron;
          &chi;&rho;&omicron;&nu;&iota;&kappa;ό
          &delta;&iota;ά&sigma;&tau;&eta;&mu;&alpha;
          &delta;&iota;&alpha;&tau;&eta;&rho;&omicron;ύ&mu;&epsilon;
          &tau;&alpha; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &sigma;&alpha;&sigmaf;;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Delta;&iota;&alpha;&tau;&eta;&rho;&omicron;ύ&mu;&epsilon;
          &tau;&alpha; &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &sigma;&alpha;&sigmaf; ό&sigma;&omicron;
          &chi;&rho;&epsilon;&iota;ά&zeta;&epsilon;&tau;&alpha;&iota;
          &gamma;&iota;&alpha; &nu;&alpha;
          &epsilon;&kappa;&pi;&lambda;&eta;&rho;ώ&sigma;&omicron;&upsilon;&mu;&epsilon;
          &tau;&omicron;&upsilon;&sigmaf;
          &sigma;&kappa;&omicron;&pi;&omicron;ύ&sigmaf; &pi;&omicron;&upsilon;
          &omicron;&rho;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &sigma;&tau;&eta;&nu; &pi;&alpha;&rho;&omicron;ύ&sigma;&alpha;
          &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή
          &Alpha;&pi;&omicron;&rho;&rho;ή&tau;&omicron;&upsilon;
          (&epsilon;&kappa;&tau;ό&sigmaf; &alpha;&nu;
          &alpha;&pi;&alpha;&iota;&tau;&epsilon;ί&tau;&alpha;&iota; &alpha;&pi;ό
          &tau;&eta;&nu; &iota;&sigma;&chi;ύ&omicron;&upsilon;&sigma;&alpha;
          &nu;&omicron;&mu;&omicron;&theta;&epsilon;&sigma;ί&alpha;
          &mu;&epsilon;&gamma;&alpha;&lambda;ύ&tau;&epsilon;&rho;&eta;
          &pi;&epsilon;&rho;ί&omicron;&delta;&omicron;&sigmaf;
          &delta;&iota;&alpha;&tau;ή&rho;&eta;&sigma;&eta;&sigmaf;).
          &Gamma;&epsilon;&nu;&iota;&kappa;ά &alpha;&upsilon;&tau;ό
          &sigma;&eta;&mu;&alpha;ί&nu;&epsilon;&iota; ό&tau;&iota;
          &theta;&alpha;
          &delta;&iota;&alpha;&tau;&eta;&rho;ή&sigma;&omicron;&upsilon;&mu;&epsilon;
          &tau;&alpha; &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &sigma;&alpha;&sigmaf;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha; &gamma;&iota;&alpha;
          ό&sigma;&omicron; &delta;&iota;ά&sigma;&tau;&eta;&mu;&alpha;
          έ&chi;&epsilon;&tau;&epsilon;
          &lambda;&omicron;&gamma;&alpha;&rho;&iota;&alpha;&sigma;&mu;ό
          &sigma;&tau;&eta;&nu;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha; &mu;&alpha;&sigmaf;.
          &Alpha;&nu;&alpha;&phi;&omicron;&rho;&iota;&kappa;ά &mu;&epsilon;
          &tau;&alpha; &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &sigma;&alpha;&sigmaf;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &pi;&omicron;&upsilon;
          &sigma;&chi;&epsilon;&tau;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &mu;&epsilon; &tau;&iota;&sigmaf;
          &pi;&rho;&omicron;&sigma;&phi;&epsilon;&rho;ό&mu;&epsilon;&nu;&epsilon;&sigmaf;
          &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
          &mu;&alpha;&sigmaf; &kappa;&alpha;&iota; &tau;&alpha;
          &sigma;&upsilon;&nu;&alpha;&phi;ή
          &pi;&rho;&omicron;ϊό&nu;&tau;&alpha;,
          &delta;&iota;&alpha;&tau;&eta;&rho;&omicron;ύ&mu;&epsilon;
          &alpha;&upsilon;&tau;ά &tau;&alpha;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha; &gamma;&iota;&alpha;
          &mu;&epsilon;&gamma;&alpha;&lambda;ύ&tau;&epsilon;&rho;&omicron;
          &delta;&iota;ά&sigma;&tau;&eta;&mu;&alpha;
          &pi;&rho;&omicron;&kappa;&epsilon;&iota;&mu;έ&nu;&omicron;&upsilon;
          &nu;&alpha;
          &sigma;&upsilon;&mu;&mu;&omicron;&rho;&phi;&omega;&theta;&omicron;ύ&mu;&epsilon;
          &mu;&epsilon; &tau;&iota;&sigmaf;
          &nu;&omicron;&mu;&iota;&kappa;έ&sigmaf; &mu;&alpha;&sigmaf;
          &upsilon;&pi;&omicron;&chi;&rho;&epsilon;ώ&sigma;&epsilon;&iota;&sigmaf;
          (ό&pi;&omega;&sigmaf; &tau;&eta;
          &phi;&omicron;&rho;&omicron;&lambda;&omicron;&gamma;&iota;&kappa;ή
          &kappa;&alpha;&iota; &epsilon;&mu;&pi;&omicron;&rho;&iota;&kappa;ή
          &nu;&omicron;&mu;&omicron;&theta;&epsilon;&sigma;ί&alpha;
          &kappa;&alpha;&iota; &gamma;&iota;&alpha;
          &lambda;ό&gamma;&omicron;&upsilon;&sigmaf;
          &pi;&alpha;&rho;&omicron;&chi;ή&sigmaf;
          &epsilon;&gamma;&gamma;ύ&eta;&sigma;&eta;&sigmaf;
          ό&pi;&omicron;&upsilon;
          &sigma;&upsilon;&nu;&tau;&rho;έ&chi;&epsilon;&iota;).
          &Sigma;&tau;&omicron; &tau;έ&lambda;&omicron;&sigmaf;
          &alpha;&upsilon;&tau;ή&sigmaf; &tau;&eta;&sigmaf;
          &pi;&epsilon;&rho;&iota;ό&delta;&omicron;&upsilon;
          &delta;&iota;&alpha;&tau;ή&rho;&eta;&sigma;&eta;&sigmaf;, &tau;&alpha;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &sigma;&alpha;&sigmaf; &theta;&alpha;
          &delta;&iota;&alpha;&gamma;&rho;&alpha;&phi;&omicron;ύ&nu;
          &pi;&lambda;ή&rho;&omega;&sigmaf; ή
          &alpha;&nu;ώ&nu;&upsilon;&mu;&alpha;, &gamma;&iota;&alpha;
          &pi;&alpha;&rho;ά&delta;&epsilon;&iota;&gamma;&mu;&alpha;
          &mu;&epsilon; &tau;&eta;
          &sigma;&upsilon;&gamma;&kappa;έ&nu;&tau;&rho;&omega;&sigma;&eta;
          &mu;&epsilon; ά&lambda;&lambda;&alpha;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;, έ&tau;&sigma;&iota;
          ώ&sigma;&tau;&epsilon; &nu;&alpha;
          &mu;&pi;&omicron;&rho;&omicron;ύ&nu; &nu;&alpha;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&eta;&theta;&omicron;ύ&nu;
          &mu;&epsilon; &mu;&eta;
          &alpha;&nu;&alpha;&gamma;&nu;&omega;&rho;ί&sigma;&iota;&mu;&omicron;
          &tau;&rho;ό&pi;&omicron; &gamma;&iota;&alpha;
          &sigma;&tau;&alpha;&tau;&iota;&sigma;&tau;&iota;&kappa;ή
          &alpha;&nu;ά&lambda;&upsilon;&sigma;&eta; &kappa;&alpha;&iota;
          &epsilon;&pi;&iota;&chi;&epsilon;&iota;&rho;&eta;&mu;&alpha;&tau;&iota;&kappa;ό
          &pi;&rho;&omicron;&gamma;&rho;&alpha;&mu;&mu;&alpha;&tau;&iota;&sigma;&mu;ό.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Mu;&epsilon;&rho;&iota;&kappa;ά
          &pi;&alpha;&rho;&alpha;&delta;&epsilon;ί&gamma;&mu;&alpha;&tau;&alpha;
          &pi;&epsilon;&rho;&iota;ό&delta;&omega;&nu;
          &delta;&iota;&alpha;&tau;ή&rho;&eta;&sigma;&eta;&sigmaf;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &pi;&epsilon;&lambda;&alpha;&tau;ώ&nu;:</span
        >
      </p>
      <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&Pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&epsilon;&sigmaf;</span
            >
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >Ό&tau;&alpha;&nu;
          &tau;&omicron;&pi;&omicron;&theta;&epsilon;&tau;&epsilon;ί&tau;&epsilon;
          &mu;&iota;&alpha;
          &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;,
          &theta;&alpha;
          &delta;&iota;&alpha;&tau;&eta;&rho;ή&sigma;&omicron;&upsilon;&mu;&epsilon;
          &tau;&alpha; &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &pi;&omicron;&upsilon; &mu;&alpha;&sigmaf;
          &delta;ώ&sigma;&alpha;&tau;&epsilon; &gamma;&iota;&alpha;
          &pi;έ&nu;&tau;&epsilon; &chi;&rho;ό&nu;&iota;&alpha;,
          ώ&sigma;&tau;&epsilon; &nu;&alpha;
          &mu;&pi;&omicron;&rho;έ&sigma;&omicron;&upsilon;&mu;&epsilon;
          &nu;&alpha;
          &sigma;&upsilon;&mu;&mu;&omicron;&rho;&phi;&omega;&theta;&omicron;ύ&mu;&epsilon;
          &mu;&epsilon; &tau;&iota;&sigmaf;
          &nu;&omicron;&mu;&iota;&kappa;έ&sigmaf; &kappa;&alpha;&iota;
          &sigma;&upsilon;&mu;&beta;&alpha;&tau;&iota;&kappa;έ&sigmaf;
          &upsilon;&pi;&omicron;&chi;&rho;&epsilon;ώ&sigma;&epsilon;&iota;&sigmaf;
          &mu;&alpha;&sigmaf;.</span
        >
      </p>
      <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&Epsilon;&gamma;&gamma;&upsilon;ή&sigma;&epsilon;&iota;&sigmaf;</span
            >
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Epsilon;ά&nu; &eta;
          &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;
          &sigma;&alpha;&sigmaf;
          &pi;&epsilon;&rho;&iota;&lambda;ά&mu;&beta;&alpha;&nu;&epsilon;
          &epsilon;&gamma;&gamma;ύ&eta;&sigma;&eta;, &tau;&alpha;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha; &theta;&alpha;
          &delta;&iota;&alpha;&tau;&eta;&rho;&eta;&theta;&omicron;ύ&nu;
          &mu;έ&chi;&rho;&iota; &tau;&omicron; &tau;έ&lambda;&omicron;&sigmaf;
          &tau;&eta;&sigmaf; &pi;&epsilon;&rho;&iota;ό&delta;&omicron;&upsilon;
          &epsilon;&gamma;&gamma;ύ&eta;&sigma;&eta;&sigmaf;.</span
        >
      </p>
      <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >Newsletter</span
            >
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Eta; &delta;ή&lambda;&omega;&sigma;&eta;
          &sigma;&upsilon;&gamma;&kappa;&alpha;&tau;ά&theta;&epsilon;&sigma;ή&sigmaf;
          &sigma;&alpha;&sigmaf; &gamma;&iota;&alpha;
          &alpha;&pi;&omicron;&sigma;&tau;&omicron;&lambda;ή
          &epsilon;&nu;&eta;&mu;&epsilon;&rho;&omega;&tau;&iota;&kappa;&omicron;ύ
          &delta;&epsilon;&lambda;&tau;ί&omicron;&upsilon; (newsletter)
          &tau;&eta;&rho;&epsilon;ί&tau;&alpha;&iota; &gamma;&iota;&alpha;
          ό&sigma;&omicron; &chi;&rho;ό&nu;&omicron; &sigma;&alpha;&sigmaf;
          &alpha;&pi;&omicron;&sigma;&tau;έ&lambda;&lambda;&epsilon;&tau;&alpha;&iota;
          newsletter &alpha;&pi;ό &tau;&eta;&nu;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha; &kappa;&alpha;&iota;
          &pi;ά&nu;&tau;&omega;&sigmaf; ό&chi;&iota;
          &pi;&epsilon;&rho;&iota;&sigma;&sigma;ό&tau;&epsilon;&rho;&omicron;
          &alpha;&pi;ό έ&xi;&iota; &mu;ή&nu;&epsilon;&sigmaf; &alpha;&pi;ό
          &tau;&eta; &delta;&iota;&alpha;&kappa;&omicron;&pi;ή
          &alpha;&pi;&omicron;&sigma;&tau;&omicron;&lambda;ή&sigmaf;
          &tau;&omicron;&upsilon;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >13. &Epsilon;ί&nu;&alpha;&iota; &alpha;&sigma;&phi;&alpha;&lambda;ή
          &tau;&alpha; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &sigma;&alpha;&sigmaf;;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Delta;&epsilon;&sigma;&mu;&epsilon;&upsilon;ό&mu;&alpha;&sigma;&tau;&epsilon;
          &nu;&alpha;
          &delta;&iota;&alpha;&phi;&upsilon;&lambda;ά&sigma;&sigma;&omicron;&upsilon;&mu;&epsilon;
          &tau;&alpha; &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &sigma;&alpha;&sigmaf;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Alpha;&nu;&alpha;&gamma;&nu;&omega;&rho;ί&zeta;&omicron;&nu;&tau;&alpha;&sigmaf;
          &tau;&eta; &sigma;&eta;&mu;&alpha;&sigma;ί&alpha; &tau;&eta;&sigmaf;
          &alpha;&sigma;&phi;ά&lambda;&epsilon;&iota;&alpha;&sigmaf;
          &tau;&omega;&nu;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ώ&nu;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&alpha;&sigmaf;, έ&chi;&omicron;&upsilon;&mu;&epsilon;
          &lambda;ά&beta;&epsilon;&iota; ό&lambda;&alpha; &tau;&alpha;
          &kappa;&alpha;&tau;ά&lambda;&lambda;&eta;&lambda;&alpha;
          &omicron;&rho;&gamma;&alpha;&nu;&omega;&tau;&iota;&kappa;ά
          &kappa;&alpha;&iota; &tau;&epsilon;&chi;&nu;&iota;&kappa;ά
          &mu;έ&tau;&rho;&alpha; &gamma;&iota;&alpha; &tau;&eta;&nu;
          &alpha;&sigma;&phi;ά&lambda;&epsilon;&iota;&alpha;
          &kappa;&alpha;&iota; &tau;&eta;&nu;
          &pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha; &tau;&omega;&nu;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&alpha;&sigmaf; &alpha;&pi;ό &kappa;ά&theta;&epsilon;
          &mu;&omicron;&rho;&phi;ή&sigmaf; &tau;&upsilon;&chi;&alpha;ί&alpha; ή
          &alpha;&theta;έ&mu;&iota;&tau;&eta;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;.
          &Chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&mu;&epsilon;
          &tau;&iota;&sigmaf; &pi;&iota;&omicron;
          &sigma;ύ&gamma;&chi;&rho;&omicron;&nu;&epsilon;&sigmaf;
          &kappa;&alpha;&iota;
          &pi;&rho;&omicron;&eta;&gamma;&mu;έ&nu;&epsilon;&sigmaf;
          &mu;&epsilon;&theta;ό&delta;&omicron;&upsilon;&sigmaf;,
          ώ&sigma;&tau;&epsilon; &nu;&alpha;
          &epsilon;&xi;&alpha;&sigma;&phi;&alpha;&lambda;ί&zeta;&epsilon;&tau;&alpha;&iota;
          &eta; &mu;έ&gamma;&iota;&sigma;&tau;&eta;
          &delta;&upsilon;&nu;&alpha;&tau;ή
          &alpha;&sigma;&phi;ά&lambda;&epsilon;&iota;&alpha;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Omicron; &iota;&sigma;&tau;ό&tau;&omicron;&pi;ό&sigmaf;
          &mu;&alpha;&sigmaf;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&epsilon;ί
          &tau;&alpha; &pi;&iota;&omicron;
          &sigma;ύ&gamma;&chi;&rho;&omicron;&nu;&alpha; &kappa;&alpha;&iota;
          &alpha;&sigma;&phi;&alpha;&lambda;ή
          &pi;&rho;&omega;&tau;ό&kappa;&omicron;&lambda;&lambda;&alpha;
          &mu;&epsilon; &sigma;&kappa;&omicron;&pi;ό &tau;&eta;&nu;
          &delta;&iota;&alpha;&sigma;&phi;ά&lambda;&iota;&sigma;&eta;
          &tau;&omega;&nu; online
          &epsilon;&mu;&pi;&omicron;&rho;&iota;&kappa;ώ&nu;
          &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;ώ&nu;.
          &Mu;&epsilon; &alpha;&upsilon;&tau;ό&nu; &tau;&omicron;&nu;
          &tau;&rho;ό&pi;&omicron;
          &kappa;&rho;&upsilon;&pi;&tau;&omicron;&gamma;&rho;&alpha;&phi;&omicron;ύ&nu;&tau;&alpha;&iota;
          ό&lambda;&alpha; &tau;&alpha;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &pi;&omicron;&upsilon; &pi;&alpha;&rho;έ&chi;&epsilon;&tau;&epsilon;,
          &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;&alpha;&nu;&omicron;&mu;έ&nu;&omicron;&upsilon;
          &tau;&omicron;&upsilon; &alpha;&rho;&iota;&theta;&mu;&omicron;ύ
          &tau;&eta;&sigmaf;
          &pi;&iota;&sigma;&tau;&omega;&tau;&iota;&kappa;ή&sigmaf;
          &sigma;&alpha;&sigmaf; &kappa;ά&rho;&tau;&alpha;&sigmaf;,
          &tau;&omicron;&upsilon;
          &omicron;&nu;ό&mu;&alpha;&tau;&omicron;&sigmaf; &kappa;&alpha;&iota;
          &tau;&eta;&sigmaf;
          &delta;&iota;&epsilon;ύ&theta;&upsilon;&nu;&sigma;&eta;&sigmaf;
          &sigma;&alpha;&sigmaf;, έ&tau;&sigma;&iota; ώ&sigma;&tau;&epsilon;
          &nu;&alpha; &mu;&eta;&nu; &mu;&pi;&omicron;&rho;&omicron;ύ&nu;
          &nu;&alpha;
          &alpha;&pi;&omicron;&kappa;&rho;&upsilon;&pi;&tau;&omicron;&gamma;&rho;&alpha;&phi;&eta;&theta;&omicron;ύ&nu;
          ή &nu;&alpha; &alpha;&lambda;&lambda;&alpha;&chi;&theta;&omicron;ύ&nu;
          &kappa;&alpha;&tau;ά &tau;&eta;
          &mu;&epsilon;&tau;&alpha;&phi;&omicron;&rho;ά
          &tau;&omicron;&upsilon;&sigmaf; &sigma;&tau;&omicron; Internet.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Epsilon;&pi;&iota;&pi;&rho;ό&sigma;&theta;&epsilon;&tau;&alpha;,
          &tau;&alpha; &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha;
          &pi;&omicron;&upsilon;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;&tau;&alpha;&iota;
          &gamma;&iota;&alpha; &tau;&eta;&nu;
          &alpha;&nu;&alpha;&gamma;&nu;ώ&rho;&iota;&sigma;ή
          &sigma;&alpha;&sigmaf; &omega;&sigmaf; &chi;&rho;ή&sigma;&tau;&eta;
          &lambda;&omicron;&gamma;&alpha;&rho;&iota;&alpha;&sigma;&mu;&omicron;ύ
          &epsilon;ί&nu;&alpha;&iota; &delta;ύ&omicron;:&nbsp;&omicron;
          &Kappa;&omega;&delta;&iota;&kappa;ό&sigmaf;
          &Epsilon;&iota;&sigma;ό&delta;&omicron;&upsilon; (Username)
          &kappa;&alpha;&iota; &omicron;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ό&sigmaf;
          &Mu;&upsilon;&sigma;&tau;&iota;&kappa;ό&sigmaf;
          &Kappa;&omega;&delta;&iota;&kappa;ό&sigmaf;
          &Alpha;&sigma;&phi;&alpha;&lambda;&epsilon;ί&alpha;&sigmaf;
          (Password).&nbsp;&Kappa;ά&theta;&epsilon; &phi;&omicron;&rho;ά
          &pi;&omicron;&upsilon;
          &kappa;&alpha;&tau;&alpha;&chi;&omega;&rho;&epsilon;ί&tau;&epsilon;
          &tau;&alpha; &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha;
          &sigma;&alpha;&sigmaf;, &sigma;&alpha;&sigmaf;
          &pi;&alpha;&rho;έ&chi;&epsilon;&tau;&alpha;&iota;
          &pi;&rho;ό&sigma;&beta;&alpha;&sigma;&eta; &sigma;&tau;&omicron;&nu;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ό
          &sigma;&alpha;&sigmaf;
          &lambda;&omicron;&gamma;&alpha;&rho;&iota;&alpha;&sigma;&mu;ό. &Eta;
          &sigma;&upsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&eta;
          &delta;&iota;&alpha;&delta;&iota;&kappa;&alpha;&sigma;ί&alpha;
          &epsilon;&pi;&iota;&tau;&upsilon;&gamma;&chi;ά&nu;&epsilon;&tau;&alpha;&iota;
          &mu;&epsilon; &alpha;&sigma;&phi;ά&lambda;&epsilon;&iota;&alpha;
          &mu;έ&sigma;&omega; &tau;&eta;&sigmaf;
          &kappa;&rho;&upsilon;&pi;&tau;&omicron;&gamma;&rho;ά&phi;&eta;&sigma;&eta;&sigmaf;
          &kappa;&alpha;&tau;ά &tau;&eta;
          &mu;&epsilon;&tau;&alpha;&phi;&omicron;&rho;ά
          &tau;&omicron;&upsilon;&sigmaf; &sigma;&tau;&omicron;
          &delta;&iota;&alpha;&delta;ί&kappa;&tau;&upsilon;&omicron;
          &kappa;&alpha;&iota; &tau;&omicron;&upsilon;&sigmaf;
          &epsilon;&xi;&upsilon;&pi;&eta;&rho;&epsilon;&tau;&eta;&tau;έ&sigmaf;
          (servers) &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;.
          &Kappa;&alpha;&tau;ά &tau;&alpha; ί&delta;&iota;&alpha;
          &pi;&rho;ό&tau;&upsilon;&pi;&alpha;, &sigma;&alpha;&sigmaf;
          &delta;ί&delta;&epsilon;&tau;&alpha;&iota; &eta;
          &delta;&upsilon;&nu;&alpha;&tau;ό&tau;&eta;&tau;&alpha; &nu;&alpha;
          &mu;&epsilon;&tau;&alpha;&beta;ά&lambda;&lambda;&epsilon;&tau;&epsilon;
          &tau;&omicron;&nu; &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ό
          &Mu;&upsilon;&sigma;&tau;&iota;&kappa;ό
          &Kappa;&omega;&delta;&iota;&kappa;ό
          &Alpha;&sigma;&phi;&alpha;&lambda;&epsilon;ί&alpha;&sigmaf;
          &sigma;&alpha;&sigmaf; (Password) ό&sigma;&omicron;
          &sigma;&upsilon;&chi;&nu;ά &epsilon;&sigma;&epsilon;ί&sigmaf;
          &epsilon;&pi;&iota;&theta;&upsilon;&mu;&epsilon;ί&tau;&epsilon;.
          &Mu;&epsilon;&tau;ά &tau;&eta;&nu;
          &kappa;&alpha;&tau;&alpha;&chi;ώ&rho;&eta;&sigma;&eta;
          &tau;&omicron;&upsilon;
          &epsilon;&pi;&iota;&theta;&upsilon;&mu;&eta;&tau;&omicron;ύ
          &kappa;&omega;&delta;&iota;&kappa;&omicron;ύ, &omicron;
          &nu;έ&omicron;&sigmaf; &kappa;&omega;&delta;&iota;&kappa;ό&sigmaf;
          &kappa;&omega;&delta;&iota;&kappa;&omicron;&pi;&omicron;&iota;&epsilon;ί&tau;&alpha;&iota;
          &kappa;&alpha;&iota;
          &alpha;&pi;&omicron;&theta;&eta;&kappa;&epsilon;ύ&epsilon;&tau;&alpha;&iota;
          &sigma;&tau;&alpha;
          &sigma;&upsilon;&sigma;&tau;ή&mu;&alpha;&tau;&alpha;
          &tau;&eta;&sigmaf;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;.
          &Gamma;&iota;&alpha; &tau;&omicron;&nu; &lambda;ό&gamma;&omicron;
          &alpha;&upsilon;&tau;ό,&nbsp;&omicron; &mu;ό&nu;&omicron;&sigmaf;
          &pi;&omicron;&upsilon; &gamma;&nu;&omega;&rho;ί&zeta;&epsilon;&iota;
          &tau;&omicron;&nu; &kappa;&omega;&delta;&iota;&kappa;ό
          &sigma;&alpha;&sigmaf; &epsilon;ί&sigma;&tau;&epsilon; &omicron;
          ί&delta;&iota;&omicron;&sigmaf; &kappa;&alpha;&iota;
          &epsilon;ί&sigma;&tau;&epsilon;
          &alpha;&pi;&omicron;&kappa;&lambda;&epsilon;&iota;&sigma;&tau;&iota;&kappa;ά
          &upsilon;&pi;&epsilon;ύ&theta;&upsilon;&nu;&omicron;&sigmaf;
          &gamma;&iota;&alpha; &tau;&eta;
          &delta;&iota;&alpha;&tau;ή&rho;&eta;&sigma;&eta; &tau;&eta;&sigmaf;
          &mu;&upsilon;&sigma;&tau;&iota;&kappa;ό&tau;&eta;&tau;&alpha;&sigmaf;
          &tau;&omicron;&upsilon; &kappa;&omega;&delta;&iota;&kappa;&omicron;ύ
          &alpha;&pi;ό &tau;&rho;ί&tau;&alpha;
          &pi;&rho;ό&sigma;&omega;&pi;&alpha;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Tau;&alpha; &epsilon;&nu; &lambda;ό&gamma;&omega;
          &mu;έ&tau;&rho;&alpha;
          &epsilon;&pi;&alpha;&nu;&epsilon;&xi;&epsilon;&tau;ά&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &kappa;&alpha;&iota;
          &tau;&rho;&omicron;&pi;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;&tau;&alpha;&iota;
          ό&tau;&alpha;&nu; &kappa;&rho;ί&nu;&epsilon;&tau;&alpha;&iota;
          &alpha;&pi;&alpha;&rho;&alpha;ί&tau;&eta;&tau;&omicron;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >14. &Pi;&omicron;&iota;&alpha; &epsilon;ί&nu;&alpha;&iota;
          &tau;&alpha; &delta;&iota;&kappa;&alpha;&iota;ώ&mu;&alpha;&tau;ά
          &sigma;&alpha;&sigmaf;;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >Έ&chi;&epsilon;&tau;&epsilon;
          &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha;
          &pi;&rho;ό&sigma;&beta;&alpha;&sigma;&eta;&sigmaf; &sigma;&tau;&alpha;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &sigma;&alpha;&sigmaf;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Alpha;&upsilon;&tau;ό &sigma;&eta;&mu;&alpha;ί&nu;&epsilon;&iota;
          ό&tau;&iota; έ&chi;&epsilon;&tau;&epsilon; &tau;&omicron;
          &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha; &nu;&alpha;
          &epsilon;&nu;&eta;&mu;&epsilon;&rho;&omega;&theta;&epsilon;ί&tau;&epsilon;
          &alpha;&pi;ό &epsilon;&mu;ά&sigmaf; &epsilon;ά&nu;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&zeta;ό&mu;&alpha;&sigma;&tau;&epsilon;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &sigma;&alpha;&sigmaf;. &Alpha;&nu;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&zeta;ό&mu;&alpha;&sigma;&tau;&epsilon;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &sigma;&alpha;&sigmaf; &mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon;
          &nu;&alpha; &zeta;&eta;&tau;ή&sigma;&epsilon;&tau;&epsilon;
          &nu;&alpha;
          &epsilon;&nu;&eta;&mu;&epsilon;&rho;&omega;&theta;&epsilon;ί&tau;&epsilon;
          &gamma;&iota;&alpha; &tau;&omicron;&nu; &sigma;&kappa;&omicron;&pi;ό
          &tau;&eta;&sigmaf;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;&sigmaf;,
          &tau;&omicron; &epsilon;ί&delta;&omicron;&sigmaf; &tau;&omega;&nu;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&alpha;&sigmaf; &pi;&omicron;&upsilon;
          &tau;&eta;&rho;&omicron;ύ&mu;&epsilon;, &sigma;&epsilon;
          &pi;&omicron;&iota;&omicron;&upsilon;&sigmaf; &tau;&alpha;
          &delta;ί&nu;&omicron;&upsilon;&mu;&epsilon;, &pi;ό&sigma;&omicron;
          &delta;&iota;ά&sigma;&tau;&eta;&mu;&alpha; &tau;&alpha;
          &alpha;&pi;&omicron;&theta;&eta;&kappa;&epsilon;ύ&omicron;&upsilon;&mu;&epsilon;,
          &alpha;&nu; &gamma;ί&nu;&epsilon;&tau;&alpha;&iota;
          &alpha;&upsilon;&tau;&omicron;&mu;&alpha;&tau;&omicron;&pi;&omicron;&iota;&eta;&mu;έ&nu;&eta;
          &lambda;ή&psi;&eta;
          &alpha;&pi;&omicron;&phi;ά&sigma;&epsilon;&omega;&nu;,
          &alpha;&lambda;&lambda;ά &kappa;&alpha;&iota; &gamma;&iota;&alpha;
          &tau;&alpha; &lambda;&omicron;&iota;&pi;ά
          &delta;&iota;&kappa;&alpha;&iota;ώ&mu;&alpha;&tau;&alpha;
          &sigma;&alpha;&sigmaf;, ό&pi;&omega;&sigmaf;
          &delta;&iota;ό&rho;&theta;&omega;&sigma;&eta;&sigmaf;,
          &delta;&iota;&alpha;&gamma;&rho;&alpha;&phi;ή&sigmaf;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;,
          &pi;&epsilon;&rho;&iota;&omicron;&rho;&iota;&sigma;&mu;&omicron;ύ
          &tau;&eta;&sigmaf;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;&sigmaf;
          &kappa;&alpha;&iota;
          &upsilon;&pi;&omicron;&beta;&omicron;&lambda;ή&sigmaf;
          &kappa;&alpha;&tau;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;
          &sigma;&tau;&eta;&nu; &Alpha;&rho;&chi;ή
          &Pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;&sigmaf;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ώ&nu;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >Έ&chi;&epsilon;&tau;&epsilon;
          &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha;
          &delta;&iota;ό&rho;&theta;&omega;&sigma;&eta;&sigmaf;
          &alpha;&nu;&alpha;&kappa;&rho;&iota;&beta;ώ&nu;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;&omicron;ύ
          &chi;&alpha;&rho;&alpha;&kappa;&tau;ή&rho;&alpha;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Alpha;&nu;
          &delta;&iota;&alpha;&pi;&iota;&sigma;&tau;ώ&sigma;&epsilon;&tau;&epsilon;
          ό&tau;&iota; &upsilon;&phi;ί&sigma;&tau;&alpha;&tau;&alpha;&iota;
          &lambda;ά&theta;&omicron;&sigmaf; &sigma;&tau;&alpha;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &sigma;&alpha;&sigmaf; &mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon;
          &nu;&alpha; &mu;&alpha;&sigmaf;
          &upsilon;&pi;&omicron;&beta;ά&lambda;&lambda;&epsilon;&tau;&epsilon;
          &alpha;ί&tau;&eta;&sigma;&eta; &gamma;&iota;&alpha; &nu;&alpha;
          &tau;&alpha;
          &delta;&iota;&omicron;&rho;&theta;ώ&sigma;&omicron;&upsilon;&mu;&epsilon;
          (&pi;.&chi;. &delta;&iota;ό&rho;&theta;&omega;&sigma;&eta;
          &omicron;&nu;ό&mu;&alpha;&tau;&omicron;&sigmaf; ή
          &epsilon;&nu;&eta;&mu;έ&rho;&omega;&sigma;&eta;
          &alpha;&lambda;&lambda;&alpha;&gamma;ή&sigmaf;
          &delta;&iota;&epsilon;ύ&theta;&upsilon;&nu;&sigma;&eta;&sigmaf;).</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >Έ&chi;&epsilon;&tau;&epsilon;
          &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha;
          &delta;&iota;&alpha;&gamma;&rho;&alpha;&phi;ή&sigmaf;/&delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha;
          &sigma;&tau;&eta; &lambda;ή&theta;&eta;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon; &nu;&alpha;
          &mu;&alpha;&sigmaf; &zeta;&eta;&tau;ή&sigma;&epsilon;&tau;&epsilon;
          &nu;&alpha;
          &delta;&iota;&alpha;&gamma;&rho;ά&psi;&omicron;&upsilon;&mu;&epsilon;
          &tau;&alpha; &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &sigma;&alpha;&sigmaf; &alpha;&nu; &delta;&epsilon;&nu;
          &epsilon;ί&nu;&alpha;&iota;
          &alpha;&pi;&alpha;&rho;&alpha;ί&tau;&eta;&tau;&alpha;
          &pi;&lambda;έ&omicron;&nu; &gamma;&iota;&alpha;
          &tau;&omicron;&upsilon;&sigmaf; &omega;&sigmaf; ά&nu;&omega;
          &alpha;&nu;&alpha;&phi;&epsilon;&rho;ό&mu;&epsilon;&nu;&omicron;&upsilon;&sigmaf;
          &sigma;&kappa;&omicron;&pi;&omicron;ύ&sigmaf;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;&sigmaf;
          ή &epsilon;&pi;&iota;&theta;&upsilon;&mu;&epsilon;ί&tau;&epsilon;
          &nu;&alpha;
          &alpha;&nu;&alpha;&kappa;&alpha;&lambda;έ&sigma;&epsilon;&tau;&epsilon;
          &tau;&eta; ή &sigma;&alpha;&sigmaf; &sigma;&tau;&eta;&nu;
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta; &pi;&omicron;&upsilon;
          &alpha;&upsilon;&tau;ή &epsilon;ί&nu;&alpha;&iota; &eta;
          &mu;ό&nu;&eta; &nu;ό&mu;&iota;&mu;&eta; &beta;ά&sigma;&eta;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >Έ&chi;&epsilon;&tau;&epsilon;
          &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha;
          &phi;&omicron;&rho;&eta;&tau;ό&tau;&eta;&tau;&alpha;&sigmaf;
          &tau;&omega;&nu; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&alpha;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon; &nu;&alpha;
          &mu;&alpha;&sigmaf; &zeta;&eta;&tau;ή&sigma;&epsilon;&tau;&epsilon;
          &nu;&alpha; &lambda;ά&beta;&epsilon;&tau;&epsilon; &sigma;&epsilon;
          &alpha;&nu;&alpha;&gamma;&nu;ώ&sigma;&iota;&mu;&eta;
          &mu;&omicron;&rho;&phi;ή &tau;&alpha;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &pi;&omicron;&upsilon; έ&chi;&epsilon;&tau;&epsilon;
          &pi;&alpha;&rho;ά&sigma;&chi;&epsilon;&iota; ή &nu;&alpha;
          &mu;&alpha;&sigmaf; &zeta;&eta;&tau;ή&sigma;&epsilon;&tau;&epsilon;
          &nu;&alpha; &tau;&alpha;
          &delta;&iota;&alpha;&beta;&iota;&beta;ά&sigma;&omicron;&upsilon;&mu;&epsilon;
          &sigma;&epsilon; ά&lambda;&lambda;&omicron;
          &upsilon;&pi;&epsilon;ύ&theta;&upsilon;&nu;&omicron;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >Έ&chi;&epsilon;&tau;&epsilon;
          &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha;
          &pi;&epsilon;&rho;&iota;&omicron;&rho;&iota;&sigma;&mu;&omicron;ύ
          &tau;&eta;&sigmaf;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon; &nu;&alpha;
          &mu;&alpha;&sigmaf; &zeta;&eta;&tau;ή&sigma;&epsilon;&tau;&epsilon;
          &nu;&alpha;
          &pi;&epsilon;&rho;&iota;&omicron;&rho;ί&sigma;&omicron;&upsilon;&mu;&epsilon;
          &tau;&eta;&nu;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
          &tau;&omega;&nu; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&alpha;&sigmaf; &gamma;&iota;&alpha; ό&sigma;&omicron;
          &chi;&rho;ό&nu;&omicron;
          &epsilon;&kappa;&kappa;&rho;&epsilon;&mu;&epsilon;ί &eta;
          &epsilon;&xi;έ&tau;&alpha;&sigma;&eta; &tau;&omega;&nu;
          &alpha;&nu;&tau;&iota;&rho;&rho;ή&sigma;&epsilon;ώ&nu;
          &sigma;&alpha;&sigmaf; &omega;&sigmaf; &pi;&rho;&omicron;&sigmaf;
          &tau;&eta;&nu;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >Έ&chi;&epsilon;&tau;&epsilon;
          &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha;
          &epsilon;&nu;&alpha;&nu;&tau;ί&omega;&sigma;&eta;&sigmaf;
          &kappa;&alpha;&iota;
          &alpha;&nu;ά&kappa;&lambda;&eta;&sigma;&eta;&sigmaf;
          &sigma;&upsilon;&gamma;&kappa;&alpha;&tau;ά&theta;&epsilon;&sigma;&eta;&sigmaf;
          &sigma;&tau;&eta;&nu;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
          &tau;&omega;&nu; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&alpha;&sigmaf;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon; &nu;&alpha;
          &alpha;&nu;&tau;&iota;&tau;&alpha;&chi;&theta;&epsilon;ί&tau;&epsilon;
          &sigma;&tau;&eta;&nu;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
          &tau;&omega;&nu; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&alpha;&sigmaf; &kappa;&alpha;&iota;
          &epsilon;&mu;&epsilon;ί&sigmaf; &theta;&alpha;
          &sigma;&tau;&alpha;&mu;&alpha;&tau;ή&sigma;&omicron;&upsilon;&mu;&epsilon;
          &tau;&eta;&nu;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
          &tau;&omega;&nu; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&alpha;&sigmaf;, &alpha;&nu; &delta;&epsilon;&nu;
          &upsilon;&phi;ί&sigma;&tau;&alpha;&nu;&tau;&alpha;&iota;
          ά&lambda;&lambda;&omicron;&iota;
          &epsilon;&pi;&iota;&tau;&alpha;&kappa;&tau;&iota;&kappa;&omicron;ί
          &kappa;&alpha;&iota; &nu;ό&mu;&iota;&mu;&omicron;&iota;
          &lambda;ό&gamma;&omicron;&iota; &pi;&omicron;&upsilon;
          &upsilon;&pi;&epsilon;&rho;&iota;&sigma;&chi;ύ&omicron;&upsilon;&nu;
          έ&nu;&alpha;&nu;&tau;&iota; &tau;&omicron;&upsilon;
          &delta;&iota;&kappa;&alpha;&iota;ώ&mu;&alpha;&tau;ό&sigmaf;
          &sigma;&alpha;&sigmaf;. &Epsilon;ά&nu; έ&chi;&epsilon;&tau;&epsilon;
          &delta;&eta;&lambda;ώ&sigma;&epsilon;&iota; &tau;&eta;
          &sigma;&upsilon;&gamma;&kappa;&alpha;&tau;ά&theta;&epsilon;&sigma;ή
          &sigma;&alpha;&sigmaf; &gamma;&iota;&alpha; &tau;&eta;
          &sigma;&upsilon;&lambda;&lambda;&omicron;&gamma;ή,
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
          &kappa;&alpha;&iota; &chi;&rho;ή&sigma;&eta; &tau;&omega;&nu;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&alpha;&sigmaf;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;&omicron;ύ
          &chi;&alpha;&rho;&alpha;&kappa;&tau;ή&rho;&alpha;,
          &mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon; &nu;&alpha;
          &alpha;&nu;&alpha;&kappa;&alpha;&lambda;έ&sigma;&epsilon;&tau;&epsilon;
          &tau;&eta;
          &sigma;&upsilon;&gamma;&kappa;&alpha;&tau;ά&theta;&epsilon;&sigma;ή
          &sigma;&alpha;&sigmaf; &alpha;&nu;ά &pi;ά&sigma;&alpha;
          &sigma;&tau;&iota;&gamma;&mu;ή &mu;&epsilon;
          &mu;&epsilon;&lambda;&lambda;&omicron;&nu;&tau;&iota;&kappa;ή
          &iota;&sigma;&chi;ύ:</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >●&Epsilon;&pi;&iota;&lambda;έ&gamma;&omicron;&nu;&tau;&alpha;&sigmaf;&nbsp;&nu;&alpha;
          &mu;&eta; &lambda;&alpha;&mu;&beta;ά&nu;&epsilon;&tau;&epsilon;
          &Epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ί&epsilon;&sigmaf;
          Marketing.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon; &nu;&alpha;
          &epsilon;&pi;&iota;&lambda;έ&xi;&epsilon;&tau;&epsilon; &nu;&alpha;
          &mu;&eta; &lambda;&alpha;&mu;&beta;ά&nu;&epsilon;&tau;&epsilon;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ί&epsilon;&sigmaf;
          marketing
          &alpha;&lambda;&lambda;ά&zeta;&omicron;&nu;&tau;&alpha;&sigmaf;
          &tau;&iota;&sigmaf; &epsilon;&gamma;&gamma;&rho;&alpha;&phi;έ&sigmaf;
          email &kappa;&alpha;&iota; sms
          &pi;&alpha;&tau;ώ&nu;&tau;&alpha;&sigmaf; &tau;&omicron; link
          &delta;&iota;&alpha;&gamma;&rho;&alpha;&phi;ή ή
          &alpha;&kappa;&omicron;&lambda;&omicron;&upsilon;&theta;ώ&nu;&tau;&alpha;&sigmaf;
          &tau;&iota;&sigmaf; &omicron;&delta;&eta;&gamma;ί&epsilon;&sigmaf;
          &pi;&omicron;&upsilon;
          &pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;ά&nu;&omicron;&nu;&tau;&alpha;&iota;
          &sigma;&tau;&omicron; &mu;ή&nu;&upsilon;&mu;&alpha;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >
          &nbsp;&Epsilon;&nu;&alpha;&lambda;&lambda;&alpha;&kappa;&tau;&iota;&kappa;ά
          &mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon; &nu;&alpha;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ή&sigma;&epsilon;&tau;&epsilon;
          &mu;&alpha;&zeta;ί &mu;&alpha;&sigmaf;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;ώ&nu;&tau;&alpha;&sigmaf;
          &tau;&alpha; &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ί&alpha;&sigmaf;
          &pi;&omicron;&upsilon; &sigma;&alpha;&sigmaf;
          &delta;ί&nu;&omicron;&upsilon;&mu;&epsilon; &sigma;&tau;&omicron;&nu;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;ό ό&rho;&omicron;
          &pi;&alpha;&rho;&alpha;&kappa;ά&tau;&omega;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Sigma;&epsilon; &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
          &pi;&omicron;&upsilon;
          &beta;&alpha;&sigma;&iota;&zeta;ό&mu;&alpha;&sigma;&tau;&epsilon;
          &sigma;&tau;&omicron;&nbsp;&nu;ό&mu;&iota;&mu;&omicron;
          &sigma;&upsilon;&mu;&phi;έ&rho;&omicron;&nu;
          &mu;&alpha;&sigmaf;:&nbsp;&Sigma;&epsilon;
          &pi;&epsilon;&rho;&iota;&pi;&tau;ώ&sigma;&epsilon;&iota;&sigmaf;
          ό&pi;&omicron;&upsilon;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&zeta;ό&mu;&alpha;&sigma;&tau;&epsilon;
          &tau;&alpha; &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &sigma;&alpha;&sigmaf;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha; &mu;&epsilon;
          &beta;ά&sigma;&eta; &tau;&omicron; &nu;ό&mu;&iota;&mu;&omicron;
          &sigma;&upsilon;&mu;&phi;έ&rho;&omicron;&nu; &mu;&alpha;&sigmaf;,
          &mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon; &nu;&alpha;
          &mu;&alpha;&sigmaf; &zeta;&eta;&tau;ή&sigma;&epsilon;&tau;&epsilon;
          &nu;&alpha;
          &sigma;&tau;&alpha;&mu;&alpha;&tau;ή&sigma;&omicron;&upsilon;&mu;&epsilon;
          &gamma;&iota;&alpha; &lambda;ό&gamma;&omicron;&upsilon;&sigmaf;
          &pi;&omicron;&upsilon;
          &sigma;&chi;&epsilon;&tau;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
          &mu;&epsilon; &tau;&eta;&nu;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ή
          &sigma;&alpha;&sigmaf;
          &kappa;&alpha;&tau;ά&sigma;&tau;&alpha;&sigma;&eta;.
          &Pi;&rho;έ&pi;&epsilon;&iota; &tau;ό&tau;&epsilon; &nu;&alpha;
          &tau;&omicron; &pi;&rho;ά&xi;&omicron;&upsilon;&mu;&epsilon;
          &alpha;&nu; &delta;&epsilon;&nu;
          &pi;&iota;&sigma;&tau;&epsilon;ύ&omicron;&upsilon;&mu;&epsilon;
          ό&tau;&iota; έ&chi;&omicron;&upsilon;&mu;&epsilon;
          &nu;ό&mu;&iota;&mu;&omicron;
          &epsilon;&pi;&iota;&tau;&alpha;&kappa;&tau;&iota;&kappa;ό
          &lambda;ό&gamma;&omicron; &nu;&alpha;
          &sigma;&upsilon;&nu;&epsilon;&chi;ί&sigma;&omicron;&upsilon;&mu;&epsilon;
          &nu;&alpha;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&zeta;ό&mu;&alpha;&sigma;&tau;&epsilon;
          &tau;&alpha; &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &sigma;&alpha;&sigmaf;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >15. &Pi;ώ&sigmaf; &mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon;
          &nu;&alpha; &alpha;&sigma;&kappa;ή&sigma;&epsilon;&tau;&epsilon;
          &tau;&alpha; &delta;&iota;&kappa;&alpha;&iota;ώ&mu;&alpha;&tau;&alpha;
          &sigma;&alpha;&sigmaf;;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Gamma;&iota;&alpha; &nu;&alpha;
          &alpha;&sigma;&kappa;ή&sigma;&epsilon;&tau;&epsilon; &tau;&alpha;
          &delta;&iota;&kappa;&alpha;&iota;ώ&mu;&alpha;&tau;&alpha;
          &sigma;&alpha;&sigmaf; &mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon;
          &nu;&alpha; &mu;&alpha;&sigmaf;
          &upsilon;&pi;&omicron;&beta;ά&lambda;&lambda;&epsilon;&tau;&epsilon;
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;ό &alpha;ί&tau;&eta;&mu;&alpha;
          &sigma;&tau;&eta;&nu;
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ή
          &delta;&iota;&epsilon;ύ&theta;&upsilon;&nu;&sigma;ή
          info@stoferno.gr&nbsp;&mu;&epsilon; &tau;ί&tau;&lambda;&omicron;
          &laquo;Ά&sigma;&kappa;&eta;&sigma;&eta;
          &Delta;&iota;&kappa;&alpha;&iota;ώ&mu;&alpha;&tau;&omicron;&sigmaf;&raquo;
          &kappa;&alpha;&iota; &epsilon;&mu;&epsilon;ί&sigmaf; &theta;&alpha;
          &tau;&omicron;
          &epsilon;&xi;&epsilon;&tau;ά&sigma;&omicron;&upsilon;&mu;&epsilon;
          &kappa;&alpha;&iota; &theta;&alpha; &sigma;&alpha;&sigmaf;
          &alpha;&pi;&alpha;&nu;&tau;ή&sigma;&omicron;&upsilon;&mu;&epsilon;
          &tau;&omicron;
          &sigma;&upsilon;&nu;&tau;&omicron;&mu;ό&tau;&epsilon;&rho;&omicron;
          &delta;&upsilon;&nu;&alpha;&tau;ό.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Kappa;&alpha;&tau;&rsquo;
          &epsilon;&xi;&alpha;ί&rho;&epsilon;&sigma;&eta;:</span
        >
      </p>
      <ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px">
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&alpha;&nu;
              &epsilon;&pi;&iota;&theta;&upsilon;&mu;&epsilon;ί&tau;&epsilon;
              &tau;&eta; &delta;&iota;ό&rho;&theta;&omega;&sigma;&eta;
              &tau;&omega;&nu;
              &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
              &sigma;&alpha;&sigmaf; &sigma;&tau;&omicron;
              &lambda;&omicron;&gamma;&alpha;&rho;&iota;&alpha;&sigma;&mu;ό
              &chi;&rho;ή&sigma;&tau;&eta; &sigma;&alpha;&sigmaf;,
              &mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon; &nu;&alpha;
              &sigma;&upsilon;&nu;&delta;&epsilon;&theta;&epsilon;ί&tau;&epsilon;
              &sigma;&epsilon; &alpha;&upsilon;&tau;ό&nu; &kappa;&alpha;&iota;
              &nu;&alpha;
              &pi;&rho;&alpha;&gamma;&mu;&alpha;&tau;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&tau;&epsilon;
              &omicron;&iota;&alpha;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
              &delta;&iota;ό&rho;&theta;&omega;&sigma;&eta;/
              &mu;&epsilon;&tau;&alpha;&beta;&omicron;&lambda;ή
              &chi;&omega;&rho;ί&sigmaf; &nu;&alpha;
              &alpha;&pi;&alpha;&iota;&tau;&epsilon;ί&tau;&alpha;&iota; &eta;
              &upsilon;&pi;&omicron;&beta;&omicron;&lambda;ή
              &Alpha;&iota;&tau;ή&mu;&alpha;&tau;&omicron;&sigmaf;.</span
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&alpha;&nu;
              &epsilon;&pi;&iota;&theta;&upsilon;&mu;&epsilon;ί&tau;&epsilon;
              &tau;&eta;&nu; &alpha;&nu;ά&kappa;&lambda;&eta;&sigma;&eta;
              &tau;&eta;&sigmaf;
              &sigma;&upsilon;&gamma;&kappa;&alpha;&tau;ά&theta;&epsilon;&sigma;&eta;&sigmaf;
              &sigma;&alpha;&sigmaf; &gamma;&iota;&alpha;
              &alpha;&pi;&omicron;&sigma;&tau;&omicron;&lambda;ή
              &epsilon;&nu;&eta;&mu;&epsilon;&rho;&omega;&tau;&iota;&kappa;&omicron;ύ
              &delta;&epsilon;&lambda;&tau;ί&omicron;&upsilon; (newsletter)
              &mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon; &nu;&alpha;
              &tau;&eta;&nu;
              &pi;&rho;&alpha;&gamma;&mu;&alpha;&tau;&omicron;&pi;&omicron;&iota;&epsilon;ί&tau;&epsilon;
              &mu;&epsilon; &tau;&eta;&nu;
              &epsilon;&pi;&iota;&lambda;&omicron;&gamma;ή
              &tau;&omicron;&upsilon;
              &sigma;&upsilon;&nu;&delta;έ&sigma;&mu;&omicron;&upsilon;
              &laquo;&Gamma;&iota;&alpha;
              &delta;&iota;&alpha;&gamma;&rho;&alpha;&phi;ή &alpha;&pi;ό
              &tau;&eta; &laquo;&lambda;ί&sigma;&tau;&alpha;
              &alpha;&pi;&omicron;&sigma;&tau;&omicron;&lambda;ή&sigmaf;
              newsletter&raquo; &kappa;ά&nu;&tau;&epsilon;
              &kappa;&lambda;&iota;&kappa; &epsilon;&delta;ώ&raquo;
              &pi;&omicron;&upsilon;
              &beta;&rho;ί&sigma;&kappa;&epsilon;&tau;&alpha;&iota;
              &sigma;&tau;&omicron; &kappa;ά&tau;&omega;
              &mu;έ&rho;&omicron;&sigmaf; &kappa;ά&theta;&epsilon;
              newsletter.</span
            >
          </p>
        </li>
        <li
          aria-level="1"
          dir="ltr"
          style="
            list-style-type: disc;
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
          "
        >
          <p
            dir="ltr"
            style="
              line-height: 1.7999999999999998;
              text-align: justify;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 12pt;
                font-family: Arial;
                color: #000000;
                background-color: #ffffff;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >&alpha;&nu;
              &epsilon;&pi;&iota;&theta;&upsilon;&mu;&epsilon;ί&tau;&epsilon;
              &nu;&alpha; &mu;&eta;&nu;
              &lambda;&alpha;&mu;&beta;ά&nu;&epsilon;&tau;&epsilon; web push
              notifications &alpha;&pi;ό &tau;&eta;&nu;
              &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
              &mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon; &nu;&alpha;
              &alpha;&pi;&epsilon;&nu;&epsilon;&rho;&gamma;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&tau;&epsilon;
              &tau;&eta;&nu; &epsilon;&pi;&iota;&lambda;&omicron;&gamma;ή
              &alpha;&pi;ό &tau;&eta; &rho;ύ&theta;&mu;&iota;&sigma;&eta;
              &pi;&epsilon;&rho;&iota;ή&gamma;&eta;&sigma;&eta;&sigmaf;
              (browser) &sigma;&alpha;&sigmaf;.</span
            >
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >Έ&lambda;&epsilon;&gamma;&chi;&omicron;&sigmaf;
          &tau;&alpha;&upsilon;&tau;&omicron;&pi;&rho;&omicron;&sigma;&omega;&pi;ί&alpha;&sigmaf;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Gamma;&iota;&alpha; &nu;&alpha;
          &pi;&rho;&omicron;&sigma;&tau;&alpha;&tau;&epsilon;ύ&sigma;&omicron;&upsilon;&mu;&epsilon;
          &tau;&eta;&nu;
          &epsilon;&mu;&pi;&iota;&sigma;&tau;&epsilon;&upsilon;&tau;&iota;&kappa;ό&tau;&eta;&tau;&alpha;
          &tau;&omega;&nu;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;&iota;ώ&nu;
          &sigma;&alpha;&sigmaf;, &theta;&alpha; &sigma;&alpha;&sigmaf;
          &zeta;&eta;&tau;ή&sigma;&omicron;&upsilon;&mu;&epsilon; &nu;&alpha;
          &epsilon;&pi;&alpha;&lambda;&eta;&theta;&epsilon;ύ&sigma;&epsilon;&tau;&epsilon;
          &tau;&eta;&nu; &tau;&alpha;&upsilon;&tau;ό&tau;&eta;&tau;ά
          &sigma;&alpha;&sigmaf; &pi;&rho;&omicron;&tau;&omicron;ύ
          &pi;&rho;&omicron;&chi;&omega;&rho;ή&sigma;&epsilon;&tau;&epsilon;
          &sigma;&epsilon;
          &omicron;&pi;&omicron;&iota;&omicron;&delta;ή&pi;&omicron;&tau;&epsilon;
          &alpha;ί&tau;&eta;&mu;&alpha;
          &upsilon;&pi;&omicron;&beta;ά&lambda;&epsilon;&tau;&epsilon;
          &mu;&epsilon; &beta;ά&sigma;&eta; &tau;&eta;&nu;
          &pi;&alpha;&rho;&omicron;ύ&sigma;&alpha;
          &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή
          &Alpha;&pi;&omicron;&rho;&rho;ή&tau;&omicron;&upsilon;. &Epsilon;ά&nu;
          έ&chi;&epsilon;&tau;&epsilon;
          &epsilon;&xi;&omicron;&upsilon;&sigma;&iota;&omicron;&delta;&omicron;&tau;ή&sigma;&epsilon;&iota;
          έ&nu;&alpha; &tau;&rho;ί&tau;&omicron; &mu;έ&rho;&omicron;&sigmaf;
          &nu;&alpha; &upsilon;&pi;&omicron;&beta;ά&lambda;&epsilon;&iota;
          &alpha;ί&tau;&eta;&mu;&alpha; &epsilon;&kappa;
          &mu;έ&rho;&omicron;&upsilon;&sigmaf; &sigma;&alpha;&sigmaf;,
          &theta;&alpha; &tau;&omicron;&upsilon;
          &zeta;&eta;&tau;ή&sigma;&omicron;&upsilon;&mu;&epsilon; &nu;&alpha;
          &alpha;&pi;&omicron;&delta;&epsilon;ί&xi;&epsilon;&iota; ό&tau;&iota;
          έ&chi;&epsilon;&iota; &tau;&eta;&nu; ά&delta;&epsilon;&iota;ά
          &sigma;&alpha;&sigmaf; &nu;&alpha;
          &epsilon;&nu;&epsilon;&rho;&gamma;ή&sigma;&epsilon;&iota;
          &gamma;&iota;&alpha; &alpha;&upsilon;&tau;ό &tau;&omicron;
          &sigma;&kappa;&omicron;&pi;ό.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >16. &Pi;ό&tau;&epsilon; &alpha;&pi;&alpha;&nu;&tau;ά&mu;&epsilon;
          &sigma;&tau;&alpha; &Alpha;&iota;&tau;ή&mu;&alpha;&tau;&alpha;
          &sigma;&alpha;&sigmaf;;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Alpha;&pi;&alpha;&nu;&tau;ά&mu;&epsilon; &sigma;&tau;&alpha;
          &Alpha;&iota;&tau;ή&mu;&alpha;&tau;ά &sigma;&alpha;&sigmaf;
          &delta;&omega;&rho;&epsilon;ά&nu; &chi;&omega;&rho;ί&sigmaf;
          &kappa;&alpha;&theta;&upsilon;&sigma;&tau;έ&rho;&eta;&sigma;&eta;,
          &kappa;&alpha;&iota; &sigma;&epsilon; &kappa;ά&theta;&epsilon;
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
          &epsilon;&nu;&tau;ό&sigmaf; (1) &epsilon;&nu;ό&sigmaf;
          &mu;&eta;&nu;ό&sigmaf; &alpha;&pi;ό &tau;ό&tau;&epsilon;
          &pi;&omicron;&upsilon; &theta;&alpha;
          &lambda;ά&beta;&omicron;&upsilon;&mu;&epsilon; &tau;&omicron;
          &alpha;ί&tau;&eta;&mu;ά &sigma;&alpha;&sigmaf;. &Alpha;&nu;,
          ό&mu;&omega;&sigmaf;, &tau;&omicron; &Alpha;ί&tau;&eta;&mu;ά
          &sigma;&alpha;&sigmaf; &epsilon;ί&nu;&alpha;&iota;
          &pi;&omicron;&lambda;ύ&pi;&lambda;&omicron;&kappa;&omicron; ή
          &upsilon;&pi;ά&rho;&chi;&epsilon;&iota;
          &mu;&epsilon;&gamma;ά&lambda;&omicron;&sigmaf;
          &alpha;&rho;&iota;&theta;&mu;ό&sigmaf;
          &Alpha;&iota;&tau;&eta;&mu;ά&tau;&omega;&nu; &sigma;&alpha;&sigmaf;,
          &theta;&alpha; &sigma;&alpha;&sigmaf;
          &epsilon;&nu;&eta;&mu;&epsilon;&rho;ώ&sigma;&omicron;&upsilon;&mu;&epsilon;
          &epsilon;&nu;&tau;ό&sigmaf; &tau;&omicron;&upsilon; &mu;ή&nu;&alpha;
          &alpha;&nu; &chi;&rho;&epsilon;&iota;&alpha;&sigma;&tau;&epsilon;ί
          &nu;&alpha; &lambda;ά&beta;&omicron;&upsilon;&mu;&epsilon;
          &pi;&alpha;&rho;ά&tau;&alpha;&sigma;&eta; ά&lambda;&lambda;&omega;&nu;
          (2) &delta;ύ&omicron; &mu;&eta;&nu;ώ&nu; &epsilon;&nu;&tau;ό&sigmaf;
          &tau;&omega;&nu; &omicron;&pi;&omicron;ί&omega;&nu; &theta;&alpha;
          &sigma;&alpha;&sigmaf;
          &alpha;&pi;&alpha;&nu;&tau;ή&sigma;&omicron;&upsilon;&mu;&epsilon;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >17. &Pi;&omicron;&iota;&omicron; &epsilon;ί&nu;&alpha;&iota;
          &tau;&omicron;
          &epsilon;&phi;&alpha;&rho;&mu;&omicron;&sigma;&tau;έ&omicron;
          &delta;ί&kappa;&alpha;&iota;&omicron; &kappa;&alpha;&tau;ά
          &tau;&eta;&nu;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
          &tau;&omega;&nu; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&alpha;&sigmaf; &alpha;&pi;ό &epsilon;&mu;ά&sigmaf;;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Epsilon;&phi;&alpha;&rho;&mu;&omicron;&sigma;&tau;έ&omicron;
          &Delta;ί&kappa;&alpha;&iota;&omicron; &epsilon;ί&nu;&alpha;&iota;
          &tau;&omicron; &Epsilon;&lambda;&lambda;&eta;&nu;&iota;&kappa;ό
          &Delta;ί&kappa;&alpha;&iota;&omicron;, ό&pi;&omega;&sigmaf;
          &delta;&iota;&alpha;&mu;&omicron;&rho;&phi;ώ&nu;&epsilon;&tau;&alpha;&iota;
          &sigma;ύ&mu;&phi;&omega;&nu;&alpha; &mu;&epsilon; &tau;&omicron;
          &Gamma;&epsilon;&nu;&iota;&kappa;ό
          &Kappa;&alpha;&nu;&omicron;&nu;&iota;&sigma;&mu;ό &gamma;&iota;&alpha;
          &tau;&eta;&nu; &Pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;
          &tau;&omega;&nu;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ώ&nu;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          2016/679/&Epsilon;&Epsilon;, &kappa;&alpha;&iota; &epsilon;&nu;
          &gamma;έ&nu;&epsilon;&iota; &tau;&omicron;
          &iota;&sigma;&chi;ύ&omicron;&nu; &epsilon;&theta;&nu;&iota;&kappa;ό
          &kappa;&alpha;&iota;
          &epsilon;&upsilon;&rho;&omega;&pi;&alpha;ϊ&kappa;ό
          &nu;&omicron;&mu;&omicron;&theta;&epsilon;&tau;&iota;&kappa;ό
          &kappa;&alpha;&iota;
          &kappa;&alpha;&nu;&omicron;&nu;&iota;&sigma;&tau;&iota;&kappa;ό
          &pi;&lambda;&alpha;ί&sigma;&iota;&omicron; &gamma;&iota;&alpha;
          &tau;&eta;&nu; &pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ώ&nu;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >T&upsilon;&chi;ό&nu; &delta;&iota;&alpha;&phi;&omicron;&rho;ά
          &pi;&rho;&omicron;&kappa;ύ&psi;&epsilon;&iota; &alpha;&pi;ό ή
          &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά &mu;&epsilon; &tau;&eta;&nu;
          &pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha; &tau;&omega;&nu;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ώ&nu;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&alpha;&sigmaf;
          &upsilon;&pi;ά&gamma;&epsilon;&tau;&alpha;&iota;
          &pi;&rho;&omicron;&sigmaf; &epsilon;&pi;ί&lambda;&upsilon;&sigma;&eta;
          &sigma;&epsilon;
          &delta;&iota;&alpha;&mu;&epsilon;&sigma;&omicron;&lambda;ά&beta;&eta;&sigma;&eta;
          &sigma;ύ&mu;&phi;&omega;&nu;&alpha; &mu;&epsilon; &tau;&omicron;&nu;
          &Kappa;&alpha;&nu;&omicron;&nu;&iota;&sigma;&mu;ό
          &Delta;&iota;&alpha;&mu;&epsilon;&sigma;&omicron;&lambda;ά&beta;&eta;&sigma;&eta;&sigmaf;
          &tau;&omicron;&upsilon;
          &Epsilon;&upsilon;&rho;&omega;&pi;&alpha;ϊ&kappa;&omicron;ύ
          &Omicron;&rho;&gamma;&alpha;&nu;&iota;&sigma;&mu;&omicron;ύ
          &Delta;&iota;&alpha;&mu;&epsilon;&sigma;&omicron;&lambda;ά&beta;&eta;&sigma;&eta;&sigmaf;
          &kappa;&alpha;&iota;
          &Delta;&iota;&alpha;&iota;&tau;&eta;&sigma;ί&alpha;&sigmaf;
          (&Epsilon;&Omicron;&Delta;&Iota;&Delta;). &Sigma;&epsilon;
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta; &pi;&omicron;&upsilon;
          &eta; &delta;&iota;&alpha;&phi;&omicron;&rho;ά ή
          &mu;έ&rho;&omicron;&sigmaf; &alpha;&upsilon;&tau;ή&sigmaf;
          &delta;&epsilon;&nu;
          &epsilon;&pi;&iota;&lambda;&upsilon;&theta;&epsilon;ί
          &mu;έ&sigma;&omega;
          &delta;&iota;&alpha;&mu;&epsilon;&sigma;&omicron;&lambda;ά&beta;&eta;&sigma;&eta;&sigmaf;,
          &eta; &delta;&iota;&alpha;&phi;&omicron;&rho;ά ή &tau;&omicron;
          &mu;&eta; &epsilon;&pi;&iota;&lambda;&upsilon;&theta;έ&nu;
          &tau;&mu;ή&mu;&alpha; &tau;&eta;&sigmaf;
          &epsilon;&pi;&iota;&lambda;ύ&epsilon;&tau;&alpha;&iota;
          &alpha;&pi;&omicron;&kappa;&lambda;&epsilon;&iota;&sigma;&tau;&iota;&kappa;ά,
          &tau;&epsilon;&lambda;&epsilon;&sigma;ί&delta;&iota;&kappa;&alpha;
          &kappa;&alpha;&iota;
          &alpha;&mu;&epsilon;&tau;ά&kappa;&lambda;&eta;&tau;&alpha;
          &alpha;&pi;ό &delta;&iota;&alpha;&iota;&tau;&eta;&tau;&iota;&kappa;ό
          &delta;&iota;&kappa;&alpha;&sigma;&tau;ή&rho;&iota;&omicron;,
          &pi;&omicron;&upsilon;
          &omicron;&rho;ί&zeta;&epsilon;&tau;&alpha;&iota; &kappa;&alpha;&iota;
          &delta;&iota;&epsilon;&xi;ά&gamma;&epsilon;&iota; &tau;&eta;
          &delta;&iota;&alpha;&iota;&tau;&eta;&sigma;ί&alpha;
          &sigma;ύ&mu;&phi;&omega;&nu;&alpha; &mu;&epsilon; &tau;&omicron;&nu;
          &Kappa;&alpha;&nu;&omicron;&nu;&iota;&sigma;&mu;ό
          &Delta;&iota;&alpha;&iota;&tau;&eta;&sigma;ί&alpha;&sigmaf;
          &Epsilon;&Omicron;&Delta;&Iota;&Delta;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Sigma;&epsilon; &kappa;ά&theta;&epsilon;
          &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
          &alpha;&mu;&phi;&iota;&sigma;&beta;ή&tau;&eta;&sigma;&eta;&sigmaf;
          &tau;&omega;&nu; &alpha;&nu;&omega;&tau;έ&rho;&omega;
          &alpha;&rho;&mu;ό&delta;&iota;&alpha;
          &delta;&iota;&kappa;&alpha;&sigma;&tau;ή&rho;&iota;&alpha;
          &omicron;&rho;ί&zeta;&omicron;&nu;&tau;&alpha;&iota; &tau;&alpha;
          &alpha;&rho;&mu;ό&delta;&iota;&alpha;
          &delta;&iota;&kappa;&alpha;&sigma;&tau;ή&rho;&iota;&alpha;
          &tau;&eta;&sigmaf; &pi;ό&lambda;&eta;&sigmaf; &tau;&omega;&nu;
          &Alpha;&theta;&eta;&nu;ώ&nu;.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >18. &Pi;&omicron;ύ &mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon;
          &nu;&alpha;
          &pi;&rho;&omicron;&sigma;&phi;ύ&gamma;&epsilon;&tau;&epsilon;
          &alpha;&nu;
          &pi;&alpha;&rho;&alpha;&beta;&iota;ά&sigma;&omicron;&upsilon;&mu;&epsilon;
          &tau;&omicron; &iota;&sigma;&chi;ύ&omicron;&nu;
          &delta;ί&kappa;&alpha;&iota;&omicron; &gamma;&iota;&alpha;
          &tau;&eta;&nu; &pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;
          &tau;&omega;&nu;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ώ&nu;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&alpha;&sigmaf;;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >Έ&chi;&epsilon;&tau;&epsilon;
          &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha; &nu;&alpha;
          &upsilon;&pi;&omicron;&beta;ά&lambda;&lambda;&epsilon;&tau;&epsilon;
          &kappa;&alpha;&tau;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;
          &sigma;&tau;&eta;&nu; &Alpha;&rho;&chi;ή
          &Pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;&sigmaf;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ώ&nu;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          (&tau;&alpha;&chi;&upsilon;&delta;&rho;&omicron;&mu;&iota;&kappa;ή
          &delta;/&nu;&sigma;&eta;
          &Kappa;&eta;&phi;&iota;&sigma;ί&alpha;&sigmaf; 1-3, &Tau;.&Kappa;. 115
          23, &Alpha;&theta;ή&nu;&alpha;, &tau;&eta;&lambda;. 210. 6475600,
          &delta;/&nu;&sigma;&eta;
          &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;&omicron;ύ
          &tau;&alpha;&chi;&upsilon;&delta;&rho;&omicron;&mu;&epsilon;ί&omicron;&upsilon;
          (e-mail) contact@dpa.gr), &alpha;&nu;
          &theta;&epsilon;&omega;&rho;&epsilon;ί&tau;&epsilon; ό&tau;&iota;
          &eta;
          &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
          &tau;&omega;&nu;
          &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ώ&nu;
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
          &sigma;&alpha;&sigmaf;
          &pi;&alpha;&rho;&alpha;&beta;&iota;ά&zeta;&epsilon;&iota;
          &tau;&omicron; &iota;&sigma;&chi;ύ&omicron;&nu;
          &epsilon;&theta;&nu;&iota;&kappa;ό &kappa;&alpha;&iota;
          &kappa;&alpha;&nu;&omicron;&nu;&iota;&sigma;&tau;&iota;&kappa;ό
          &pi;&lambda;&alpha;ί&sigma;&iota;&omicron;
          &delta;ί&kappa;&alpha;&iota;&omicron; &gamma;&iota;&alpha;
          &tau;&eta;&nu; &pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;
          &tau;&omega;&nu;
          &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ώ&nu;
          &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          ><br /></span
        ><span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >19. &Pi;ώ&sigmaf; &theta;&alpha;
          &epsilon;&nu;&eta;&mu;&epsilon;&rho;&omega;&theta;&epsilon;ί&tau;&epsilon;
          &gamma;&iota;&alpha; &tau;&upsilon;&chi;ό&nu;
          &tau;&rho;&omicron;&pi;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&iota;&sigmaf;
          &tau;&eta;&sigmaf; &pi;&alpha;&rho;&omicron;ύ&sigma;&alpha;&sigmaf;
          &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή&sigmaf;;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Epsilon;&nu;&eta;&mu;&epsilon;&rho;ώ&nu;&omicron;&upsilon;&mu;&epsilon;
          &tau;&eta;&nu; &pi;&alpha;&rho;&omicron;ύ&sigma;&alpha;
          &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή
          &Alpha;&pi;&omicron;&rho;&rho;ή&tau;&omicron;&upsilon;
          ό&pi;&omicron;&tau;&epsilon; &alpha;&upsilon;&tau;ό
          &epsilon;ί&nu;&alpha;&iota;
          &alpha;&nu;&alpha;&gamma;&kappa;&alpha;ί&omicron;. &Epsilon;ά&nu;
          &upsilon;&pi;ά&rho;&chi;&omicron;&upsilon;&nu;
          &sigma;&eta;&mu;&alpha;&nu;&tau;&iota;&kappa;έ&sigmaf;
          &alpha;&lambda;&lambda;&alpha;&gamma;έ&sigmaf; &sigma;&tau;&eta;&nu;
          &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή
          &Alpha;&pi;&omicron;&rho;&rho;ή&tau;&omicron;&upsilon; ή
          &sigma;&tau;&omicron;&nu; &tau;&rho;ό&pi;&omicron; &mu;&epsilon;
          &tau;&omicron;&nu; &omicron;&pi;&omicron;ί&omicron;
          &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&mu;&epsilon;
          &tau;&alpha; &Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ά
          &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &sigma;&alpha;&sigmaf;, &theta;&alpha;
          &delta;&eta;&mu;&omicron;&sigma;&iota;&epsilon;ύ&omicron;&upsilon;&mu;&epsilon;
          &sigma;&tau;&eta;&nu;
          &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;
          &mu;&alpha;&sigmaf; &tau;&eta;&nu;
          &epsilon;&pi;&iota;&kappa;&alpha;&iota;&rho;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
          &tau;&eta;&sigmaf; &pi;&alpha;&rho;&omicron;ύ&sigma;&alpha;&sigmaf;,
          &pi;&rho;&omicron;&tau;&omicron;ύ &omicron;&iota;
          &alpha;&lambda;&lambda;&alpha;&gamma;έ&sigmaf;
          &tau;&epsilon;&theta;&omicron;ύ&nu; &sigma;&epsilon;
          &iota;&sigma;&chi;ύ &kappa;&alpha;&iota; &theta;&alpha;
          &sigma;&alpha;&sigmaf;
          &epsilon;&iota;&delta;&omicron;&pi;&omicron;&iota;&omicron;ύ&mu;&epsilon;
          &mu;&epsilon; &kappa;ά&theta;&epsilon;
          &pi;&rho;ό&sigma;&phi;&omicron;&rho;&omicron;
          &tau;&rho;ό&pi;&omicron;.</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Sigma;&alpha;&sigmaf;
          &epsilon;&nu;&theta;&alpha;&rho;&rho;ύ&nu;&omicron;&upsilon;&mu;&epsilon;
          &nu;&alpha; &delta;&iota;&alpha;&beta;ά&zeta;&epsilon;&tau;&epsilon;,
          &alpha;&nu;ά &tau;&alpha;&kappa;&tau;ά
          &delta;&iota;&alpha;&sigma;&tau;ή&mu;&alpha;&tau;&alpha;,
          &tau;&eta;&nu; &pi;&alpha;&rho;&omicron;ύ&sigma;&alpha;
          &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή &gamma;&iota;&alpha;
          &nu;&alpha; &gamma;&nu;&omega;&rho;ί&zeta;&epsilon;&tau;&epsilon;
          &pi;ώ&sigmaf;
          &pi;&rho;&omicron;&sigma;&tau;&alpha;&tau;&epsilon;ύ&omicron;&nu;&tau;&alpha;&iota;
          &tau;&alpha; &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
          &sigma;&alpha;&sigmaf;. &Eta; &pi;&alpha;&rho;&omicron;ύ&sigma;&alpha;
          &pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή
          &alpha;&pi;&omicron;&rho;&rho;ή&tau;&omicron;&upsilon;
          &tau;&rho;&omicron;&pi;&omicron;&pi;&omicron;&iota;ή&theta;&eta;&kappa;&epsilon;
          &gamma;&iota;&alpha;
          &tau;&epsilon;&lambda;&epsilon;&upsilon;&tau;&alpha;ί&alpha;
          &phi;&omicron;&rho;ά 01/06/2021.&nbsp;</span
        >
      </p>
      <p
        dir="ltr"
        style="
          line-height: 1.7999999999999998;
          text-align: justify;
          margin-top: 0pt;
          margin-bottom: 0pt;
        "
      >
        <span
          style="
            font-size: 12pt;
            font-family: Arial;
            color: #000000;
            background-color: #ffffff;
            font-weight: 400;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
          "
          >&Pi;&alpha;&rho;&alpha;&mu;έ&nu;&omicron;&upsilon;&mu;&epsilon;
          &sigma;&tau;&eta; &delta;&iota;ά&theta;&epsilon;&sigma;ή
          &sigma;&alpha;&sigmaf; &gamma;&iota;&alpha; &pi;ά&sigma;&alpha;
          &pi;&epsilon;&rho;&alpha;&iota;&tau;έ&rho;&omega;
          &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&alpha;
          &kappa;&alpha;&iota;
          &pi;&rho;&omicron;&beta;&lambda;&eta;&mu;&alpha;&tau;&iota;&sigma;&mu;ό
          &pi;&omicron;&upsilon; &pi;&iota;&theta;&alpha;&nu;ό&nu; &nu;&alpha;
          &pi;&rho;&omicron;&kappa;ύ&pi;&tau;&omicron;&upsilon;&nu; &alpha;&pi;ό
          &tau;&eta;&nu; &pi;&alpha;&rho;&omicron;ύ&sigma;&alpha;
          &Delta;ή&lambda;&omega;&sigma;&eta; &ndash;
          &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή
          &Alpha;&pi;&omicron;&rho;&rho;ή&tau;&omicron;&upsilon;
          &kappa;&alpha;&iota;
          &pi;&alpha;&rho;&alpha;&kappa;&alpha;&lambda;&omicron;ύ&mu;&epsilon;
          ό&pi;&omega;&sigmaf;
          &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ή&sigma;&epsilon;&tau;&epsilon;
          &mu;&epsilon; &tau;&eta;&nu;
          &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha; &mu;&alpha;&sigmaf;
          &omega;&sigmaf; &epsilon;&xi;ή&sigmaf;: info@stoferno.gr</span
        >
      </p>
    </v-container>
  </div>
</template>

<script>
import LegalHeader from "./LegalHeader.vue";
export default {
  components: { LegalHeader },
  name: "PrivacyPolicy",
  data() {
    return {};
  },
};
</script>

<style></style>
